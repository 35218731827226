import { Area, Bar, ComposedChart, Legend, Line, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

const DynamicChart = ({ chart }) => {
  let allLabels = Object.keys(chart.labels);
  let data = Object.keys(chart.data).map((date) => {
    let dataForDate = chart.data[date];
    return allLabels.reduce(
      (acc, val) => {
        acc[chart.labels[val].name] = dataForDate === [] || !dataForDate[val] ? 0 : parseInt(dataForDate[val], 10);
        return acc;
      },
      { date: date },
    );
  });

  let chartElements = allLabels
    .map((k) => {
      let { name, color, type, order } = chart.labels[k];
      return { dataKey: k, name, color, type, order };
    })
    .sort((a, b) => a.order - b.order);

  return (
    <div className='statistics-chart' style={{ height: chart.height }}>
      <h2 className='statistics-title'>{chart.name}</h2>
      <ResponsiveContainer width='100%' height='100%'>
        <ComposedChart
          data={data}
          margin={{
            top: 0,
            right: 0,
            left: 20,
            bottom: 0,
          }}
        >
          {chartElements.map((el) => {
            if (el.type === 'area') {
              return (
                <Area
                  dataKey={el.name}
                  yAxisId='y-axis'
                  stackId='1'
                  stroke={el.color}
                  fill={el.color}
                  type='monotone'
                />
              );
            }
            if (el.type === 'line') {
              return (
                <Line
                  dataKey={el.name}
                  stroke={el.color}
                  yAxisId='y-axis'
                  strokeWidth={2}
                  activeDot={{ r: 4 }}
                  type='monotone'
                />
              );
            }
            if (el.type === 'bar') {
              return (
                <Bar
                  dataKey={el.name}
                  yAxisId='y-axis'
                  stackId='1'
                  barSize={20}
                  fill={el.color}
                  type='monotone'
                />
              );
            }
            return null;
          })}
          <XAxis dataKey='date' />
          <YAxis yAxisId='y-axis' />
          <Tooltip />
          <Legend verticalAlign='top' />
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
};

export { DynamicChart };
