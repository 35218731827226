import { createSlice } from '@reduxjs/toolkit';
import { request } from 'api';
import { format } from 'date-fns';
import { setRedirect } from './redirect';

const initialState = {
  isLoad: false,
  list: [],
  filters: {
    status: '1',
    email: '',
    dateFrom: format(new Date(), 'yyyy-MM-dd'),
    dateTo: format(new Date(), 'yyyy-MM-dd'),
    isActive: null,
    firstSix: '',
    lastFour: '',
    orderNumber: '',
    id: '',
    domain: '',
  },
  pagination: {
    limit: '50',
    page: 1,
    pages: 1,
    total: 0,
  },
  selectedUser: null,
  userEditErrors: {},
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setIsLoad(state, action) {
      state.isLoad = action.payload;
    },
    setUserData(state, action) {
      state.isLoad = false;
      state.list = action.payload.items?.map((el) => ({
        ...el,
        createdAt: `${el.createdAt.slice(0, 10).split('-').reverse().join('.')} ${el.createdAt.slice(-8)}`,
      }));
      state.pagination.page = action.payload.pagination?.page;
      state.pagination.pages = action.payload.pagination?.pages;
      state.pagination.total = action.payload.pagination?.total;
    },
    setUserFilter(state, action) {
      state.filters.status = action.payload.status;
      state.filters.email = action.payload.email;
      state.filters.isActive = action.payload.isActive;
      state.filters.dateFrom = action.payload.dateFrom;
      state.filters.dateTo = action.payload.dateTo;
      state.filters.firstSix = action.payload.firstSix;
      state.filters.lastFour = action.payload.lastFour;
      state.filters.id = action.payload.id;
      state.filters.orderNumber = action.payload.orderNumber;
      state.filters.domain = action.payload.domain;
      state.pagination.limit = action.payload.limit;
      state.pagination.page = 1;
    },
    setUserPage(state, action) {
      state.pagination.page = action.payload;
    },
    setUserOne(state, action) {
      state.selectedUser = action.payload;
      state.isLoad = false;
      state.userEditErrors = {};
    },
    setErrorUserChanges(state, action) {
      state.isLoad = false;
      state.userEditErrors = action.payload;
    },
    setClearSelectedUser(state) {
      state.selectedUser = null;
    },
    setClearErrorsUser(state) {
      state.userEditErrors = {};
    },
  },
});

const {
  reducer: user,
  actions: {
    setIsLoad,
    setUserPage,
    setClearSelectedUser,
    setClearErrorsUser,
    setErrorUserChanges,
    setUserData,
    setUserFilter,
    setUserOne,
  },
} = userSlice;

export const userGetAll = (params) => async (dispatch) => {
  dispatch(setIsLoad(true));
  try {
    const { data } = await request.get('/admin/users', { params });
    dispatch(setUserData(data));
  } catch {
    dispatch(setIsLoad(false));
  }
};

export const userGetOne = (id) => async (dispatch) => {
  try {
    const { data } = await request.get(`/admin/users/${id}`);
    dispatch(setUserOne(data));
  } catch {
    dispatch(setRedirect('/user'));
  }
};

export const userSaveChanges = (id, changes) => async (dispatch) => {
  dispatch(setIsLoad(true));
  try {
    await request.put(`/admin/users/${id}`, changes);
    dispatch(setRedirect('/user'));
  } catch (err) {
    dispatch(setErrorUserChanges(err.response?.data?.error?.messages ?? { email: 'Ошибка' }));
  }
};

export { user, setUserData, setUserFilter, setUserPage, setClearErrorsUser, setClearSelectedUser };
