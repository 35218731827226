import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store';
import Filters from 'assets/components/Filters';
import Table from 'assets/components/Table';
import Pagination from 'rc-pagination';
import 'rc-pagination/assets/index.css';
import { companyGetAll } from 'store/slices/company';
import { getAllGateways } from 'store/slices/gateway';
import { productGetAll } from 'store/slices/product';
import { domainGetPaymentSystems } from 'store/slices/domain';
import { setChainErrorsFilter, setChainErrorsPage, getChainList } from 'store/slices/errors';
import { getAllBanksList } from 'store/slices/banks';
import OrderPaymentsModal from 'pages/OrderPage/OrderAll/OrderPaymentsModal';

const titlesErrorsChains = [
  {
    name: 'дата',
    onClick: true,
    title: 'titlesErrorsChainsDate'
  },
  {
    name: 'ID шлюза',
  },
  {
    name: 'ID заказа',
  },
  {
    name: 'Банк'
  },
  {
    name: 'статус',
  },
  {
    name: 'дата и время',
    onClick: true,
    title: 'titlesErrorsChainsDateAndTime'
  },
  {
    name: 'сумма и ответ',
    mod: 'big',
  },
];

const ErrorsChains = () => {
  const dispatch = useAppDispatch();
  const errorsList = useAppSelector((state) => state.errors.errorsChainList);
  const appliedFilters = useAppSelector((state) => state.errors.filtersChain);
  const limit = useAppSelector((state) => state.errors.paginationChain.limit);
  const page = useAppSelector((state) => state.errors.paginationChain.page);
  const total = useAppSelector((state) => state.errors.paginationChain.total);
  const companies = useAppSelector((state) => state.company.list);
  const gateways = useAppSelector((state) => state.gateway.gateways);
  const products = useAppSelector((state) => state.product.list);
  const paymentSystemOption = useAppSelector((state) => state.domain.paymentSystems).map((paymentSystem, idx) => {
    return { value: paymentSystem, label: paymentSystem };
  });
  const bankList = useAppSelector((state) => state.banks.allBanksList)?.map((item) => ({
    value: item,
    label: item,
  }));
  const [companiesOption, setCompaniesOption] = useState([]);
  const [paymentSystem, setPaymentSystem] = useState([]);
  const [productsListOption, setProductsListOption] = useState([]);
  const [reloadPage, setReloadPage] = useState(true);
  const [isCheck, setIsCheck] = useState([]);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [active, setActive] = useState(false);

  const handleGetChainList = async () => {
    const params = {};
    for (let key in appliedFilters) {
      if (appliedFilters[key]) {
        params[`filter[${key}]`] = await appliedFilters[key];
      }
    }
    params.limit = limit;
    params.page = page;
    dispatch(getChainList(params));
  };

  const handleClickCheck = (e) => {
    const { id, checked } = e.target;
    setIsCheck([...isCheck, id]);
    if (!checked) {
      setIsCheck(isCheck.filter((item) => item !== id));
    }
  };

  const handleClickCheckSelectAll = (e) => {
    setIsCheckAll(!isCheckAll);
    setIsCheck(errorsList.map((li) => li.id));
    if (isCheckAll) {
      setIsCheck([]);
    }
  };

  const handleClickStartEdit = () => {
    setActive(true);
  };

  useEffect(() => {
    if (reloadPage) {
      handleGetChainList();
      setReloadPage(false);
    }
  }, [reloadPage]);

  useEffect(() => {
    dispatch(companyGetAll());
    dispatch(getAllGateways());
    dispatch(productGetAll());
    dispatch(domainGetPaymentSystems());
    dispatch(getAllBanksList());
  }, []);

  useEffect(() => {
    setCompaniesOption(
      companies.map((item) => ({
        value: item.id,
        label: item.name,
      })),
    );
  }, [companies]);

  useEffect(() => {
    gateways?.length > 1 &&
      setPaymentSystem(
        gateways
          .map((el) => ({
            label: `${el.paymentSystem}: ${el.name} (${el.companyName})`,
            value: el.id,
            paymentSystem: el.paymentSystem,
            name: el.name,
            id: el.id,
            companyName: el.companyName,
          }))
          .sort((a, b) => (a.label > b.label ? 1 : -1)),
      );
  }, [gateways]);

  useEffect(() => {
    products.length &&
      setProductsListOption(
        products
          .filter((item) => item.innerName !== '')
          .reverse()
          .map((option) => ({ label: option.innerName, value: option.id })),
      );
  }, [products]);

  const handlePageChange = (current) => {
    dispatch(setChainErrorsPage(current));
    setReloadPage(true);
  };

  const formatOptionLabel = ({ paymentSystem, name, id, companyName }) => (
    <div>
      <div>
        <strong>ID: </strong>
        {id}
      </div>
      <div>
        <strong>Платёжная система: </strong>
        {paymentSystem}
      </div>
      <div>
        <strong>Платёжный шлюз: </strong>
        {name}
      </div>
      <div>
        <strong>ЮрЛицо: </strong>
        {companyName ? companyName : '—'}
      </div>
    </div>
  );

  return (
    <>
      <Filters
        chainErrors={true}
        appliedFilters={{ ...appliedFilters, limit }}
        onFilterChange={(obj) => {
          dispatch(setChainErrorsFilter(obj));
          setReloadPage(true);
        }}
        formatOptionLabel={formatOptionLabel}
        companiesOption={companiesOption}
        gatewaysOption={paymentSystem}
        multiSelectOptions={productsListOption}
        paymentSystemLabels={paymentSystemOption}
        bankListOption={bankList}
        statusLabels={[
          { label: 'Успешный', value: '1' },
          { label: 'Неуспешный', value: '2' },
        ]}
        orderLabels={[
          { label: 'Ребилл', value: '2' },
          { label: 'Первые ребиллы', value: '8' },
          { label: 'Повторные ребиллы', value: '9' },
          { label: 'RENEWAL', value: '3' },
        ]}
      />
      <Table
        isCheck={isCheck}
        isCheckAll={isCheckAll}
        handleClickCheck={handleClickCheck}
        handleClickCheckSelectAll={handleClickCheckSelectAll}
        handleClickStartEdit={handleClickStartEdit}
        titles={titlesErrorsChains}
        content={errorsList}
        keyProp='errorsChain'
      />
      <Pagination
        className='order__pagination'
        current={page}
        total={total}
        pageSize={limit}
        onChange={handlePageChange}
        showTitle={false}
      />
      {active && <OrderPaymentsModal isCheck={isCheck} setIsCheckAll={setIsCheckAll} setIsCheck={setIsCheck} setActive={setActive}/>}
    </>
  );
};

export default ErrorsChains;
