import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'store'
import Filters from 'assets/components/Filters'
import Table from 'assets/components/Table'
import Pagination from 'rc-pagination'
import 'rc-pagination/assets/index.css'
import { domainGetPaymentSystems } from 'store/slices/domain'
import { getAllGateways } from 'store/slices/gateway'
import { companyGetAll } from 'store/slices/company'
import { productGetAll } from 'store/slices/product'
import {
  getPaymentErrorListStatus,
  orderReportGetAll,
  setOrderGetReport,
  orderGetAll,
  setOrderPage,
  getStatusLabels,
  setOrderFilter,
} from 'store/slices/order'
import { getAllBanksList } from 'store/slices/banks'
import OrderPaymentsModal from './OrderPaymentsModal'

const titlesAllOrder = [
  {
    name: 'ID',
    mod: 'mm',
  },
  {
    name: 'ID шлюза',
  },
  {
    name: 'номер',
    mod: 'mm',
  },
  {
    name: 'email',
    mod: 'big',
  },
  {
    name: 'сумма',
  },
  {
    name: 'статус',
  },
  {
    name: 'домен',
  },
  {
    name: 'cpa',
  },
  {
    name: 'webmaster',
  },
  {
    name: 'создан',
  },
]

const OrderAll = () => {
  const dispatch = useAppDispatch()
  const [reloadPage, setReloadPage] = useState(true)
  const [valueSelectErrors, setValueSelectErrors] = useState([])
  const [valueSelectStringErrors, setValueSelectStringErrors] = useState('')
  const [paymentSystem, setPaymentSystem] = useState([])

  const [isCheck, setIsCheck] = useState([])
  const [isCheckAll, setIsCheckAll] = useState(false)
  const [active, setActive] = useState(false)

  const paymentSystemLabels = useAppSelector((state) => state.domain.paymentSystems).map((paymentSystem) => {
    return { value: paymentSystem, label: paymentSystem }
  })
  const gateways = useAppSelector((state) => state.gateway.gateways)
  const orders = useAppSelector((state) => state.order.list)
  const bankList = useAppSelector((state) => state.banks.allBanksList)?.map((item) => ({
    value: item,
    label: item,
  }))
  const appliedFilters = useAppSelector((state) => state.order.filters)
  const limit = useAppSelector((state) => state.order.pagination.limit)
  const page = useAppSelector((state) => state.order.pagination.page)
  const total = useAppSelector((state) => state.order.pagination.total)
  const statusLabels = useAppSelector((state) => state.order.statusLabels)
  const getReport = useAppSelector((state) => state.order.getReport)
  const paymentErrorList = useAppSelector((state) => state.order.paymentErrorList)?.map((item) => ({
    label: item,
    value: item,
  }))

  const companiesOption = useAppSelector((state) => state.company.list).map((item) => ({
    value: item.id,
    label: item.name,
  }))
  const productsListOption = useAppSelector((state) => state.product.list)
    .filter((item) => item.innerName !== '')
    .reverse()
    .map((option) => ({ label: option.innerName, value: option.id }))

  const handleOnChangeMultiSelect = (value) => {
    setValueSelectErrors(value)
    setValueSelectStringErrors(value.map((item) => item.value).toString())
  }

  useEffect(() => dispatch(getStatusLabels()), [getStatusLabels])

  useEffect(() => {
    dispatch(companyGetAll())
    dispatch(productGetAll())
    dispatch(getPaymentErrorListStatus())
    dispatch(domainGetPaymentSystems())
    dispatch(getAllGateways())
    dispatch(getAllBanksList())
  }, [])

  const handleGetOrderAll = async () => {
    const params = {}
    for (let key in appliedFilters) {
      if (appliedFilters[key]) {
        params[`filter[${key}]`] = await appliedFilters[key]
      }
    }
    params.limit = limit
    params.page = page
    dispatch(orderGetAll(params))
  }

  useEffect(() => {
    if (reloadPage) {
      handleGetOrderAll()
      setReloadPage(false)
    }
  }, [reloadPage])

  useEffect(() => {
    let params = {}
    for (let key in appliedFilters) {
      if (appliedFilters[key]) {
        params[`filter[${key}]`] = appliedFilters[key]
      }
    }
    params.limit = limit
    params.page = page
    getReport && dispatch(orderReportGetAll(params))
  }, [getReport, orderReportGetAll])

  const handlePageChange = (current) => {
    dispatch(setOrderPage(current))
    setReloadPage(true)
  }

  useEffect(() => {
    gateways?.length > 1 &&
      setPaymentSystem(
        gateways
          .map((el) => ({
            label: `${el.paymentSystem}: ${el.name} (${el.companyName})`,
            value: el.id,
            paymentSystem: el.paymentSystem,
            name: el.name,
            id: el.id,
            companyName: el.companyName,
          }))
          .sort((a, b) => (a.label > b.label ? 1 : -1)),
      )
  }, [gateways])

  const formatOptionLabel = ({ paymentSystem, name, id, companyName }) => (
    <div>
      <div>
        <strong>ID: </strong>
        {id}
      </div>
      <div>
        <strong>Платёжная система: </strong>
        {paymentSystem}
      </div>
      <div>
        <strong>Платёжный шлюз: </strong>
        {name}
      </div>
      <div>
        <strong>ЮрЛицо: </strong>
        {companyName ? companyName : '—'}
      </div>
    </div>
  )

  const handleClickCheck = (e) => {
    const { id, checked } = e.target
    setIsCheck([...isCheck, id])
    if (!checked) {
      setIsCheck(isCheck.filter((item) => item !== id))
    }
  }

  const handleClickCheckSelectAll = (e) => {
    setIsCheckAll(!isCheckAll)
    setIsCheck(orders.map((li) => li.id))
    if (isCheckAll) {
      setIsCheck([])
    }
  }

  const handleClickStartEdit = () => {
    setActive(true)
  }

  return (
    <>
      <Filters
        order={true}
        appliedFilters={{ ...appliedFilters, limit }}
        onFilterChange={(obj) => {
          dispatch(setOrderFilter(obj))
          setReloadPage(true)
        }}
        statusLabels={statusLabels.map((label, index) => ({ label: label, value: index.toString() }))}
        orderLabels={[
          { label: 'Билл', value: '0' },
          { label: 'RENEWAL', value: '3' },
          { label: 'Эконом', value: '10' },
        ]}
        paymentLabels={[
          { label: 'Ребилл', value: '2' },
          { label: 'Первые ребиллы', value: '8' },
          { label: 'Повторные ребиллы', value: '9' },
        ]}
        formatOptionLabel={formatOptionLabel}
        gatewaysOption={paymentSystem}
        companiesOption={companiesOption}
        multiSelectOptions={productsListOption}
        bankListOption={bankList}
        valueSelectErrors={valueSelectErrors}
        valueSelectStringErrors={valueSelectStringErrors}
        paymentErrorListOption={paymentErrorList}
        handleOnChangeMultiSelect={handleOnChangeMultiSelect}
        paymentSystemLabels={paymentSystemLabels}
      />
      <Table
        isCheck={isCheck}
        isCheckAll={isCheckAll}
        handleClickCheck={handleClickCheck}
        handleClickCheckSelectAll={handleClickCheckSelectAll}
        handleClickStartEdit={handleClickStartEdit}
        titles={titlesAllOrder}
        content={orders}
        keyProp="order"
      />
      <Pagination
        className="order__pagination"
        current={page}
        total={total}
        pageSize={limit}
        onChange={handlePageChange}
        showTitle={false}
      />
      {active && (
        <OrderPaymentsModal
          isCheck={isCheck}
          setIsCheckAll={setIsCheckAll}
          setIsCheck={setIsCheck}
          setActive={setActive}
        />
      )}
      <div className="order__button">
        <button onClick={() => dispatch(setOrderGetReport())}>Экспорт</button>
      </div>
    </>
  )
}

export default OrderAll
