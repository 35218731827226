import { createSlice } from '@reduxjs/toolkit';
import { request } from 'api';
import { setRedirect } from './redirect';

const initialState = {
  botsList: [],
  isLoad: false,
  pagination: {
    limit: '50',
    page: 1,
    pages: 1,
    total: 0,
  },
  errors: null,
};

const botsSlice = createSlice({
  name: 'bots',
  initialState,
  reducers: {
    setIsLoad(state, action) {
      state.isLoad = action.payload;
    },
    setBotsList(state, action) {
      state.botsList = action.payload;
    },
    setError(state, action) {
      state.errors = action.payload;
    },
  },
});

const {
  reducer: bots,
  actions: { setIsLoad, setBotsList, setError },
} = botsSlice;

export const getBots = (params) => async (dispatch) => {
  dispatch(setIsLoad(true));
  request
    .get('/admin/bots', { params })
    .then((res) => {
      dispatch(setBotsList(res.data));
      dispatch(setIsLoad(false));
    })
    .catch(() => {
      dispatch(setIsLoad(false));
    });
};

export const createBots = (payload) => async (dispatch) => {
  dispatch(setIsLoad(true));
  return request
    .post('/admin/bots', { ...payload })
    .then(() => {
      dispatch(setIsLoad(false));
      dispatch(setRedirect('/bots'));
    })
    .catch((error) => {
      console.log(error.response.data?.detail);
      dispatch(setIsLoad(false));
    });
};

export const getSelectedBot = (id) => async (dispatch) => {
  dispatch(setIsLoad(true));
  return request
    .get(`/admin/bots/${id}`)
    .catch(() => dispatch(setIsLoad(false)))
    .finally(() => dispatch(setIsLoad(false)));
};

export const updateBot = (id, payload) => async (dispatch) => {
  dispatch(setIsLoad(true));
  return request
    .put(`/admin/bots/${id}`, payload)
    .then(() => {
      dispatch(setIsLoad(false));
      dispatch(setRedirect('/bots'));
    })
    .catch((error) => {
      // dispatch(setError(Object.values(error.response.data.error?.messages)[0]));
      console.log(error.response.data.error);
      dispatch(setIsLoad(false));
    });
};

export const deleteBot = (id) => (dispatch) => {
  dispatch(setIsLoad(true));
  return request
    .delete(`/admin/bots/${id}`)
    .then(() => {
      dispatch(getBots());
      dispatch(setIsLoad(false));
    })
    .catch(() => {
      dispatch(setIsLoad(false));
    });
};

export { bots, setError, setIsLoad };
