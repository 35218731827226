import React from 'react'
import Logo from 'assets/img/logo.png'
import SideBarLink from '../SideBarLink'

const SideBar = ({ authLogout, permissions }) => {
  const showSideBar = (sectionName, viewName, component) =>
    permissions[sectionName] && permissions[sectionName].includes(viewName) ? component : null

  return (
    <aside className="sideBar">
      <img src={Logo} alt="" className="sideBar__logo" />
      {showSideBar('stats', 'view_stats', <SideBarLink text="Dashboard" icon="home" href="/dashboard" />)}
      {showSideBar('order', 'view_orders', <SideBarLink text="Заказы" icon="doc" href="/orders/all" />)}
      {showSideBar('order', 'view_orders', <SideBarLink text="Платежи" icon="doc" href="/errors/chains" />)}
      {showSideBar('user', 'view_users', <SideBarLink text="Клиенты" icon="user" href="/user" />)}
      {showSideBar('product', 'view_products', <SideBarLink text="Продукты" icon="post" href="/product" />)}
      {showSideBar('domain', 'view_domains', <SideBarLink text="Домены" icon="post" href="/domain" />)}
      {showSideBar(
        'new_analytics',
        'view_new_analytics',
        <SideBarLink text="Аналитика" icon="post" href="/analytics" />,
      )}
      {showSideBar('analytics', 'view_analytics', <SideBarLink text="Cтатистика" icon="post" href="/stats" />)}
      {showSideBar('gateway', 'view_gateways', <SideBarLink text="Аналитика MID's" icon="post" href="/statsmids" />)}
      {showSideBar('file', 'view_files', <SideBarLink text="Файлы продуктов" icon="settings" href="/file" />)}
      <SideBarLink text="Email рассылки" icon="message" href="/mail" />
      {showSideBar('gateway', 'view_gateways', <SideBarLink text="Шлюзы" icon="post" href="/gateway" />)}
      {showSideBar('webinar', 'view_webinars', <SideBarLink text="Вебинары" icon="post" href="/webinars" />)}
      {showSideBar('bot', 'view_bots', <SideBarLink text="Боты" icon="post" href="/bots" />)}
      {showSideBar('admin', 'view_admins', <SideBarLink text="Админы" icon="post" href="/admin/admins" />)}
      {showSideBar('shaker', 'view_shakers', <SideBarLink text="Шейкер" icon="post" href="/shaker/settings" />)}
      {showSideBar(
        'payment',
        'chargeback_payments',
        <SideBarLink text="Чарджбеки" icon="post" href="/chargebacks/statistic" />,
      )}
      {showSideBar('sberalert', 'view_sberalerts', <SideBarLink text="СберАлерт" icon="post" href="/sberalert" />)}
      {showSideBar('company', 'view_companies', <SideBarLink text="ЮрЛица" icon="post" href="/company" />)}
      {showSideBar('bank_issuer', 'edit_banks', <SideBarLink text="Банки" icon="settings" href="/banks" />)}
      <SideBarLink text="Настройки-2FA" icon="settings" href="/settings-2fa" />
      <SideBarLink text="Выйти" icon="logout" href="/auth" onClick={() => authLogout()} />
    </aside>
  )
}

export default SideBar
