import React, { useEffect } from 'react'
import Header from 'assets/components/Header'
import Table from 'assets/components/Table'
import Loader from 'assets/components/Loader'
import Filters from 'assets/components/Filters'
import { companyGetAll } from 'store/slices/company'
import { useAppSelector, useAppDispatch } from 'store'
import { analyticsGetAll, setAnalyticsFilter } from 'store/slices/analytics'
import { domainGetPaymentSystems } from 'store/slices/domain'

const titlesGeneral = [
  {
    name: 'дата',
  },
  {
    name: 'эквайринг',
  },
  {
    name: 'bill количество',
    mod: 'rigth',
  },
  {
    name: 'bill сумма',
    mod: 'rigth',
  },
  {
    name: 'rebill количество',
    mod: 'rigth',
  },
  {
    name: 'rebill сумма',
    mod: 'rigth',
  },
  {
    name: 'renew количество',
    mod: 'rigth',
  },
  {
    name: 'renew сумма',
    mod: 'rigth',
  },
  {
    name: 'eco количество',
    mod: 'rigth',
  },
  {
    name: 'eco сумма',
    mod: 'rigth',
  },
  {
    name: 'общее количество',
    mod: 'rigth',
  },
  {
    name: 'общая сумма',
    mod: 'rigth',
  },
  {
    name: 'валюта',
  },
]

const currency = [
  { value: 'RUB', label: 'RUB' },
  { value: 'USD', label: 'USD' },
  { value: 'EUR', label: 'EUR' },
]

const AnalyticsPage = () => {
  const dispatch = useAppDispatch()
  const [reloadPage, setReloadPage] = React.useState(true)
  const companies = useAppSelector((state) => state.company.list)
  const analytics = useAppSelector((state) => state.analytics.list)
  const appliedFilters = useAppSelector((state) => state.analytics.filters)
  const isLoad = useAppSelector((state) => state.analytics.isLoad)
  const paymentSystem = useAppSelector((state) => state.domain.paymentSystems).map((paymentSystem, idx) => {
    return { value: paymentSystem, label: paymentSystem }
  })

  const handleGetAnalytics = React.useCallback(async () => {
    const params = {}

    for (let key in appliedFilters) {
      if (appliedFilters[key]) {
        if (key === 'types') {
          appliedFilters[key].split(',').forEach((val) => (params[`filter[${key}][${val}]`] = val))
        } else {
          params[`filter[${key}]`] = await appliedFilters[key]
        }
      }
    }

    dispatch(analyticsGetAll(params))
  }, [dispatch, appliedFilters])

  useEffect(() => {
    if (reloadPage) {
      handleGetAnalytics()
      setReloadPage(false)
    }
  }, [handleGetAnalytics, reloadPage])

  useEffect(() => {
    dispatch(companyGetAll())
    dispatch(domainGetPaymentSystems())
  }, [dispatch])

  const companiesOption = companies.map((item) => ({ value: item.id, label: item.name }))

  const getPaymentSummary = (analytics, key) => {
    const uniqArray = Object.values(analytics)
      .map((item) => Object.keys(item))
      .flat()
      .reduce((acc, item) => {
        if (acc.includes(item)) {
          return acc
        }
        return [...acc, item]
      }, [])

    const data = Object.values(analytics)
      .map((item) =>
        Object.entries(item).map((entry) => [
          entry[0],
          Object.values(entry[1])
            .map((item) => item[key])
            .reduce((sum, current) => sum + current),
        ]),
      )
      .flat()

    return uniqArray.map((currency) => ({
      [currency]: data
        .filter((item) => item[0] === currency)
        .map((item) => item[1])
        .reduce((prev, next) => prev + next),
    }))
  }

  return (
    <>
      {isLoad && <Loader />}
      <Header title="Аналитика" />
      <Filters
        analytics={true}
        appliedFilters={{ ...appliedFilters }}
        onFilterChange={(obj) => {
          dispatch(setAnalyticsFilter(obj))
          setReloadPage(true)
        }}
        paymentSystemLabels={paymentSystem}
        currencyLabels={currency}
        companiesOption={companiesOption}
      />
      <Table getPaymentSummary={getPaymentSummary} titles={titlesGeneral} content={analytics} keyProp="analytics" />
    </>
  )
}

export default AnalyticsPage
