import React from 'react';
import ButtonLink from '../ButtonLink';
import Avatar from 'assets/img/avatar.png';

const Header = ({ title, href, text, secondHref, secondText, thirdHref, thirdText, fourthText, fourthHref }) => (
  <header className='header'>
    <h1 className='header__title'>{title}</h1>
    {/* {href && } */}
    {href && secondHref && secondText && thirdText && thirdHref && fourthText && fourthHref ?
      <div className="header-third">
        <ButtonLink plus={true} btnClass='header__btn' text={fourthText} href={fourthHref} />
        <ButtonLink plus={true} btnClass='header__btn' text={thirdText} href={thirdHref} />
        <ButtonLink btnClass='header__btn' text={text || href} href={href} />
        <ButtonLink plus={true} btnClass='header__btn' text={secondText} href={secondHref} />
        <img src={Avatar} alt='avatar' className='header__ava' />
      </div>
      :
      href && secondHref && secondText ?
        <div className="header-second">
          <ButtonLink btnClass='header__btn' text={text || href} href={href} />
          <ButtonLink plus={true} btnClass='header__btn' text={secondText || secondHref} href={secondHref} />
          <img src={Avatar} alt='avatar' className='header__ava' />
        </div> : href ?
          <>
            <ButtonLink btnClass='header__btn' text={text || href} href={href} />
            <img src={Avatar} alt='avatar' className='header__ava' />
          </> : <img src={Avatar} alt='avatar' className='header__ava' />
    }
  </header>
);

export default Header;
