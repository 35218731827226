import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Select from 'react-select';
import cn from 'classnames';
import Input from 'assets/components/Input';
import Loader from 'assets/components/Loader';
import Table from 'assets/components/Table/Table';
import { variantPageOptions, variantFooterOptions, landFooterOptions } from '../../constants/dashboardOptions/dashboardOptions';
import { productGetAll } from 'store/slices/product';
import { useAppDispatch, useAppSelector } from 'store';
import {
  domainCreate,
  domainUpdate,
  domainGetOne,
  setDomainCleanErrorsLocale,
  setDomainClearCurDomain,
  domainFolderGetAll,
  domainGetPaymentSystems,
} from 'store/slices/domain';

const DomainCrtPage = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const [link, setLink] = useState('');
  const [name, setName] = useState('');
  const [folder, setFolder] = useState('');
  const [selectedProduct, setSelectedProduct] = useState([]);
  const [legal, setLegal] = useState('');
  const [paymentSettings, setPaymentSettings] = useState([]);
  const [status, setStatus] = useState(0);
  //
  const errorMessage = useAppSelector((state) => state.domain.errorMessage);
  const errors = useAppSelector((state) => state.domain.errors);
  const curDomain = useAppSelector((state) => state.domain.curDomain);
  const foldersOption = useAppSelector((state) => state.domain.folderList)?.map((item) => ({
    value: item,
    label: item,
  }));
  const isLoad = useAppSelector((state) => state.domain.isLoad);
  const productList = useAppSelector((state) => state.product.list);
  const paymentSystems = useAppSelector((state) => state.domain.paymentSystems);
  const gateways = useAppSelector((state) => state.gateway.gateways);
  const domainStatusList = useAppSelector((state) => state.domain.domainStatusList);
  // adding to table
  const [paymentSystemId, setPaymentSystemId] = useState(null);
  const [paymentSystemToAdd, setPaymentSystemToAdd] = useState(null);
  const [paymentSystemTitleToAdd, setPaymentSystemTitleToAdd] = useState(null);
  const [paymentSystemToAddOptions, setPaymentSystemToAddOptions] = useState([]);
  const [paymentSystemToAddOptions2, setPaymentSystemToAddOptions2] = useState([]);
  const [paymentGatewayToAdd, setPaymentGatewayToAdd] = useState('');
  const [paymentChanceToAdd, setPaymentChanceToAdd] = useState('');
  const [noRef, setNoRef] = useState(false);
  const [sberbill, setSberbill] = useState(false);
  const [productListOption, setProductListOption] = useState([]);

  useEffect(() => {
    if (productList.length) {
      setProductListOption(productList?.map((item) => ({ value: item.id, label: `${item.id} | ${item.innerName}` }))?.sort((a, b) => (a.label.toLowerCase() < b.label.toLowerCase() ? 1 : -1)));
    }
  }, [productList]);

  const [options, setOptions] = useState({
    unsubscribe: '',
    refund: '',
    auth: '',
    footer: '',
    landFooter: '',
  });

  useEffect(() => {
    if (id) {
      dispatch(domainGetOne(id));
    }
    dispatch(domainFolderGetAll());
    dispatch(productGetAll());
    dispatch(domainGetPaymentSystems());
    return () => dispatch(setDomainClearCurDomain());
  }, [id, domainFolderGetAll, domainGetOne, productGetAll, domainGetPaymentSystems, setDomainClearCurDomain]);

  useEffect(() => {
    curDomain.name && setName(curDomain.name);
    curDomain.link && setLink(curDomain.link);
    curDomain.legal && setLegal(curDomain.legal);
    curDomain?.folder && setFolder(curDomain.folder);
    curDomain?.products && setSelectedProduct(curDomain?.products);
    curDomain?.options && setOptions(curDomain?.options);
    curDomain.paymentSettings &&
      setPaymentSettings((prev) =>
        curDomain.paymentSettings?.map((el) => ({
          ...el,
          paymentGatewayOptions: gateways
            ?.filter((gateway) => gateway.paymentSystem === el.paymentSystem)
            ?.map((filtered) => ({
              label: `${filtered.paymentSystem}: ${filtered.name} (${filtered.companyName}) ${filtered.id}`,
              value: filtered.name,
              id: filtered.id,
              name: filtered.name,
              companyName: filtered.companyName,
              paymentSystem: filtered.paymentSystem,
            })),
        })),
      );
    setStatus(curDomain.status);
  }, [curDomain, gateways]);

  useEffect(() => {}, []);

  useEffect(() => {
    setPaymentSystemToAddOptions(paymentSystems.map((_) => ({ value: _, label: _ })));
  }, [paymentSystems]);

  useEffect(() => {
    gateways?.length > 1 &&
      setPaymentSystemToAddOptions2(
        gateways
          .map((el) => ({
            label: `${el.paymentSystem}: ${el.name} (${el.companyName})`,
            value: el.id,
            paymentSystem: el.paymentSystem,
            name: el.name,
            id: el.id,
            companyName: el.companyName,
            paymentSystemTitle: el.paymentGatewayTitle,
          }))
          .sort((a, b) => (a.label > b.label ? 1 : -1)),
      );
  }, [gateways]);

  const handlePaymentChanceToAddChange = (e) => {
    if (+e.target.value < 0) {
      return setPaymentChanceToAdd(0);
    }
    if (+e.target.value > 100) {
      return setPaymentChanceToAdd(100);
    }
    setPaymentChanceToAdd(+e.target.value);
  };

  const handlePaymentSettingsAdd = () => {
    setPaymentSettings((prev) => [
      ...prev,
      {
        // paymentSystem: paymentSystemToAdd?.value ?? '',
        paymentSystem: paymentSystemToAdd ?? '',
        paymentGateway: paymentGatewayToAdd,
        paymentGatewayTitle: paymentSystemTitleToAdd,
        chance: paymentChanceToAdd,
        noRef: noRef,
        sberbill: sberbill,
        paymentGatewayOptions: gateways
          .filter((gateway) => gateway.paymentSystem === paymentSystemToAdd)
          .map((filtered) => ({
            label: `${filtered.paymentSystem}: ${filtered.name} (${filtered.companyName}) ${filtered.id}`,
            value: filtered.name,
            id: filtered.id,
            name: filtered.name,
            companyName: filtered.companyName,
            paymentSystem: filtered.paymentSystem,
          })),
      },
    ]);
    setPaymentSystemId(null);
    setPaymentSystemToAdd(null);
    setPaymentSystemTitleToAdd(null);
    setPaymentGatewayToAdd('');
    setPaymentChanceToAdd('');
    setNoRef(false);
    setSberbill(false);
    setOptions({
      unsubscribe: '',
      refund: '',
      auth: '',
      footer: '',
      landFooter: '',
    });
  };

  const handleSaveClick = () => {
    const action = id ? domainUpdate.bind(null, id) : domainCreate;
    dispatch(
      action({
        link,
        name,
        folder: folder,
        products: selectedProduct[0].id ? selectedProduct.map((item) => item.id) : selectedProduct,
        paymentSettings: paymentSettings,
        legal,
        status: +status,
        options: options,
      }),
    );
  };

  const handlePayment = (idx, value, parameter) => {
    setPaymentSettings(
      paymentSettings.map((el, index) => {
        if (index === idx && parameter != 'noRef' && parameter != 'sberbill') {
          el[parameter] = value;
        } else if (index === idx && parameter === 'noRef') {
          el.noRef = value;
        } else if (index === idx && parameter === 'sberbill') {
          el.sberbill = value;
        }
        return el;
      }),
    );
  };
  const handleEditPayment = (idx) => {
    setPaymentSettings(
      paymentSettings.map((el, index) => {
        if (index === idx) el.disabled = !el.disabled;
        return el;
      }),
    );
  };

  const formatOptionLabel = ({ paymentSystem, name, id, companyName }) => (
    <div>
      <div>
        <strong>ID: </strong>
        {id}
      </div>
      <div>
        <strong>Платёжная система: </strong>
        {paymentSystem}
      </div>
      <div>
        <strong>Платёжный шлюз: </strong>
        {name}
      </div>
      <div>
        <strong>ЮрЛицо: </strong>
        {companyName ? companyName : '—'}
      </div>
    </div>
  );

  const selectStyles = {
    container: (provided) => ({
      ...provided,
      display: 'inline-block',
      width: '100%',
      height: '80px',
      minHeight: '1px',
      textAlign: 'left',
      border: 'none',
    }),
    control: (provided) => ({
      ...provided,
      height: '80px',
    }),
    valueContainer: (provided) => ({
      ...provided,
      height: '80px',
    }),
  };

  const handlePressEnter = (event) => {
    if (event.key === 'Enter' && paymentChanceToAdd && paymentSystemToAdd) {
      event.preventDefault();
      handlePaymentSettingsAdd();
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handlePressEnter);
    return () => {
      document.removeEventListener('keydown', handlePressEnter);
    };
  });

  return (
    <>
      <div className='create'>
        {isLoad ? <Loader /> : ''}

        <h2 className='create__title'>{id ? 'Изменение домена' : 'Создание домена'}</h2>

        <Input
          value={name}
          onChange={(e) => {
            setName(e.target.value);
            if (errors.name) dispatch(setDomainCleanErrorsLocale());
          }}
          error={errors.name || ''}
          placeholder='Название'
          type='text'
          name='name'
          inputCls='create__input'
          label='Название'
        />

        <Input
          value={link}
          onChange={(e) => {
            setLink(e.target.value);
            if (errors.link) dispatch(setDomainCleanErrorsLocale());
          }}
          error={errors.link || ''}
          placeholder='Хост'
          type='text'
          name='domain'
          inputCls='create__input'
          label='Хост домена'
        />

        <div className='domain-crt-select--mb'>
          <label className='input__label'>Папка</label>
          <Select
            options={foldersOption}
            value={foldersOption.find((o) => o.value === folder)}
            onChange={(option) => {
              setFolder(option.value);
            }}
            placeholder='Выберите папку'
          />
        </div>

        <div className='domain-crt-select--mb'>
          <label className='input__label'>Продукты</label>
          <Select
            options={productListOption}
            value={productListOption?.find((o) => o.value === selectedProduct[0]?.id)}
            onChange={(option) => {
              setSelectedProduct([option.value]);
            }}
            placeholder='Продукты'
          />
        </div>

        <div className='domain-crt-select--mb'>
          <label className='input__label'>Статус</label>
          <Select
            options={domainStatusList}
            value={domainStatusList[domainStatusList.findIndex((el) => el.value == status)]}
            onChange={(option) => {
              setStatus(option.value);
            }}
            placeholder='Выберите статус'
          />
        </div>

        <div className='domain-crt-select--mb'>
          <label className='input__label'>Refund Page</label>
          <Select
            options={variantPageOptions}
            value={variantPageOptions[variantPageOptions.findIndex((el) => el.value === options.refund)]}
            onChange={(option) => {
              setOptions({ ...options, refund: option.value });
            }}
            placeholder='Выберите вариант страницы'
          />
        </div>

        <div className='domain-crt-select--mb'>
          <label className='input__label'>Unsubscribe Page</label>
          <Select
            options={variantPageOptions}
            value={variantPageOptions[variantPageOptions.findIndex((el) => el.value === options.unsubscribe)]}
            onChange={(option) => {
              setOptions({ ...options, unsubscribe: option.value });
            }}
            placeholder='Выберите вариант страницы'
          />
        </div>

        <div className='domain-crt-select--mb'>
          <label className='input__label'>Auth Page</label>
          <Select
            options={variantPageOptions}
            value={variantPageOptions[variantPageOptions.findIndex((el) => el.value === options.auth)]}
            onChange={(option) => {
              setOptions({ ...options, auth: option.value });
            }}
            placeholder='Выберите вариант страницы'
          />
        </div>

        <div className='domain-crt-select--mb'>
          <label className='input__label'>Footer</label>
          <Select
            options={variantFooterOptions}
            value={variantFooterOptions[variantFooterOptions.findIndex((el) => el.value === options.footer)]}
            onChange={(option) => {
              setOptions({ ...options, footer: option.value });
            }}
            placeholder='Выберите вариант футера'
          />
        </div>

        <div className='domain-crt-select--mb'>
          <label className='input__label'>LandFooter</label>
          <Select
            options={landFooterOptions}
            value={landFooterOptions[landFooterOptions.findIndex((el) => el.value === options.landFooter)]}
            onChange={(option) => {
              setOptions({ ...options, landFooter: option.value });
            }}
            placeholder='Выберите вариант футера'
          />
        </div>

        {/* {id ? (
          <>
            <div style={{ margin: '32px 0' }}>
              <label className='input__label'>Платежная система</label>
              <div>{curDomain.paymentSystem}</div>
            </div>
            <div style={{ margin: '32px 0' }}>
              <label className='input__label'>Платежный шлюз</label>
              <div>{curDomain.paymentGateway}</div>
            </div>
          </>
        ) : null} */}

        <div className={cn({ 'add-to-domain-payment-settings--error': errors.paymentSettings })}>
          <Table
            content={paymentSettings}
            keyProp='domainPaymentSettings'
            titles={[
              { name: 'Платежная система' },
              { name: 'Платежный шлюз', mod: 'big' },
              { name: 'Вероятность' },
              { name: ' ', mod: 'big' },
              { name: 'No Ref' },
              { name: 'SberBill' },
              { name: '...' },
            ]}
            formatOptionLabel={formatOptionLabel}
            btnDelete={(i) => setPaymentSettings((prev) => prev.filter((_, index) => i !== index))}
            handleChange={handlePayment}
          />

          {/* Добавление настроек платежных систем */}
          <div>
            <h3 className='add-to-domain-payment-settings-title'>Добавить платежные настройки</h3>
            <div className='add-paymentSettingsDomain'>
              {/*<div className='add-to-domain-payment-settings__payment-system'>*/}
              {/*  <label className='input__label'>Платежная система</label>*/}
              {/*  <Select*/}
              {/*    options={paymentSystemToAddOptions}*/}
              {/*    value={paymentSystemToAdd}*/}
              {/*    onChange={setPaymentSystemToAdd}*/}
              {/*  />*/}
              {/*</div>*/}
              {/*<div className='add-to-domain-payment-settings__payment-gateway'>*/}
              {/*  <label className='input__label'>Платежный шлюз</label>*/}
              {/*  <Input*/}
              {/*    value={paymentGatewayToAdd}*/}
              {/*    onChange={(e) => {*/}
              {/*      setPaymentGatewayToAdd(e.target.value);*/}
              {/*    }}*/}
              {/*    placeholder='Платежный шлюз'*/}
              {/*    type='text'*/}
              {/*    inputCls='add-to-domain-payment-settings__input'*/}
              {/*  />*/}
              {/*</div>*/}
              <div className='add-paymentSettingsDomain__paymentSystem'>
                <label className='input__label'>Селектор</label>
                <Select
                  options={paymentSystemToAddOptions2}
                  styles={selectStyles}
                  value={paymentSystemToAddOptions2.filter((option) => option.id === paymentSystemId)}
                  formatOptionLabel={formatOptionLabel}
                  onChange={(data) => {
                    setPaymentSystemToAdd(data.paymentSystem);
                    setPaymentGatewayToAdd(data.name);
                    setPaymentSystemTitleToAdd(data.paymentSystemTitle);
                    setPaymentSystemId(data.id);
                  }}
                  isSearchable={true}
                />
              </div>

              <div className='add-paymentSettingsDomain__chance'>
                <label className='input__label'>Вероятность</label>
                <Input
                  value={paymentChanceToAdd}
                  onChange={handlePaymentChanceToAddChange}
                  placeholder='Вероятность'
                  type='number'
                  inputCls='input__chance'
                />
              </div>
              <div className='input-checkbox-noRef'>
                <label className='input__label'>No Ref</label>
                <input className='input__item' type='checkbox' checked={noRef} onChange={() => setNoRef(!noRef)} />
              </div>
              <div className='input-checkbox-noRef'>
                <label className='input__label'>sberBill</label>
                <input
                  className='input__item'
                  type='checkbox'
                  checked={sberbill}
                  onChange={() => setSberbill(!sberbill)}
                />
              </div>
              <div className='add-paymentSettingsDomain__btn'>
                <button onClick={handlePaymentSettingsAdd} className='button'>
                  Добавить
                </button>
              </div>
            </div>
          </div>
          <div className='add-payment-settings-error-message'>{errors.paymentSettings}</div>
          <div className='add-payment-settings-error-message-2'>
            {errorMessage === 'Different legal entities' ? 'Разные  ЮрЛица платежных шлюзов ' : errorMessage}
          </div>
        </div>

        <label className='input__label'>Реквизиты</label>
        <textarea className='domain-textarea' value={legal} onChange={(e) => setLegal(e.target.value)} />

        <button disabled={!selectedProduct.length} className='button' onClick={handleSaveClick}>
          Сохранить
        </button>
        <Link to='/domain' className='create__prev'>
          Назад к списку
        </Link>
      </div>
    </>
  );
};

export default DomainCrtPage;
