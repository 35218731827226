import React from 'react';
import { useAppDispatch, useAppSelector } from 'store';
import { getWebinars } from 'store/slices/webinar';
import Table from 'assets/components/Table';
import Pagination from 'rc-pagination';
import Loader from 'assets/components/Loader';
import Header from 'assets/components/Header';
import { modalCleanData } from 'store/slices/modal';
import { deleteWebinar, setWebinarPage } from 'store/slices/webinar';

const titles = [
  {
    name: 'ID',
    mod: 'sm',
  },
  {
    name: 'название',
  },
  {
    name: 'domain id',
    mod: 'big',
  },
  {
    name: 'статус',
  },
  {
    name: '. . .',
    mod: 'm',
  },
];

const WebinarPage = () => {
  const modalStatus = useAppSelector((state) => state.modal.modalStatus);
  const dispatch = useAppDispatch();
  const limit = useAppSelector((state) => state.webinars.pagination.limit);
  const page = useAppSelector((state) => state.webinars.pagination.page);
  const total = useAppSelector((state) => state.webinars.pagination.total);
  const webinarList = useAppSelector((state) => state.webinars.webinarList);
  const infoPayload = useAppSelector((state) => state.modal.infoPayload);
  const isLoad = useAppSelector((state) => state.webinars.isLoad);

  React.useEffect(() => {
    const params = {};
    params.limit = limit;
    params.page = page;
    dispatch(getWebinars(params));
  }, [page, limit, dispatch]);

  React.useEffect(() => {
    if (modalStatus === 'yes') {
      dispatch(deleteWebinar(infoPayload.id));
      dispatch(modalCleanData());
    }
  }, [modalStatus]);

  const handlePageChange = (current) => {
    dispatch(setWebinarPage(current));
  };

  return (
    <>
      {isLoad ? <Loader /> : ''}
      <Header title='Вебинар' text='Добавить вебинар' href='/webinars/add' />
      <Table titles={titles} content={webinarList} keyProp='webinars' />
      <Pagination
        className='order__pagination'
        current={page}
        total={total}
        pageSize={limit}
        onChange={handlePageChange}
        showTitle={false}
      />
    </>
  );
};

export default WebinarPage;
