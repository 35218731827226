import { createSlice } from '@reduxjs/toolkit';
import { request } from 'api';
import { setRedirect } from './redirect';
import axios from 'axios';
import { setGatewayData } from './gateway';

const initialState = {
  list: [],
  errors: {},
  curDomain: {},
  folderList: [],
  filters: {
    status: '1',
    products: '',
    link: '',
    companyIds: '',
    paymentGatewayId: '',
    folder: '',
    sberbill: '',
    landFooter: null,
    gateways: ''
  },
  pagination: {
    limit: '50',
    page: 1,
    pages: 1,
    total: 0,
  },
  isLoad: false,
  paymentSystems: [],
  domainStatusList: [],
  errorMessage: '',
  massUpdateMessage: '',
  sortBill: false,
};

const domainSlice = createSlice({
  name: 'domain',
  initialState,
  reducers: {
    setIsLoad(state, action) {
      state.isLoad = action.payload;
    },
    setDomainGetAllData(state, action) {
      state.list = action.payload.items?.map((el) => ({
        ...el,
        createdAt: `${el.createdAt.slice(0, 10).split('-').reverse().join('.')} ${el.createdAt.slice(-8)}`,
      }));
      state.isLoad = false;
      state.pagination.page = action.payload.pagination?.page;
      state.pagination.pages = action.payload.pagination?.pages;
      state.pagination.total = action.payload.pagination?.total;
    },
    setDomainFilter(state, action) {
      state.filters.status = action.payload.status;
      state.filters.products = action.payload.products;
      state.filters.link = action.payload.link;
      state.filters.companyIds = action.payload.companyIds;
      state.filters.paymentGatewayId = action.payload.paymentGatewayId;
      state.filters.folder = action.payload.folder;
      state.filters.sberbill = action.payload.sberbill;
      state.filters.landFooter = action.payload.landFooter;
      state.filters.gateways = action.payload.gateways;
      state.pagination.limit = action.payload.limit;
      state.pagination.page = 1;
    },
    setDomainPage(state, action) {
      state.pagination.page = action.payload;
    },
    setDomainsFolderGetAll(state, action) {
      state.folderList = action.payload;
      state.isLoad = false;
    },
    setDomainCreateSuccess(state) {
      state.isLoad = false;
      state.curDomain = {};
    },
    setDomainCreateFailure(state, action) {
      state.isLoad = false;
      state.errors = action.payload;
    },
    setDomainGetOneRequest(state) {
      state.isLoad = true;
      state.curDomain = {};
    },
    setDomainGetOneSuccess(state, action) {
      state.errors = {};
      state.isLoad = false;
      state.curDomain = { ...action.payload };
    },
    setDomainGetOneFailure(state) {
      state.errors = { domain: 'не найден' };
      state.isLoad = false;
      state.curDomain = {};
    },
    setDomainGetStatuses(state, action) {
      state.domainStatusList = Object.keys(action.payload).map((el) => ({
        value: el,
        label: action.payload[el],
      }));
    },
    setDomainUpdateSuccess(state) {
      state.curDomain = {};
      state.isLoad = false;
    },
    setDomainUpdateError(state, action) {
      state.isLoad = false;
      state.errorMessage = action.payload;
      state.errors = action.payload;
    },
    setDomainMassUpdateStatus(state, action) {
      state.massUpdateMessage = action.payload;
      state.isLoad = false;
    },
    setDomainMassCopyStatus(state, action) {
      state.massCopyMessage = action.payload;
      state.isLoad = false;
    },
    setDomainMassUpdateFailure(state, action) {
      state.isLoad = false;
      state.errors = action.payload;
    },
    setDomainPaymentSystems(state, action) {
      state.paymentSystems = action.payload;
    },
    setSortDomainBill(state) {
      state.list = state.sortBill
        ? state.list.sort((a, b) => {
            return a.statistics.active_bills - b.statistics.active_bills;
          })
        : state.list.sort((a, b) => {
            return b.statistics.active_bills - a.statistics.active_bills;
          });
      state.sortBill = !state.sortBill;
    },
    setDomainCleanErrorsLocale(state) {
      state.errors = '';
    },
    setDomainClearCurDomain(state) {
      state.curDomain = {};
    },
    setErrorMessage(state, action) {
      state.errorMessage = action.payload
    } 
  },
});

const {
  reducer: domain,
  actions: {
    setIsLoad,
    setDomainCreateSuccess,
    setDomainGetAllData,
    setDomainsFolderGetAll,
    setDomainFilter,
    setDomainPage,
    setDomainCreateFailure,
    setDomainGetOneRequest,
    setDomainGetOneSuccess,
    setDomainGetOneFailure,
    setDomainGetStatuses,
    setDomainUpdateError,
    setDomainUpdateSuccess,
    setDomainMassUpdateStatus,
    setDomainMassCopyStatus,
    setDomainMassUpdateFailure,
    setSortDomainBill,
    setDomainPaymentSystems,
    setDomainCleanErrorsLocale,
    setDomainClearCurDomain,
    setErrorMessage
  },
} = domainSlice;

export const domainGetAll = (params) => async (dispatch) => {
  dispatch(setIsLoad(true));
  request
    .get('/admin/domains', { params })
    .then((res) => {
      dispatch(setDomainGetAllData(res.data));
    })
    .catch((error) => {
      dispatch(setErrorMessage(error.response?.data?.detail))
      dispatch(setIsLoad(false))
    });
};

export const domainFolderGetAll = () => async (dispatch) => {
  dispatch(setIsLoad(true));
  request
    .get('/admin/domains/folders')
    .then((res) => {
      dispatch(setDomainsFolderGetAll(res.data));
    })
    .catch(() => dispatch(setIsLoad(false)));
};

export const domainCreate = (payload) => async (dispatch) => {
  dispatch(setIsLoad(true));
  request
    .post('/admin/domains', payload)
    .then(() => {
      dispatch(domainGetAll());
      dispatch(setDomainCreateSuccess());
      dispatch(setRedirect('/domain'));
    })
    .catch((err) => {
      dispatch(setDomainCreateFailure(err.response?.data?.error?.messages || {}));
    });
};

export const domainDelete = (id, filters) => async (dispatch) => {
  dispatch(setIsLoad(true));
  request
    .delete(`/admin/domains/${id}`)
    .then(() => {
      dispatch(domainGetAll(filters));
      dispatch(setIsLoad(false));
    })
    .catch(() => {
      dispatch(setIsLoad(false));
    });
};

export const domainGetOne =
  (id = 0) =>
  async (dispatch) => {
    const fixId = isNaN(id) ? 0 : id;
    dispatch(setDomainGetOneRequest());
    return axios
      .all([
        request.get(`/admin/domains/${fixId}`),
        request.get('/admin/gateways?limit=10000'),
        request.get('/admin/domains/statuses'),
      ])
      .then(
        axios.spread((res1, res2, res3) => {
          dispatch(setDomainGetOneSuccess(res1.data));
          dispatch(setGatewayData(res2.data));
          dispatch(setDomainGetStatuses(res3.data));
        }),
      )
      .catch(() => {
        dispatch(setDomainGetOneFailure());
      });
  };

export const domainUpdate = (id, payload) => async (dispatch) => {
  dispatch(setIsLoad(true));
  request
    .put(`/admin/domains/${id}`, payload)
    .then(() => {
      dispatch(domainGetAll());
      dispatch(setDomainUpdateSuccess());
      dispatch(setRedirect('/domain'));
    })
    .catch((err) => {
      dispatch(setDomainUpdateError(err.response?.data?.error?.message));
    });
};

export const domainMassUpdate = (payload) => async (dispatch) => {
  dispatch(setIsLoad(true));
  request
    .post(`/admin/domains/mass-update`, payload)
    .then(() => {
      dispatch(setIsLoad(false));
      dispatch(setDomainMassUpdateStatus('success'));
    })
    .catch((err) => {
      dispatch(setDomainMassUpdateFailure(err.response?.data?.error?.message));
      dispatch(setDomainMassUpdateStatus('error'));
    });
};

export const domainMassCopy = (payload) => async (dispatch) => {
  dispatch(setIsLoad(true));
  request
      .post(`/admin/domains/mass-copy`, payload)
      .then(() => {
        dispatch(setIsLoad(false));
        dispatch(setDomainMassCopyStatus('success'));
      })
      .catch((err) => {
        dispatch(setDomainMassUpdateFailure(err.response?.data?.error?.message));
        dispatch(setDomainMassCopyStatus('error'));
      });
};

export const domainGetPaymentSystems = () => (dispatch) => {
  return request
    .get('/admin/domains/paymentSystems')
    .then((res) => dispatch(setDomainPaymentSystems(res.data)))
    .catch(console.trace);
};

export { domain, setErrorMessage, setDomainCleanErrorsLocale, setDomainClearCurDomain, setDomainFilter, setDomainPage, setSortDomainBill, setDomainMassUpdateStatus, setDomainMassCopyStatus };
