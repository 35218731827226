import React from 'react';
import Select from 'react-select';

const InputSelect = ({ value, options, onChange, needLabel, classStyle }) => {
  const handleChange = (selectedOption) => {
    if(needLabel) {
      onChange(selectedOption?.label ?? null);
    } else {
      onChange(selectedOption?.value ?? null);
    }
  };
  return (
    <Select
      className={classStyle ? classStyle : 'filters-select'}
      classNamePrefix={classStyle ? classStyle : 'filters-select'}
      value={options.find((o) => o.value === value)}
      options={options}
      onChange={handleChange}
      placeholder='Все'
      isSearchable={false}
      isClearable
    />
  );
};

export { InputSelect };
