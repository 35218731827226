import React, { useEffect, useState } from 'react';
import Input from 'assets/components/Input';
import { FileInput } from 'assets/components/FileInput';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import Loader from '../../assets/components/Loader';
import ProductDescr from 'assets/components/ProductDescr';
import { dashboardOptions } from '../../constants/dashboardOptions/dashboardOptions';
import {
  productCreate,
  setProductCleanErrors,
  setProductCleanRedirect,
  productCategoriesGetAll,
} from 'store/slices/product';
/**
 * store
 */
import { useAppDispatch, useAppSelector } from 'store';
import { getAllCurrencies } from 'store/slices/currency';
import { getAllLanguages } from 'store/slices/language';

const portionSizeOptions = [
  { value: '24', label: 'День' },
  { value: '168', label: 'Неделя' },
];
const workSchemeOptions = [
  { value: 0, label: 'Обычная' },
  { value: 1, label: 'Выкуп' },
];

const designPersonalAreaOption = [
  { value: 0, label: 'Старый' },
  { value: 1, label: 'Новый' },
];

const ProductCrtPage = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const categoriesOption = useAppSelector((state) => state.product.categories);
  const errors = useAppSelector((state) => state.product.errors);
  const redirect = useAppSelector((state) => state.product.redirect);
  const isLoad = useAppSelector((state) => state.product.isLoad);
  const currencies = useAppSelector((state) => state.currency.data);
  const languages = useAppSelector((state) => state.language.data);
  // debugger;
  const [name, setName] = useState('');
  const [innerName, setInnerName] = useState('');
  const [initialPrice, setInitialPrice] = useState('');
  const [price, setPrice] = useState('');
  const [oldPrice, setOldPrice] = useState('');
  const [tariff, setTariff] = useState('');
  const [initialInterval, setInitialInterval] = useState(72);
  const [followingInterval, setFollowingInterval] = useState(168);
  const [refundInitial, setRefundInitial] = useState(true);
  const [description, setDescription] = useState('');
  const [tariffDescription, setTariffDescription] = useState('');
  const [dashboardSelectedOption, setDashboardSelectedOption] = useState(null);
  const [portionSizeSelectedOption, setPortionSizeSelectedOption] = useState(() => portionSizeOptions[0]);
  const [workSchemeSelectedOption, setWorkSchemeSelectedOption] = useState(null);
  const [productBackgroundFile, setProductBackgroundFile] = useState('');
  const [previewBackgroundURL, setPreviewBackgroundURL] = useState('');
  const [productCoverFile, setProductCoverFile] = useState('');
  const [previewCoverURL, setPreviewCoverURL] = useState('');
  const [currencySelectedOption, setCurrencySelectedOption] = useState('');
  const [recalculateDisplayPrice, setRecalculateDisplayPrice] = useState(true);
  const [displayCurrency, setDisplayCurrency] = useState('');
  const [displayPrice, setDisplayPrice] = useState('');
  const [displayOldPrice, setDisplayOldPrice] = useState('');
  const [languageSelectedOption, setLanguageSelectedOption] = useState('');
  const [firstCheckbox, setFirstCheckbox] = useState('');
  const [secondCheckbox, setSecondCheckbox] = useState('');
  const [catalog, setCatalog] = useState(false);
  const [categories, setCategories] = useState([]);
  const [designPersonalArea, setDesignPersonalArea] = useState(0);
  const [contentAccessDays, setContentAccessDays] = useState(7);
  const [withoutToken, setWithoutToken] = useState(false);

  useEffect(() => {
    dispatch(getAllCurrencies());
    dispatch(getAllLanguages());
    dispatch(productCategoriesGetAll());
  }, []);

  useEffect(() => {
    if (errors?.product) {
      dispatch(setProductCleanErrors('product'));
    }
    if (redirect) {
      dispatch(setProductCleanRedirect());
      history.goBack();
    }
  }, [history, errors?.product, redirect, setProductCleanErrors, setProductCleanRedirect]);

  const handleCreateProduct = () => {
    dispatch(
      productCreate({
        innerName,
        name,
        initialPrice,
        price,
        oldPrice,
        tariff,
        initialInterval,
        followingInterval,
        refundInitial,
        files: [],
        description,
        tariffDescription,
        portionSize: +portionSizeSelectedOption.value,
        dashboard: dashboardSelectedOption ? dashboardSelectedOption.value : null,
        workScheme: workSchemeSelectedOption ? workSchemeSelectedOption.value : 0,
        background: productBackgroundFile,
        cover: productCoverFile,
        recalculateDisplayPrice,
        displayCurrency: displayCurrency.label ? displayCurrency.label : 'RUB',
        displayPrice,
        displayOldPrice,
        currency: currencySelectedOption.label ? currencySelectedOption.label : 'RUB',
        language: languageSelectedOption.label ? languageSelectedOption.label : 'ru',
        firstCheckbox,
        secondCheckbox,
        public: catalog,
        categories,
        designPersonalArea,
        contentAccessDays,
        withoutToken,
      }),
    );
  };

  return (
    <>
      <div className='create'>
        {isLoad ? <Loader /> : ''}
        <h2 className='create__title'>Создание продукта</h2>
        <div className='create__inner'>
          <div className='create__inputs'>
            {/* Название */}
            <Input
              placeholder=''
              type='text'
              name='innerName'
              inputCls='create__input'
              label='Название внутреннее'
              onChange={(e) => {
                setInnerName(e.target.value);
                if (errors.innerName) dispatch(setProductCleanErrors('innerName'));
              }}
              value={innerName}
              error={errors.innerName || ''}
            />
            <Input
              placeholder=''
              type='text'
              name='name'
              inputCls='create__input'
              label='Название для клиента'
              onChange={(e) => {
                setName(e.target.value);
                if (errors.name) dispatch(setProductCleanErrors('name'));
              }}
              value={name}
              error={errors.name || ''}
            />
            <Input
              placeholder=''
              type='text'
              name='contentAccessDays'
              inputCls='create__input'
              label='Кол-во дней контента предоставляемых при покупке(билле)'
              onChange={(e) => {
                setContentAccessDays(parseInt(e.target.value) || '');
              }}
              value={contentAccessDays}
              error={errors.contentAccessDays || ''}
            />
            {/* Язык */}
            <div className='product-crt-select'>
              <label className='input__label'>Язык</label>
              <Select
                options={languages}
                value={languageSelectedOption}
                onChange={setLanguageSelectedOption}
                placeholder='Выберите язык'
              />
            </div>
            {/* Валюта */}
            <div className='product-crt-select'>
              <label className='input__label'>Валюта</label>
              <Select
                options={currencies}
                value={currencySelectedOption}
                onChange={setCurrencySelectedOption}
                placeholder='Выберите валюту'
              />
            </div>
            {/* Изначальная цена */}
            <Input
              placeholder=''
              type='text'
              name='initialPrice'
              inputCls='create__input'
              label='Инициализационная цена'
              onChange={(e) => {
                setInitialPrice(e.target.value);
                if (errors.initialPrice) dispatch(setProductCleanErrors('initialPrice'));
              }}
              value={initialPrice}
              error={errors.initialPrice || ''}
            />
            <div className='input input-checkbox'>
              <label className='input__label'>Возврат инициализационного платежа</label>
              <input
                className='input__item'
                type='checkbox'
                checked={refundInitial}
                onChange={() => setRefundInitial(!refundInitial)}
              />
            </div>
            {/* Цена */}
            <Input
              placeholder=''
              type='text'
              name='price'
              inputCls='create__input'
              label='Цена'
              onChange={(e) => {
                setPrice(e.target.value);
                if (errors.price) dispatch(setProductCleanErrors('price'));
              }}
              value={price}
              error={errors.price || ''}
            />
            {/* Старая цена */}
            <Input
              placeholder=''
              type='text'
              name='oldPrice'
              inputCls='create__input'
              label='Старая цена'
              onChange={(e) => {
                setOldPrice(e.target.value);
                if (errors.oldPrice) dispatch(setProductCleanErrors('oldPrice'));
              }}
              value={oldPrice}
              error={errors.oldPrice || ''}
            />
            <div className='input input-checkbox'>
              <label className='input__label'>Без токенизации</label>
              <input
                className='input__item'
                type='checkbox'
                checked={withoutToken}
                onChange={() => setWithoutToken(!withoutToken)}
              />
            </div>
            {/* Тариф */}
            <Input
              placeholder=''
              type='text'
              name='tariff'
              inputCls='create__input'
              label='Тариф'
              onChange={(e) => {
                setTariff(e.target.value);
                if (errors.recurrentPrice) dispatch(setProductCleanErrors('tariff'));
              }}
              value={tariff}
              error={errors.tariff || ''}
            />
            <div className='product-crt-select'>
              <label className='input__label'>Валюта (на ленде)</label>
              <Select
                options={currencies}
                value={displayCurrency}
                onChange={setDisplayCurrency}
                placeholder='Выберите Валюту (на ленде)'
              />
            </div>
            {/* Автоматически пересчитывать цену на ленде */}
            <div className='recalculate-checkbox input-checkbox'>
              <label>Автоматически пересчитывать цену на ленде</label>
              <input
                type='checkbox'
                checked={recalculateDisplayPrice}
                onChange={() => setRecalculateDisplayPrice(!recalculateDisplayPrice)}
              />
            </div>
            <div className='recalculate-checkbox input-checkbox'>
              <label>Показывать в витрине</label>
              <input type='checkbox' checked={catalog} onChange={() => setCatalog(!catalog)} />
            </div>
            <Input
              placeholder=''
              type='text'
              name='displayPrice'
              inputCls='create__input'
              label='Цена (на ленде)'
              onChange={(e) => {
                setDisplayPrice(e.target.value);
                if (errors.displayPrice) dispatch(setProductCleanErrors('displayPrice'));
              }}
              value={displayPrice}
              error={errors.displayPrice || ''}
              disabled={recalculateDisplayPrice}
            />
            <Input
              placeholder=''
              type='text'
              name='displayOldPrice'
              inputCls='create__input'
              label='Старая цена (на ленде)'
              onChange={(e) => {
                setDisplayOldPrice(e.target.value);
                if (errors.displayOldPrice) dispatch(setProductCleanErrors('displayOldPrice'));
              }}
              value={displayOldPrice}
              error={errors.displayOldPrice || ''}
              disabled={recalculateDisplayPrice}
            />
            {/* Время до первого ребилла (в часах) */}
            <Input
              placeholder=''
              type='text'
              name='initialInterval'
              inputCls='create__input'
              label='Время до первого ребилла (в часах)'
              onChange={(e) => {
                setInitialInterval(e.target.value);
                if (errors.initialInterval) dispatch(setProductCleanErrors('initialInterval'));
              }}
              value={initialInterval}
              error={errors.initialInterval || ''}
            />
            {/* Время между ребиллами (в часах) */}
            <Input
              placeholder=''
              type='text'
              name='followingInterval'
              inputCls='create__input'
              label='Время между ребиллами (в часах)'
              onChange={(e) => {
                setFollowingInterval(e.target.value);
                if (errors.followingInterval) dispatch(setProductCleanErrors('followingInterval'));
              }}
              value={followingInterval}
              error={errors.followingInterval || ''}
            />
            <div className='create__textarea'>
              <div className='create__textfield-wrapper'>
                <label className='input__label'>Текст первого чекбокса</label>
                <div className='textarea-checkboxValue'>
                  <textarea
                    onChange={(e) => {
                      setFirstCheckbox(e.target.value);
                    }}
                    placeholder=''
                    name='firstCheckbox'
                    value={firstCheckbox}
                    wrap='soft'
                  />
                  <div>
                    {`{initial_interval_days} => первое списание в днях`} <br />
                    {`{initial_interval_hours} => первое списание в часах`} <br />
                    {`{initial_sum} => сумма первого списания`} <br />
                    <br />
                    {`{following_interval_days} => ребилл согласно тарифу в днях`} <br />
                    {`{following_interval_hours} => ребилл согласно тарифу в часах`} <br />
                    {`{following_interval_sum} => сумма ребилла за весь интервал`} <br />
                    {`{following_day_sum} => сумма ребилла с пересчетом на день`} <br />
                    <br />
                    {`{link_permission} => url на защиту ПД`} <br />
                    {`{link_offer} => url на офферту`} <br />
                    {`{link_tariff} => url на тариф`} <br />
                    {`{link_cabinet} => url на ЛК`} <br />
                  </div>
                </div>
              </div>
            </div>
            <div className='create__textarea'>
              <div className='create__textfield-wrapper'>
                <label className='input__label'>Текст второго чекбокса</label>
                <div className='textarea-checkboxValue'>
                  <textarea
                    onChange={(e) => {
                      setSecondCheckbox(e.target.value);
                    }}
                    placeholder=''
                    name='secondCheckbox'
                    value={secondCheckbox}
                    wrap='soft'
                  />
                  <div>
                    {`{initial_interval_days} => первое списание в днях`} <br />
                    {`{initial_interval_hours} => первое списание в часах`} <br />
                    {`{initial_sum} => сумма первого списания`} <br />
                    <br />
                    {`{following_interval_days} => ребилл согласно тарифу в днях`} <br />
                    {`{following_interval_hours} => ребилл согласно тарифу в часах`} <br />
                    {`{following_interval_sum} => сумма ребилла за весь интервал`} <br />
                    {`{following_day_sum} => сумма ребилла с пересчетом на день`} <br />
                    <br />
                    {`{link_permission} => url на защиту ПД`} <br />
                    {`{link_offer} => url на офферту`} <br />
                    {`{link_tariff} => url на тариф`} <br />
                    {`{link_cabinet} => url на ЛК`} <br />
                  </div>
                </div>
              </div>
            </div>
            {/* тип дашборда */}
            <div className='product-crt-select'>
              <label className='input__label'>Тип дашборда</label>
              <Select
                options={dashboardOptions}
                value={dashboardSelectedOption}
                onChange={setDashboardSelectedOption}
                placeholder='Выберите тип дашборда'
                isClearable
              />
            </div>
            {/* Размер выдачи контента */}
            <div className='product-crt-select'>
              <label className='input__label'>Размер выдачи контента</label>
              <Select
                options={portionSizeOptions}
                value={portionSizeSelectedOption}
                onChange={setPortionSizeSelectedOption}
                placeholder='Выберите размер выдачи'
              />
            </div>
            {/* Схемы работы */}
            <div className='product-crt-select'>
              <label className='input__label'>Схемы работы</label>
              <Select
                options={workSchemeOptions}
                value={workSchemeSelectedOption}
                onChange={setWorkSchemeSelectedOption}
                placeholder='Выберите схемы работы'
              />
            </div>

            <div className='product-crt-select'>
              <label className='input__label'>Категория продукта</label>
              <Select
                isMulti={true}
                options={categoriesOption}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id}
                value={categories}
                // onChange={(o) => setCategories(o.map((item) => item.id))}
                onChange={setCategories}
                placeholder='Выберите категорию'
              />
            </div>

            <div className='product-crt-select'>
              <label className='input__label'>Дизайн личного кабинета</label>
              <Select
                options={designPersonalAreaOption}
                value={
                  designPersonalAreaOption[designPersonalAreaOption.findIndex((el) => el.value === designPersonalArea)]
                }
                onChange={(option) => setDesignPersonalArea(option.value)}
                placeholder='Выберите дизайн ЛК'
              />
            </div>

            <FileInput
              label='Загрузки файла фона'
              name='create-product-background'
              previewURL={previewBackgroundURL ? previewBackgroundURL : ''}
              onChange={(e) => {
                let reader = new FileReader();
                let file = e.target.files[0];
                reader.onloadend = () => {
                  setProductBackgroundFile(file);
                  setPreviewBackgroundURL(reader.result);
                };
                reader.readAsDataURL(file);
              }}
              inputCls='create-file-input'
            />
            <FileInput
              label='Загрузки файла обложки'
              name='create-product-cover'
              previewURL={previewCoverURL ? previewCoverURL : ''}
              onChange={(e) => {
                let reader = new FileReader();
                let file = e.target.files[0];
                reader.onloadend = () => {
                  setProductCoverFile(file);
                  setPreviewCoverURL(reader.result);
                };
                reader.readAsDataURL(file);
              }}
              inputCls='create-file-input'
            />
          </div>
          <div className='create__inputs'>
            <div className='create__textfield-wrapper'>
              <label>Описание продукта</label>
              <ProductDescr
                className='create__textarea'
                cols='50'
                rows='15'
                placeholder=''
                name='description'
                onChange={(e) => {
                  setDescription(e.target.value);
                  if (errors.description) dispatch(setProductCleanErrors('description'));
                }}
                value={description}
                error={errors.description || ''}
              />
            </div>
            <div className='create__textfield-wrapper'>
              <label>Архив тарифов</label>
              <ProductDescr
                className='create__textarea'
                cols='50'
                rows='15'
                placeholder=''
                onChange={(e) => {
                  setTariffDescription(e.target.value);
                  if (errors.tariffDescription) dispatch(setProductCleanErrors('tariffDescription'));
                }}
                value={tariffDescription}
                error={errors.tariffDescription || ''}
              />
            </div>
          </div>
        </div>

        <button className='button' onClick={handleCreateProduct}>
          Сохранить
        </button>
        <Link className='create__prev' to='/product'>
          Назад к списку
        </Link>
      </div>
    </>
  );
};

export default ProductCrtPage;
