import { createSlice } from '@reduxjs/toolkit'
import { request } from 'api'

const initialState = {
  isLoad: false,
  list: [],
  listSummary: [],
  filters: {
    paymentSystem: '',
    currency: '',
    dateFrom: null,
    dateTo: null,
    companyId: '',
    types: '',
    products: '',
    webmasterId: '',
    domain: '',
    banks: '',
    gateways: '',
  },
}

const analyticsAllSlice = createSlice({
  name: 'analyticsall',
  initialState,
  reducers: {
    setIsLoad(state, action) {
      state.isLoad = action.payload
    },
    setAnalyticsStatsData(state, action) {
      state.list = Object.entries(action.payload || {})?.filter((data) => !data.includes('summary'))
      state.listSummary = Object.entries(action.payload).filter((data) => data.includes('summary'))[0][1]
      state.isLoad = false
    },
    setAnalyticsStatsFilter(state, action) {
      state.filters.dateFrom = action.payload.dateFrom
      state.filters.dateTo = action.payload.dateTo
      state.filters.currency = action.payload.currency
      state.filters.paymentSystem = action.payload.paymentSystem
      state.filters.companyId = action.payload.companyId
      state.filters.types = action.payload.types
      state.filters.products = action.payload.products
      state.filters.webmasterId = action.payload.webmasterId
      state.filters.domain = action.payload.domain
      state.filters.banks = action.payload.banks
      state.filters.gateways = action.payload.gateways
    },
  },
})

const {
  reducer: analyticsall,
  actions: { setIsLoad, setAnalyticsStatsData, setAnalyticsStatsFilter },
} = analyticsAllSlice

export const getAnalyticsStats = (params) => async (dispatch) => {
  dispatch(setIsLoad(true))
  return request
    .get('/admin/analytics/new-summary', { params })
    .then((res) => dispatch(setAnalyticsStatsData(res.data)))
    .catch(() => dispatch(setIsLoad(false)))
    .finally(() => dispatch(setIsLoad(false)))
}

export { analyticsall, setAnalyticsStatsFilter }
