import { createSlice } from '@reduxjs/toolkit';
import { request } from 'api';
import { setRedirect } from './redirect';

const initialState = {
  webinarList: [],
  isLoad: false,
  pagination: {
    limit: '50',
    page: 1,
    pages: 1,
    total: 0,
  },
  errors: null,
};

const webinarsSlice = createSlice({
  name: 'webinars',
  initialState,
  reducers: {
    setIsLoad(state, action) {
      state.isLoad = action.payload;
    },
    setWebinarsList(state, action) {
      state.webinarList = action.payload.items;
    },
    setError(state, action) {
      state.errors = action.payload;
    },
    setWebinarPage(state, action) {
      state.pagination.page = action.payload;
    },
  },
});

const {
  reducer: webinars,
  actions: { setIsLoad, setWebinarsList, setError, setWebinarPage },
} = webinarsSlice;

export const getWebinars = (params) => async (dispatch) => {
  dispatch(setIsLoad(true));
  request
    .get('/admin/webinars', { params })
    .then((res) => {
      dispatch(setWebinarsList(res.data));
      dispatch(setIsLoad(false));
    })
    .catch(() => {
      dispatch(setIsLoad(false));
    });
};

export const createWebinar = (payload) => async (dispatch) => {
  dispatch(setIsLoad(true));
  return request.post('/admin/webinars', { ...payload });
};

export const getSelectedWebinar = (id) => async (dispatch) => {
  dispatch(setIsLoad(true));
  return request
    .get(`/admin/webinars/${id}`)
    .catch(() => dispatch(setIsLoad(false)))
    .finally(() => dispatch(setIsLoad(false)));
};

export const updateWebinar = (id, payload) => async (dispatch) => {
  dispatch(setIsLoad(true));
  return request
    .put(`/admin/webinars/${id}`, payload)
    .then(() => {
      dispatch(setIsLoad(false));
      dispatch(setRedirect('/webinars'));
    })
    .catch((error) => {
      dispatch(setError(Object.values(error.response.data.error?.messages)[0]));
      dispatch(setIsLoad(false));
    });
};

export const deleteWebinar = (id) => (dispatch) => {
  dispatch(setIsLoad(true));
  return request
    .delete(`/admin/webinars/${id}`)
    .then(() => {
      dispatch(getWebinars());
      dispatch(setIsLoad(false));
    })
    .catch(() => {
      dispatch(setIsLoad(false));
    });
};

export { webinars, setError, setIsLoad, setWebinarPage };
