import React, { useEffect, useState } from 'react'
import { productGetOne, setProductCleanErrors } from 'store/slices/product'
import { setCleanErrors, setCleanRedirect, pixelCreate, pixelGetType } from 'store/slices/pixel'
import Input from 'assets/components/Input'
import ButtonLink from 'assets/components/ButtonLink'
import { useHistory, useParams } from 'react-router-dom'
import DropDown from '../../assets/components/DropDown'
import Loader from '../../assets/components/Loader'
import { useAppDispatch, useAppSelector } from 'store'
import { toast } from 'react-toastify'

const PixelCrtPage = () => {
  const dispatch = useAppDispatch()
  const { productId } = useParams()
  const productName = useAppSelector((state) => state.product.curProduct.name)
  const productError = useAppSelector((state) => state.product.errors.product)
  const errors = useAppSelector((state) => state.pixel.errors)
  const redirect = useAppSelector((state) => state.pixel.redirect)
  const isLoad = useAppSelector((state) => state.pixel.isLoad)
  const types = useAppSelector((state) => state.pixel.types)
  const [type, setType] = useState([])
  const [value, setValue] = useState('')
  const [firstRun, setFirstRun] = useState(true)
  const history = useHistory()

  // debugger;
  let pixelTypes = []
  for (let key in types) {
    pixelTypes.push({ name: types[key], value: key, check: false })
  }

  useEffect(() => {
    if (errors) {
      toast.error(errors)
      dispatch(setCleanErrors())
    }
  }, [errors])

  useEffect(() => {
    if (productError) {
      dispatch(setProductCleanErrors('product'))
      history.goBack()
    }

    if (redirect) {
      dispatch(setCleanRedirect())
      history.goBack()
    }
  }, [redirect, productError])

  useEffect(() => {
    if (firstRun) {
      setFirstRun(false)
      dispatch(productGetOne(productId))
    }
  }, [productName])

  useEffect(() => {
    dispatch(pixelGetType())
    setType(pixelTypes)
    window.scrollTo(0, 0)
  }, [pixelTypes.length])

  return (
    <>
      <div className="create">
        {isLoad ? <Loader /> : ''}
        <h2 className="create__title">Создание пикселя</h2>
        <label className="create__label">Тип пикселя</label>
        <DropDown text="Pixels" arrayOfValues={type} onChange={setType} radio />
        <Input
          value={value}
          onChange={(e) => {
            setValue(e.target.value)
          }}
          placeholder="Значение"
          type="text"
          name="value"
          inputCls="create__input"
          label="Значение"
        />
        <p className="create__info">
          Этот пиксель будет прикреплен к продукту {productName} (ID: {productId})
        </p>
        <button
          className="button"
          onClick={() => {
            dispatch(
              pixelCreate({
                value,
                productId: +productId,
                type: type.find(({ check }) => check) ? type.find(({ check }) => check).value : '',
              }),
            )
          }}
        >
          Сохранить
        </button>
        <ButtonLink text="Назад к списку" btnClass="create__prev" onClick={() => history.goBack()} />
      </div>
    </>
  )
}

export default PixelCrtPage
