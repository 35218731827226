import { createSlice } from '@reduxjs/toolkit';
import { request } from 'api';

const initialState = {
  isLoading: false,
  errorsList: [],
  error: null,
  filters: {
    companyId: '',
    dateFrom: '',
    dateTo: '',
    domain: '',
    webmaster: '',
    paymentGatewayId: '',
    products: '',
    companies: '',
    issuer: '',
    type: '',
    errors: '',
  },
  pagination: {
    limit: '50',
    page: 1,
    pages: 1,
    total: 0,
  },
  sortErrorCount: false,
  sortErrorDate: false,
  errorsChainList: [],
  filtersChain: {
    orderIds: '',
    webmaster: '',
    dateFrom: '',
    dateTo: '',
    companies: '',
    issuer: '',
    step: '',
    stepNone: '',
    type: '',
    status: '',
    paymentGatewayIds: '',
    products: '',
    paymentSystem: '',
    issuers: '',
  },
  paginationChain: {
    limit: '50',
    page: 1,
    pages: 1,
    total: 0,
  },
  sortErrorChainsDate: false,
  sortErrorChainsDateAndTime: false,
};

const errorsSlice = createSlice({
  name: 'errors',
  initialState,
  reducers: {
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setErrorsList(state, action) {
      state.errorsList = action.payload.items;
      state.pagination.page = action.payload.pagination?.page;
      state.pagination.pages = action.payload.pagination?.pages;
      state.pagination.total = action.payload.pagination?.total;
    },
    setError(state, action) {
      state.error = action.payload;
    },
    setErrorsFilter(state, action) {
      state.filters.companies = action.payload.companies;
      state.filters.dateFrom = action.payload.dateFrom;
      state.filters.dateTo = action.payload.dateTo;
      state.filters.domain = action.payload.domain;
      state.filters.webmaster = action.payload.webmaster;
      state.filters.paymentGatewayId = action.payload.paymentGatewayId;
      state.filters.products = action.payload.products;
      state.filters.issuer = action.payload.issuer;
      state.filters.type = action.payload.type;
      state.filters.errors = action.payload.errors;
      state.pagination.limit = action.payload.limit;
      state.pagination.page = 1;
    },
    setErrorsPage(state, action) {
      state.pagination.page = action.payload;
    },
    setSortErrorListCount(state) {
      state.errorsList = state.sortErrorCount
        ? state.errorsList.sort((a, b) => {
            return a.err_count - b.err_count;
          })
        : state.errorsList.sort((a, b) => {
            return b.err_count - a.err_count;
          });
      state.sortErrorCount = !state.sortErrorCount;
    },
    setSortErrorListDate(state) {
      state.errorsList = state.sortErrorDate
        ? state.errorsList.sort((a, b) => {
            return Number(a.created_at.split('-').join('')) - Number(b.created_at.split('-').join(''));
          })
        : state.errorsList.sort((a, b) => {
            return Number(b.created_at.split('-').join('')) - Number(a.created_at.split('-').join(''));
          });
      state.sortErrorDate = !state.sortErrorDate;
    },
    setChainErrorsList(state, action) {
      state.errorsChainList = action.payload.items;
      state.paginationChain.page = action.payload.pagination?.page;
      state.paginationChain.pages = action.payload.pagination?.pages;
      state.paginationChain.total = action.payload.pagination?.total;
    },
    setChainErrorsPage(state, action) {
      state.paginationChain.page = action.payload;
    },
    setChainErrorsFilter(state, action) {
      state.filtersChain.orderIds = action.payload.orderIds;
      state.filtersChain.webmaster = action.payload.webmaster;
      state.filtersChain.companies = action.payload.companies;
      state.filtersChain.dateFrom = action.payload.dateFrom;
      state.filtersChain.dateTo = action.payload.dateTo;
      state.filtersChain.issuer = action.payload.issuer;
      state.filtersChain.step = action.payload.step;
      state.filtersChain.stepNone = action.payload.stepNone;
      state.filtersChain.type = action.payload.type;
      state.filtersChain.paymentGatewayIds = action.payload.paymentGatewayIds;
      state.filtersChain.status = action.payload.status;
      state.filtersChain.products = action.payload.products;
      state.filtersChain.paymentSystem = action.payload.paymentSystem;
      state.filtersChain.issuers = action.payload.issuers;
      state.paginationChain.limit = action.payload.limit;
      state.paginationChain.page = 1;
    },
    setSortChainErrorListDate(state) {
      state.errorsChainList = state.sortErrorChainsDate
        ? state.errorsChainList.sort((a, b) => {
            return Number(a.created_at.split('-').join('')) - Number(b.created_at.split('-').join(''));
          })
        : state.errorsChainList.sort((a, b) => {
            return Number(b.created_at.split('-').join('')) - Number(a.created_at.split('-').join(''));
          });
      state.sortErrorChainsDate = !state.sortErrorChainsDate;
    },
    setSortChainErrorListDateAndTime(state) {
      state.errorsChainList = state.sortErrorChainsDateAndTime
        ? state.errorsChainList.sort((a, b) => {
            return Number(a.data[a.data.length - 1].op_cr_at.split(/[|\-|:|\s]/).join('')) - Number(b.data[b.data.length - 1].op_cr_at.split(/[|\-|:|\s]/).join(''));
          })
        : state.errorsChainList.sort((a, b) => {
            return Number(b.data[b.data.length - 1].op_cr_at.split(/[|\-|:|\s]/).join('')) - Number(a.data[a.data.length - 1].op_cr_at.split(/[|\-|:|\s]/).join(''));
          });
      state.sortErrorChainsDateAndTime = !state.sortErrorChainsDateAndTime;
    },
  },
});

const {
  reducer: errors,
  actions: {
    setSortErrorListDate,
    setSortErrorListCount,
    setSortChainErrorListDate,
    setIsLoading,
    setErrorsPage,
    setErrorsList,
    setError,
    setErrorsFilter,
    setChainErrorsList,
    setChainErrorsPage,
    setChainErrorsFilter,
    setSortChainErrorListDateAndTime
  },
} = errorsSlice;

const getErrorsList = (params) => async (dispatch) => {
  dispatch(setIsLoading(true));
  return request
    .get('/admin/errors', { params })
    .then((res) => dispatch(setErrorsList(res.data)))
    .catch((err) => dispatch(setError(err.response?.data?.error?.message ?? 'Ошибка при загрузке erorrs')))
    .finally(() => dispatch(setIsLoading(false)));
};

const getChainList = (params) => async (dispatch) => {
  dispatch(setIsLoading(true));
  return request
    .get('/admin/errors/chain', { params })
    .then((res) => dispatch(setChainErrorsList(res.data)))
    .catch((err) => dispatch(setError(err.response?.data?.error?.message ?? 'Ошибка при загрузке erorrs')))
    .finally(() => dispatch(setIsLoading(false)));
};

export {
  errors,
  getErrorsList,
  setErrorsFilter,
  setSortChainErrorListDate,
  setErrorsPage,
  setSortErrorListCount,
  setSortErrorListDate,
  getChainList,
  setChainErrorsList,
  setChainErrorsPage,
  setChainErrorsFilter,
  setSortChainErrorListDateAndTime,
};
