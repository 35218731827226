import React from 'react'
import cn from 'classnames'
import { TitlesMapping, ContentMapping } from './components/mappingFunctions'

const Table = ({
  titles,
  style = {},
  content,
  keyProp,
  productId = 0,
  gatewaysId = 0,
  showModal,
  btnDelete,
  btnCopy,
  className,
  handleChange,
  btnEdit,
  handleEditButton,
  handleEditRefundOrder,
  handleEditChargebackOrder,
  handleUpdateShakerButton,
  downloadStatistics,
  dateFrom,
  dateTo,
  handleUpdateSberAlertButton,
  optionsCompany,
  isCheck,
  isCheckAll,
  handleClickStartEdit,
  handleClickStartCopy,
  handleClickCheckSelectAll,
  handleClickCheck,
  productsObject,
  companiesObject,
  gatewaysObject,
  formatOptionLabel,
  getPaymentSummary,
  availableCancelLead,
  fixed,
}) => {
  return (
    <div style={style} className={cn('table')}>
      <TitlesMapping titles={titles} fixed={fixed} />
      <div className="table__inner">
        <ContentMapping
          keyProp={keyProp}
          content={content}
          btnDelete={btnDelete ?? showModal}
          productId={productId}
          gatewaysId={gatewaysId}
          handleChange={handleChange ?? showModal}
          btnEdit={btnEdit ?? showModal}
          btnCopy={btnCopy ?? showModal}
          handleEditButton={handleEditButton}
          handleEditRefundOrder={handleEditRefundOrder}
          handleEditChargebackOrder={handleEditChargebackOrder}
          availableCancelLead={availableCancelLead}
          handleUpdateShakerButton={handleUpdateShakerButton}
          downloadStatistics={downloadStatistics}
          dateFrom={dateFrom}
          dateTo={dateTo}
          handleUpdateSberAlertButton={handleUpdateSberAlertButton}
          optionsCompany={optionsCompany}
          handleClickCheck={handleClickCheck}
          isCheck={isCheck}
          isCheckAll={isCheckAll}
          handleClickCheckSelectAll={handleClickCheckSelectAll}
          handleClickStartEdit={handleClickStartEdit}
          handleClickStartCopy={handleClickStartCopy}
          productsObject={productsObject}
          companiesObject={companiesObject}
          gatewaysObject={gatewaysObject}
          formatOptionLabel={formatOptionLabel}
          getPaymentSummary={getPaymentSummary}
        />
      </div>
    </div>
  )
}

export default Table
