import React from 'react';
import Input from 'assets/components/Input';
import { useAppDispatch, useAppSelector } from '../../../store';
import { modalCleanData } from '../../../store/slices/modal';
import './CopyModalDomain.scss';

const CopyModalDomain = ({
    setNewLinkName,
    newLinkName,
    setActiveStatus,
    subStringLinkName,
    handleClickCloseTable,
    handleClickMassCopy,
    handleChangeSubStringLinkDomain,
}) => {
    const dispatch = useAppDispatch();
    const modalStatus = useAppSelector((state) => state.modal.modalStatus);

    React.useEffect(() => {
        if (modalStatus === 'changeStatus') {
            dispatch(modalCleanData());
        }
        if (modalStatus === 'No') {
            setActiveStatus('');
            dispatch(modalCleanData());
        }
    }, [modalStatus]);

    const handleChangeDomain = React.useCallback((event) => setNewLinkName(event.target.value), []);

    return (
        <div className="copyModalDomain">
            <div className="copyModalDomain__container">
                <div className="copyModalDomain__header">
                    <div>Групповое копирование</div>
                    <div className="copyModalDomain__header__close" onClick={handleClickCloseTable}>
                        X
                    </div>
                </div>
                <div>
                    <div className="add-damain">
                        <label className="input__label">Подстрока для замены</label>
                        <Input
                            value={subStringLinkName}
                            onChange={handleChangeSubStringLinkDomain}
                            placeholder="Подстрока для замены"
                            type="string"
                            inputCls="input__sub_string-domain"
                        />
                        <label className="input__label">Хост домена</label>
                        <Input
                            value={newLinkName}
                            onChange={handleChangeDomain}
                            placeholder="Хост домена"
                            type="string"
                            inputCls="input__domain"
                        />
                        <div className="copyModalDomain__buttons">
                            <button
                                type="button"
                                onClick={handleClickCloseTable}
                                className="copyModalDomain__buttons__cancel"
                            >
                                Отмена
                            </button>
                            <button
                                type="button"
                                onClick={handleClickMassCopy}
                                className="copyModalDomain__buttons__add"
                            >
                                Сохранить
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export { CopyModalDomain };
