import * as React from 'react'
import Header from 'assets/components/Header'
import Table from 'assets/components/Table'
import { useAppDispatch, useAppSelector } from 'store'
import { getAnalyticsStats, setAnalyticsStatsFilter } from 'store/slices/analyticsall'
import { companyGetAll } from 'store/slices/company'
import { domainGetPaymentSystems } from 'store/slices/domain'
import { getBankListStatus } from 'store/slices/order'
import { getAllGateways } from 'store/slices/gateway'
import { productGetAll } from 'store/slices/product'
import Filters from 'assets/components/Filters'
import Loader from 'assets/components/Loader'

const analyticsTitle = [
  {
    name: 'Продукты',
    mod: 'analytics',
  },
  {
    name: 'биллы',
  },
  {
    name: 'РЕБИЛЛ',
  },
  {
    name: '% АКТИВНЫХ',
  },
  {
    name: 'LTV',
  },
  {
    name: 'ВСЕГО ₽ ',
  },
  {
    name: 'ВЕБУ ₽',
  },
  {
    name: 'УСПЕШНЫХ ТРАНЗАКЦИЙ',
    mod: 'analytics',
  },
  {
    name: '%Чрдж',
    mod: 'analytics',
  },
  {
    name: '%БНК',
    mod: 'analytics',
  },
  {
    name: '%ОТПИСКИ до РЕБИЛЛА',
    mod: 'analytics',
  },
  {
    name: '%ОТПИСКИ РЕБИЛЛ 1',
    mod: 'analytics',
  },
  {
    name: '%ОТПИСКИ РЕБИЛЛ 2',
    mod: 'analytics',
  },
  {
    name: '%ОТПИСКИ РЕБИЛЛ 3',
    mod: 'analytics',
  },
  {
    name: '%ОТПИСКИ РЕБИЛЛ 4',
    mod: 'analytics',
  },
  {
    name: '%ОТПИСКИ РЕБИЛЛ 5',
    mod: 'analytics',
  },
  {
    name: '%ОТПИСКИ РЕБИЛЛ 6',
    mod: 'analytics',
  },
  {
    name: '%ОТПИСКИ РЕБИЛЛ 7',
    mod: 'analytics',
  },
  {
    name: '%ОТПИСКИ РЕБИЛЛ 8',
    mod: 'analytics',
  },
  {
    name: '%ОТПИСКИ РЕБИЛЛ 9',
    mod: 'analytics',
  },
  {
    name: '%ОТПИСКИ РЕБИЛЛ 10',
    mod: 'analytics',
  },
]

const currency = [
  { value: 'RUB', label: 'RUB' },
  { value: 'USD', label: 'USD' },
  { value: 'EUR', label: 'EUR' },
]

const formatOptionLabel = ({ paymentSystem, name, id, companyName }) => (
  <div>
    <div>
      <strong>ID: </strong>
      {id}
    </div>
    <div>
      <strong>Платёжная система: </strong>
      {paymentSystem}
    </div>
    <div>
      <strong>Платёжный шлюз: </strong>
      {name}
    </div>
    <div>
      <strong>ЮрЛицо: </strong>
      {companyName ? companyName : '—'}
    </div>
  </div>
)

function AnalyticsStatsPage() {
  const dispatch = useAppDispatch()
  const gateways = useAppSelector((state) => state.gateway.gateways)
  const analytics_data = useAppSelector((state) => state.analyticsall?.list)
  const isLoad = useAppSelector((state) => state.analyticsall.isLoad)
  const isLoading = useAppSelector((state) => state.gateway.isLoading)
  const appliedFilters = useAppSelector((state) => state.analyticsall.filters)
  const productsListOption = useAppSelector((state) => state.product.list)
    .filter((item) => item.innerName !== '')
    .reverse()
    .map((option) => ({ label: option.innerName, value: option.id }))
  const bankList = useAppSelector((state) => state.order.bankList)?.map((item) => ({
    value: item.id,
    label: item.name,
  }))
  const analyticsSummary = useAppSelector((state) => state.analyticsall?.listSummary)

  const [reloadPage, setReloadPage] = React.useState(true)

  const companies = useAppSelector((state) => state.company.list)
  const paymentSystem = useAppSelector((state) => state.domain.paymentSystems).map((paymentSystem, idx) => {
    return { value: paymentSystem, label: paymentSystem }
  })

  const gatewaysOption = React.useMemo(() => {
    return gateways
      .map((el) => ({
        label: `${el.paymentSystem}: ${el.name} (${el.companyName})`,
        value: el.id,
        paymentSystem: el.paymentSystem,
        name: el.name,
        id: el.id,
        companyName: el.companyName,
      }))
      .sort((a, b) => (a.label > b.label ? 1 : -1))
  }, [gateways])

  const companiesOption = React.useMemo(() => {
    return companies.map((item) => ({ value: item.id, label: item.name }))
  }, [companies])

  const getAnalytics = React.useCallback(async () => {
    const params = {}

    for (let key in appliedFilters) {
      if (appliedFilters[key]) {
        if (key === 'types') {
          appliedFilters[key].split(',').forEach((val) => (params[`filter[${key}][${val}]`] = val))
        } else {
          params[`filter[${key}]`] = await appliedFilters[key]
        }
      }
    }

    dispatch(getAnalyticsStats(params))
  }, [dispatch, appliedFilters])

  React.useEffect(() => {
    if (reloadPage) {
      getAnalytics()
      setReloadPage(false)
    }
  }, [getAnalytics, reloadPage])

  React.useEffect(() => {
    dispatch(companyGetAll())
    dispatch(domainGetPaymentSystems())
    dispatch(getBankListStatus())
    dispatch(getAllGateways())
    dispatch(productGetAll())
  }, [dispatch])

  return (
    <>
      {(isLoad || isLoading) && <Loader />}
      <Header title="Аналитика общая" />
      <Filters
        analyticsAll={true}
        appliedFilters={{ ...appliedFilters }}
        onFilterChange={(obj) => {
          dispatch(setAnalyticsStatsFilter(obj))
          setReloadPage(true)
        }}
        paymentSystemLabels={paymentSystem}
        currencyLabels={currency}
        companiesOption={companiesOption}
        bankListOption={bankList}
        multiSelectOptions={productsListOption}
        gatewaysOption={gatewaysOption}
        formatOptionLabel={formatOptionLabel}
      />
      <Table fixed={true} titles={analyticsTitle} content={analytics_data} keyProp="analytics_data" />
      <Table titles={analyticsTitle} content={analyticsSummary} keyProp="analytics_data_all" />
    </>
  )
}

export default AnalyticsStatsPage
