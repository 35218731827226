import { routes } from './router';
import { Switch, Route, Redirect, useHistory } from 'react-router-dom';
import { authCheck } from './actions/auth';
import { setClearRedirect } from 'store/slices/redirect';
import React, { useEffect, useState } from 'react';
import AuthPage from './pages/AuthPage';
import { connect } from 'react-redux';
import SideBar from './assets/components/SideBar';
import Modal from './assets/components/Modal';
import LoaderImg from './assets/components/LoaderImg';
import { ToastContainer } from 'react-toastify';
import 'assets/scss/style.scss';
import 'react-toastify/dist/ReactToastify.min.css';

function App({ authCheck, auth, redirectPath, setClearRedirect, permissions }) {
  const [firstRun, setFirstRun] = useState(true);
  const history = useHistory();

  useEffect(() => {
    if (firstRun) {
      setFirstRun(false);
      authCheck();
    }
  }, [firstRun]);

  useEffect(() => {
    if (redirectPath) {
      history.push(redirectPath);
      setClearRedirect();
    }
  }, [history, redirectPath, setClearRedirect]);

  switch (auth) {
    case 'success':
      return (
        <div className='App'>
          <div className='container'>
            <SideBar />
            <div className='container__content'>
              <Switch>
                {permissions && routes(permissions).map((el) => {
                  return (
                    <Route
                      path={el.path}
                      exact={el.exact}
                      key={el.path}
                      render={() => {
                        return <el.component />;
                      }}
                    />
                  );
                })}
              </Switch>
            </div>
            <Modal />
            <ToastContainer
              position='bottom-center'
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
          </div>
        </div>
      );
    case 'fail':
    case 'pending':
      return (
        <div className='App'>
          <Switch>
            <Route path='/auth'>
              <AuthPage />
            </Route>
            <Redirect to='/auth' />
          </Switch>
        </div>
      );
    default:
      return <LoaderImg />;
  }
}
const mapStateToProps = (state) => ({
  auth: state.auth.auth,
  redirectPath: state.redirect.path,
  permissions: state.auth.role?.permissions ?? '',
});

const mapDispatchToProps = {
  authCheck,
  setClearRedirect,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
