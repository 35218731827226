import Input from 'assets/components/Input'
import React, { useEffect, useState, useCallback } from 'react'
import 'react-datepicker/dist/react-datepicker.css'
// import InputСalendar from './components/InputСalendar'
import cn from 'classnames'
import { format } from 'date-fns'
import InputSelect from './components/InputSelect'
import Select from 'react-select'
import DatePicker from 'react-datepicker'
import { useAppSelector } from 'store'
import { landFooterOptions } from 'constants/dashboardOptions/dashboardOptions'

// CSS Modules, react-datepicker-cssmodules.css
// import 'react-datepicker/dist/react-datepicker-cssmodules.css'

const parseAmount = (val1, val2) => {
  const result =
    val1 && val2 && val1 > val2 && val2 !== ''
      ? `${val2}:${val1}`
      : val1 && val2
      ? `${val1}:${val2}`
      : val1 === '' && val2
      ? `0:${val2}`
      : val1 && val2 === ''
      ? `${val1}`
      : ''
  return result
}

const selectStyles = {
  container: (provided) => ({
    ...provided,
    display: 'inline-block',
    width: '250px',
    minHeight: '1px',
    textAlign: 'left',
    border: 'none',
  }),
}

const typesOptions = [
  { label: 'биллы', value: 0 },
  { label: 'ребиллы', value: 2 },
  { label: 'первые ребиллы', value: 1 },
  { label: 'повторные ребиллы', value: 4 },
  { label: 'Renewal', value: 3 },
]

const gatewaysDomainOptions = [
  { label: 'Есть нореф шлюз', value: 'isNoRefExist' },
  { label: 'Есть реф шлюз', value: 'isRefExist' },
  { label: 'Нет нореф шлюза', value: 'isNoRefNotExist' },
  { label: 'Нет реф шлюза', value: 'isRefNotExist' },
]

const stopRebillOption = [
  { label: 'Ребиллы включены', value: 0 },
  { label: 'Все выключены', value: 1 },
  { label: 'Все по Сберу выключены', value: 2 },
  { label: 'Все по ТКС выключены', value: 10 },
  { label: 'Все по Сберу и ТКС выключены', value: 11 },
  { label: 'Выключены все кроме Сбера', value: 3 },
  { label: 'Первые выключены', value: 4 },
  { label: 'Первые по Сберу выключены', value: 5 },
  { label: 'Первые кроме Сбера выключены', value: 6 },
  { label: 'Повторные выключены', value: 7 },
  { label: 'Повторные по Сберу выключены', value: 8 },
  { label: 'Повторные кроме Сбера выключены', value: 9 },
]

const selectStyles2 = {
  control: (provided, state) => ({
    ...provided,
    background: '#fff',
    borderColor: '#9e9e9e',
    minHeight: '30px',
    height: '30px',
    boxShadow: state.isFocused ? null : null,
  }),

  valueContainer: (provided, state) => ({
    ...provided,
    height: '30px',
    padding: '0 6px',
  }),

  input: (provided, state) => ({
    ...provided,
    margin: '0px',
  }),
  indicatorSeparator: (state) => ({
    display: 'none',
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: '30px',
  }),
}

const sberbillOrSberrouteOption = [
  { value: '0', label: 'Выключен' },
  { value: '1', label: 'Включен' },
]

const transactionStatusOption = [
  { value: '0', label: 'Неуспешный' },
  { value: '1', label: 'Успешный' },
]

const stepOption = [
  { value: '1', label: '1' },
  { value: '2', label: '2' },
  { value: '3', label: '3' },
  { value: '4', label: '4' },
  { value: '5', label: '5' },
  { value: '6', label: '6' },
  { value: '7', label: '7' },
  { value: '8', label: '8' },
  { value: '9', label: '9' },
  { value: '10', label: '10' },
]

const dirtyOption = [
  { value: '1', label: 'Грязный' },
  { value: '0', label: 'Чистый' },
]

const Filters = ({
  sberBill,
  sberOut,
  limitPage,
  formatOptionLabel,
  gatewaysOption,
  companiesOption,
  appliedFilters,
  orderLabels,
  paymentLabels,
  onFilterChange,
  statusLabels,
  order,
  refund,
  domainPage,
  gatewayPage,
  productPage,
  analytics,
  paymentSystemLabels,
  currencyLabels,
  multiSelectOptions,
  handleOnChangeMultiSelect,
  valueSelect,
  valueSelectString,
  valueSelectErrors,
  valueSelectStringErrors,
  analyticsMids,
  shakerStats,
  bankListOption,
  folderListOption,
  paymentErrorListOption,
  typeLabels,
  paymentsOptions,
  statisticChargeback,
  webmasterChargeback,
  gatewayStatistics,
  topErrors,
  chainErrors,
  banksPage,
  analyticsAll,
}) => {
  const schemeOption = useAppSelector((state) => state.gateway.rates)
  const [status, setStatus] = useState(appliedFilters?.status ?? null)
  const [type, setType] = useState(appliedFilters?.type ?? null)
  const [typeOrder, setTypeOrder] = useState(appliedFilters?.typeOrder ?? null)
  const [typePayment, setTypePayment] = useState(appliedFilters?.typePayment ?? null)
  const [email, setEmail] = useState(appliedFilters?.email ?? '')
  const [companies, setCompanies] = useState(appliedFilters?.companies ?? '')
  const [fromDate, setFromDate] = useState(appliedFilters?.dateFrom ? new Date(appliedFilters.dateFrom) : null)
  const [toDate, setToDate] = useState(appliedFilters?.dateTo ? new Date(appliedFilters.dateTo) : null)
  const [fromDatePayment, setFromDatePayment] = useState(
    appliedFilters?.datePaymentFrom ? new Date(appliedFilters.datePaymentFrom) : null,
  )
  const [toDatePayment, setToDatePayment] = useState(
    appliedFilters?.datePaymentTo ? new Date(appliedFilters.datePaymentTo) : null,
  )
  const [domain, setDomain] = useState(appliedFilters?.domain ?? '')
  const [link, setLink] = useState(appliedFilters?.link ?? '')
  const [limit, setLimit] = useState(limitPage ?? '50')
  const [id, setId] = useState(appliedFilters?.id ?? '')
  const [orderIds, setOrderIds] = useState(appliedFilters?.orderIds ?? '')
  const [orderNumber, setOrderNumber] = useState(appliedFilters?.number ?? '')
  const [firstSix, setFirstSix] = useState(appliedFilters?.firstSix ?? '')
  const [lastFour, setLastFour] = useState(appliedFilters?.lastFour ?? '')
  const [webmaster, setWebmaster] = useState(appliedFilters?.webmaster ?? '')
  const [paymentSystem, setPaymentSystem] = useState(appliedFilters?.paymentSystem ?? '')
  const [currency, setCurrency] = useState(appliedFilters?.paymentSystem ?? '')
  const [gateway, setGateway] = useState(appliedFilters?.gateway ?? '')
  const [products, setProducts] = useState(appliedFilters?.products ?? '')
  const [name, setName] = useState(appliedFilters?.name ?? '')
  const [reason, setReason] = useState(appliedFilters?.reason ?? '')
  const [externalId, setExternalId] = useState(appliedFilters?.externalId ?? '')
  const [companyId, setCompanyId] = useState(appliedFilters?.companyId ?? '')
  const [companyIds, setCompanyIds] = useState(appliedFilters?.companyIds ?? '')
  const [productId, setProductId] = useState(appliedFilters?.productId ?? '')
  const [productIds, setProductIds] = useState(appliedFilters?.productIds ?? '')
  const [paymentGatewayId, setPaymentGatewayId] = useState(appliedFilters?.paymentGatewayId ?? '')
  const [equirer, setEquirer] = useState(appliedFilters?.equirer ?? '')
  const [landing, setLanding] = useState(appliedFilters?.landing ?? '')
  const [issuer, setIssuer] = useState(appliedFilters?.issuer ?? '')
  const [folder, setFolder] = useState(appliedFilters?.folder ?? '')
  const [sberbill, setSberbill] = useState(appliedFilters?.sberbill ?? '')
  const [sberroute, setSberroute] = useState(appliedFilters?.sberroute ?? '')
  const [transactionStatus, setTransactionStatus] = useState(appliedFilters?.transactionStatus ?? '')
  const [errors, setErrors] = useState(appliedFilters?.errors ?? '')
  const [step, setStep] = useState(appliedFilters?.step ?? '')
  const [stepNone, setStepNone] = useState(appliedFilters?.stepNone ?? '')
  const [dirty, setDirty] = useState(appliedFilters?.dirty ?? '')
  const [issuers, setIssuers] = useState(appliedFilters?.issuers ?? '')
  const [statuses, setStatuses] = useState(appliedFilters?.statuses ?? '')
  const [fromAmount, setFromAmount] = useState(appliedFilters?.amount?.split(':')[0] ?? '')
  const [toAmount, setToAmount] = useState(appliedFilters?.amount?.split(':')[1] ?? '')
  const [recurrentStoppedIds, setRecurrentStoppedIds] = useState(appliedFilters?.recurrentStoppedIds ?? '')
  const [scheme, setScheme] = useState(appliedFilters?.scheme ?? '')
  const [clientId, setClientId] = useState(appliedFilters?.clientId ?? '')
  const [paymentGatewayIds, setPaymentGatewayIds] = useState(appliedFilters?.paymentGatewayIds ?? '')
  const [landFooter, setLandFooter] = useState(appliedFilters?.landFooter ?? null)
  const [gateways, setGateways] = useState(appliedFilters?.gateways ?? '')
  const [types, setTypes] = useState(appliedFilters?.types ?? null)
  const [paymentSystems, setPaymentSystems] = useState(appliedFilters?.paymentSystems ?? '')
  const [webmasterId, setWebmasterId] = useState(appliedFilters?.webmasterId ?? '')
  const [banks, setBanks] = useState(appliedFilters?.banks ?? '')

  const filterFrom = (date) =>
    toDate ? date.getTime() <= Date.now() && date.getTime() <= toDate.getTime() : filterTo(date)
  const filterTo = (date) => date.getTime() <= Date.now()

  const filterFromTransaction = (date) =>
    toDatePayment
      ? date.getTime() <= Date.now() && date.getTime() <= toDatePayment.getTime()
      : filterToTransaction(date)
  const filterToTransaction = (date) => date.getTime() <= Date.now()

  const handleApplyFilters = () => {
    let formattedFromDate = fromDate ? format(fromDate, 'yyyy-MM-dd') : null
    let formattedToDate = toDate ? format(toDate, 'yyyy-MM-dd') : null
    let formattedFromDateTransaction = fromDatePayment ? format(fromDatePayment, 'yyyy-MM-dd') : null
    let formattedToDateTransaction = toDatePayment ? format(toDatePayment, 'yyyy-MM-dd') : null
    if (formattedFromDate > formattedToDate) {
      // switch from <-> to dates
      let temp = fromDate
      setFromDate(toDate)
      setToDate(temp)
      ;[formattedFromDate, formattedToDate] = [formattedToDate, formattedFromDate]
    }

    if (formattedFromDateTransaction > formattedToDateTransaction) {
      // switch from <-> to dates
      let temp = fromDatePayment
      setFromDatePayment(toDatePayment)
      setToDatePayment(temp)
      ;[formattedFromDateTransaction, formattedToDateTransaction] = [
        formattedToDateTransaction,
        formattedFromDateTransaction,
      ]
    }
    if (fromAmount > toAmount && toAmount !== '') {
      let temp = fromAmount
      setFromAmount(toAmount)
      setToAmount(temp)
    }
    onFilterChange({
      status,
      type,
      typeOrder,
      typePayment,
      email,
      dateFrom: formattedFromDate,
      dateTo: formattedToDate,
      datePaymentFrom: formattedFromDateTransaction,
      datePaymentTo: formattedToDateTransaction,
      domain,
      limit,
      id,
      orderIds,
      number: orderNumber,
      firstSix,
      lastFour,
      webmaster,
      paymentSystem,
      currency,
      gateway,
      link,
      products,
      name,
      reason,
      externalId,
      companyId,
      paymentGatewayId,
      productId,
      productIds,
      equirer,
      landing,
      issuer,
      folder,
      sberbill,
      sberroute,
      transactionStatus,
      errors,
      companies,
      step,
      stepNone,
      dirty,
      issuers,
      statuses,
      companyIds,
      amount: parseAmount(fromAmount, toAmount),
      recurrentStoppedIds,
      scheme,
      clientId,
      paymentGatewayIds,
      landFooter,
      gateways,
      types,
      paymentSystems,
      webmasterId,
      banks,
    })
  }

  const handlePressEnter = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      handleApplyFilters()
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', handlePressEnter)
    return () => {
      document.removeEventListener('keydown', handlePressEnter)
    }
  })

  // status selector optimization rendering
  const onChangeState = useCallback((e) => {
    setStatus(e)
  }, [])

  // type selector optimization rendering
  const onChangeType = useCallback((e) => {
    setType(e)
  }, [])
  // type selector optimization rendering
  const onChangeTypeOrder = useCallback((e) => {
    setTypeOrder(e)
  }, [])
  // type selector optimization rendering
  const onChangeTypePayment = useCallback((e) => {
    setTypePayment(e)
  }, [])

  // type selector optimization rendering
  const onChangePaymentGatewayId = useCallback((e) => {
    e?.value ? setPaymentGatewayId(e.value) : setPaymentGatewayId(null)
  }, [])

  // email input optimization rendering
  const onChangeEmail = useCallback((e) => {
    setEmail(e.target.value)
  }, [])

  // fromDate optimization rendering
  const onChangeFromDate = useCallback((e) => {
    setFromDate(e)
  }, [])

  // toDate optimization rendering
  const onChangeToDate = useCallback((e) => {
    setToDate(e)
  }, [])

  // fromDate optimization rendering
  const onChangeFromDateTransaction = useCallback((e) => {
    setFromDatePayment(e)
  }, [])

  // toDate optimization rendering
  const onChangeToDateTransaction = useCallback((e) => {
    setToDatePayment(e)
  }, [])

  // domain optimization rendering
  const onChangeDomain = useCallback((e) => {
    setDomain(e.target.value)
  }, [])

  // link optimization rendering
  const onChangeLink = useCallback((e) => {
    setLink(e.target.value)
  }, [])

  // limit optimization rendering
  const onChangeLimit = useCallback((e) => {
    setLimit(e.target.value)
  }, [])

  // id optimization rendering
  const onChangeId = useCallback((e) => {
    setId(e.target.value)
  }, [])

  // order ids optimization rendering
  const onChangeOrderIds = useCallback((e) => {
    setOrderIds(e.target.value)
  }, [])

  // orderNumber optimization rendering
  const onChangeOrderNumber = useCallback((e) => {
    setOrderNumber(e.target.value)
  }, [])

  const onChangeClientId = useCallback((e) => {
    setClientId(e.target.value)
  }, [])

  // orderNumber optimization rendering
  const onChangeFirstSix = useCallback((e) => {
    setFirstSix(e.target.value)
  }, [])

  // orderNumber optimization rendering
  const onChangeLastFour = useCallback((e) => {
    setLastFour(e.target.value)
  }, [])

  // orderNumber optimization rendering
  const onChangeWebmaster = useCallback((e) => {
    setWebmaster(e.target.value)
  }, [])

  // paymentSystem optimization rendering
  const onChangePaymentSystem = useCallback((e) => {
    setPaymentSystem(e)
  }, [])

  // currency optimization rendering
  const onChangeCurrency = useCallback((e) => {
    setCurrency(e)
  }, [])

  const onChangeGateway = useCallback((e) => {
    setGateway(e.target.value)
  }, [])

  const onChangeName = useCallback((e) => {
    setName(e.target.value)
  }, [])

  const onChangeReason = useCallback((e) => {
    setReason(e.target.value)
  }, [])

  const onChangeExternalId = useCallback((e) => {
    setExternalId(e.target.value)
  }, [])

  const onChangeCompanyId = useCallback((e) => {
    e?.value ? setCompanyId(e.value) : setCompanyId(e)
  }, [])

  const onChangeProductId = useCallback((e) => {
    e?.value ? setProductId(e.value) : setProductId(null)
  }, [])

  const onChangeEquirer = useCallback((e) => {
    e?.value ? setEquirer(e.value) : setEquirer(null)
  }, [])

  const onChangeLanding = useCallback((e) => {
    setLanding(e.target.value)
  }, [])

  const onChangeTypeCharge = useCallback((e) => {
    e?.value ? setType(e.value) : setType(null)
  }, [])

  const onChangeIssuer = useCallback((e) => {
    setIssuer(e.target.value)
  }, [])

  const onChangeFolder = useCallback((e) => {
    e?.value ? setFolder(e.value) : setFolder(e)
  }, [])

  const onChangeSberbill = useCallback((e) => {
    e?.value ? setSberbill(e.value) : setSberbill(e)
  }, [])

  const onChangeLandFooter = useCallback((e) => {
    e?.value || e?.value === '' ? setLandFooter(e.value) : setLandFooter(e)
  }, [])

  const onChangeSberroute = useCallback((e) => {
    e?.value ? setSberroute(e.value) : setSberroute(e)
  }, [])

  const onChangeDirty = useCallback((e) => {
    e?.value ? setDirty(e.value) : setDirty(e)
  }, [])

  const onChangeTransactionStatus = useCallback((e) => {
    e?.value ? setTransactionStatus(e.value) : setTransactionStatus(e)
  }, [])

  const onChangeStep = useCallback((e) => {
    e?.value ? setStep(e.value) : setStep(e)
  }, [])

  const onChangeStepNone = useCallback((e) => {
    e?.value ? setStepNone(e.value) : setStepNone(e)
  }, [])

  const onChangeScheme = useCallback((e) => {
    e?.value ? setScheme(e.value) : setScheme(e)
  }, [])

  const onChangeWebmasterId = useCallback((e) => {
    setWebmasterId(e.target.value)
  }, [])

  useEffect(() => {
    setProducts(valueSelectString)
  }, [valueSelectString])

  useEffect(() => {
    setErrors(valueSelectStringErrors)
  }, [valueSelectStringErrors])

  return order ? (
    <div className="filters">
      <h2 className="filters__title">Фильтры</h2>
      <div className="filters__inner">
        {/* Row 1 */}
        <div className="row">
          <div className="filters__select-2">
            <label className="input__label">Статус</label>
            <Select
              isMulti={true}
              onChange={(o) => setStatuses(o.map((item) => item.value).toString())}
              options={statusLabels}
              styles={selectStyles}
              isSearchable={true}
              placeholder="Статус"
              isClearable
            />
          </div>
          <Input
            placeholder=""
            type="text"
            name="status"
            inputCls="filters__input"
            label="Email"
            value={email}
            onChange={onChangeEmail}
          />
          <Input
            placeholder=""
            type="text"
            name="domain"
            inputCls="filters__input"
            label="Домен"
            value={domain}
            onChange={onChangeDomain}
          />
          <Input
            placeholder=""
            type="number"
            name="count"
            inputCls="filters__input"
            label="Выводить по"
            value={limit}
            onChange={onChangeLimit}
          />
          <button className={cn('button', 'filters__btn')} onClick={handleApplyFilters}>
            Применить
          </button>
        </div>
        {/* Row 2 */}
        <div className="row">
          <div className="filters__select">
            <label className="input__label">Тип билл/реневал/эконом:</label>
            <InputSelect options={orderLabels} value={typeOrder} onChange={onChangeTypeOrder} />
          </div>
          <div className="filters__date">
            <label className="input__label">Дата заказа от</label>
            <DatePicker
              selected={fromDate}
              onChange={onChangeFromDate}
              dateFormat="dd.MM.yyyy"
              isClearable={true}
              className="filter-dataPicker"
              filterDate={filterFrom}
            />
          </div>
          <div className="filters__date">
            <label className="input__label">Дата заказа до</label>
            <DatePicker
              selected={toDate}
              onChange={onChangeToDate}
              dateFormat="dd.MM.yyyy"
              isClearable={true}
              className="filter-dataPicker"
              filterDate={filterTo}
            />
          </div>
          <div className="filters__select">
            <label className="input__label">Тип ребилла:</label>
            <InputSelect options={paymentLabels} value={typePayment} onChange={onChangeTypePayment} />
          </div>
          <div className="filters__date">
            <label className="input__label">Дата платежа от</label>
            <DatePicker
              selected={fromDatePayment}
              onChange={onChangeFromDateTransaction}
              dateFormat="dd.MM.yyyy"
              isClearable={true}
              className="filter-dataPicker"
              filterDate={filterFromTransaction}
            />
          </div>
          <div className="filters__date">
            <label className="input__label">Дата платежа до</label>
            <DatePicker
              selected={toDatePayment}
              onChange={onChangeToDateTransaction}
              dateFormat="dd.MM.yyyy"
              isClearable={true}
              className="filter-dataPicker"
              filterDate={filterToTransaction}
            />
          </div>
        </div>
        <div className="row">
          <Input
            placeholder=""
            type="text"
            name="id"
            inputCls="filters__input"
            label="ID"
            value={id}
            onChange={onChangeId}
          />
          <Input
            placeholder=""
            type="text"
            name="number"
            inputCls="filters__input"
            label="Номер"
            value={orderNumber}
            onChange={onChangeOrderNumber}
          />
          <Input
            placeholder=""
            type="number"
            name="firstSix"
            inputCls="filters__input"
            label="Первые 6 цифр карты"
            value={firstSix}
            onChange={onChangeFirstSix}
          />
          <Input
            placeholder=""
            type="number"
            name="lastFour"
            inputCls="filters__input"
            label="Последние 4 цифр карты"
            value={lastFour}
            onChange={onChangeLastFour}
          />
          <Input
            placeholder=""
            type="text"
            name="webmaster"
            inputCls="filters__input"
            label="ID Вебмастера"
            value={webmaster}
            onChange={onChangeWebmaster}
          />
          <Input
            placeholder=""
            type="text"
            name="externalId"
            inputCls="filters__input"
            label="Внешний ID платежа"
            value={externalId}
            onChange={onChangeExternalId}
          />
          <div className="filters__select">
            <label className="input__label">Шлюзы</label>
            <Select
              onChange={(o) => setPaymentGatewayIds(o.map((item) => item.value).toString())}
              formatOptionLabel={formatOptionLabel}
              options={gatewaysOption}
              className="filters-select"
              classNamePrefix="filters-select"
              placeholder="Шлюзы"
              isSearchable={true}
              isMulti={true}
              styles={selectStyles}
              isClearable
            />
          </div>
        </div>
        {/* Row 3 */}
        <div className="row">
          <div className="filters__select-2">
            <label className="input__label">Продукты</label>
            <Select
              onChange={(o) => setProductIds(o.map((item) => item.value).toString())}
              options={multiSelectOptions}
              styles={selectStyles}
              isSearchable={true}
              isMulti={true}
              placeholder="Продукты..."
              isClearable
            />
          </div>
          <div className="filters__select-2">
            <label className="input__label">Ошибки</label>
            <Select
              isMulti={true}
              value={valueSelectErrors}
              onChange={handleOnChangeMultiSelect}
              options={paymentErrorListOption}
              styles={selectStyles}
              placeholder="Ошибки"
            />
          </div>
          <div className="filters__select-2">
            <label className="input__label">Банки</label>
            <Select
              isMulti={true}
              onChange={(o) => setIssuers(o.map((item) => item.value).toString())}
              options={bankListOption}
              styles={selectStyles}
              isSearchable={true}
              placeholder="Банки..."
              isClearable
            />
          </div>
          <div className="filters__select-2">
            <label className="input__label">ЮрЛицо</label>
            <Select
              isMulti={true}
              onChange={(o) => setCompanyIds(o.map((item) => item.value).toString())}
              options={companiesOption}
              styles={selectStyles}
              isSearchable={true}
              placeholder="ЮрЛицо"
              isClearable
            />
          </div>
          <Input
            placeholder=""
            type="text"
            name="issuer"
            inputCls="filters__input"
            label="Банк"
            value={issuer}
            onChange={onChangeIssuer}
          />
          <div className="filters__select">
            <label className="input__label">Cтатус Транзакции</label>
            <Select
              value={transactionStatusOption?.find((o) => o.value === transactionStatus)}
              onChange={onChangeTransactionStatus}
              options={transactionStatusOption}
              styles={selectStyles2}
              isSearchable={true}
              placeholder="Cтатус"
              isClearable
            />
          </div>
        </div>
        <div className="row">
          <div className="filters__select">
            <label className="input__label">Платежная система</label>
            <InputSelect options={paymentSystemLabels} value={paymentSystem} onChange={onChangePaymentSystem} />
          </div>
          <Input
            placeholder=""
            type="number"
            name="fromAmount"
            inputCls="filters__input"
            label="Сумма от"
            value={fromAmount}
            onChange={(e) => setFromAmount(parseInt(e.target.value))}
          />
          <Input
            placeholder=""
            type="number"
            name="toAmount"
            inputCls="filters__input"
            label="Cумма до"
            value={toAmount}
            onChange={(e) => setToAmount(parseInt(e.target.value))}
          />
        </div>
      </div>
    </div>
  ) : analyticsAll ? (
    <div className="filters">
      <h2 className="filters__title">Фильтры</h2>
      <div className="filters__inner">
        <div className="row">
          <div className="filters__date">
            <label className="input__label">Дата от</label>
            <DatePicker
              selected={fromDate}
              onChange={onChangeFromDate}
              dateFormat="dd.MM.yyyy"
              isClearable={true}
              className="filter-dataPicker"
              filterDate={filterFrom}
            />
          </div>
          <div className="filters__date">
            <label className="input__label">Дата до</label>
            <DatePicker
              selected={toDate}
              onChange={onChangeToDate}
              dateFormat="dd.MM.yyyy"
              isClearable={true}
              className="filter-dataPicker"
              filterDate={filterTo}
            />
          </div>
          <div className="filters__select">
            <label className="input__label">Эквайринг</label>
            <InputSelect options={paymentSystemLabels} value={paymentSystem} onChange={onChangePaymentSystem} />
          </div>
          <div className="filters__select">
            <label className="input__label">Валюта</label>
            <InputSelect options={currencyLabels} value={currency} onChange={onChangeCurrency} needLabel />
          </div>
          <div className="filters__select">
            <label className="input__label">ЮрЛицо</label>
            <InputSelect options={companiesOption} value={companyId} onChange={onChangeCompanyId} />
          </div>
          <div className="filters__select">
            <label className="input__label">Билл/Ребилл</label>
            <Select
              onChange={(o) => setTypes(o.map((item) => item.value).toString())}
              className="filters-select"
              classNamePrefix="filters-select"
              options={typesOptions}
              isSearchable={true}
              isMulti={true}
              placeholder="Билл/Ребилл"
              isClearable
            />
          </div>
        </div>
        <div className="row">
          <div className="filters__select-2">
            <label className="input__label">Продукты</label>
            <Select
              onChange={(o) => setProducts(o.map((item) => item.value).toString())}
              options={multiSelectOptions}
              styles={selectStyles}
              isSearchable={true}
              isMulti={true}
              placeholder="Продукты..."
              isClearable
            />
          </div>
          <div className="filters__select-2">
            <label className="input__label">Шлюзы</label>
            <Select
              onChange={(o) => setGateways(o.map((item) => item.value).toString())}
              formatOptionLabel={formatOptionLabel}
              options={gatewaysOption}
              placeholder="Шлюзы"
              isSearchable={true}
              isMulti={true}
              styles={selectStyles}
              isClearable
            />
          </div>
          <div className="filters__select-2">
            <label className="input__label">Банки</label>
            <Select
              isMulti={true}
              onChange={(o) => setBanks(o.map((item) => item.value).toString())}
              options={bankListOption}
              styles={selectStyles}
              isSearchable={true}
              placeholder="Банки..."
              isClearable
            />
          </div>
        </div>
        <div className="row">
          <Input
            placeholder=""
            type="text"
            name="domain"
            inputCls="filters__input"
            label="Домен"
            value={domain}
            onChange={onChangeDomain}
          />
          <Input
            placeholder=""
            type="text"
            name="webmasterId"
            inputCls="filters__input"
            label="WebmasterId"
            value={webmasterId}
            onChange={onChangeWebmasterId}
          />
          <button className={cn('button', 'filters__btn')} onClick={handleApplyFilters}>
            Применить
          </button>
        </div>
      </div>
    </div>
  ) : banksPage ? (
    <div className="filters">
      <h2 className="filters__title">Фильтры</h2>
      <div className="filters__inner">
        {/* Row 1 */}
        <div className="row">
          <Input
            placeholder=""
            type="number"
            name="count"
            inputCls="filters__input"
            label="Выводить по"
            value={limit}
            onChange={onChangeLimit}
          />
          <button className={cn('button', 'filters__btn')} onClick={handleApplyFilters}>
            Применить
          </button>
        </div>
      </div>
    </div>
  ) : chainErrors ? (
    <div className="filters">
      <h2 className="filters__title">Фильтры</h2>
      <div className="filters__inner">
        {/* Row 1 */}
        <div className="row">
          <Input
            placeholder=""
            type="text"
            name="webmaster"
            inputCls="filters__input"
            label="ID Вебмастера"
            value={webmaster}
            onChange={onChangeWebmaster}
          />
          <Input
            placeholder=""
            type="text"
            name="id"
            inputCls="filters__input"
            label="ID заказа"
            value={orderIds}
            onChange={onChangeOrderIds}
          />
          <div className="filters__date">
            <label className="input__label">Дата от</label>
            <DatePicker
              selected={fromDate}
              onChange={onChangeFromDate}
              dateFormat="dd.MM.yyyy"
              isClearable={true}
              className="filter-dataPicker"
              filterDate={filterFrom}
            />
          </div>
          <div className="filters__date">
            <label className="input__label">Дата до</label>
            <DatePicker
              selected={toDate}
              onChange={onChangeToDate}
              dateFormat="dd.MM.yyyy"
              isClearable={true}
              className="filter-dataPicker"
              filterDate={filterTo}
            />
          </div>
          <Input
            placeholder=""
            type="number"
            name="count"
            inputCls="filters__input"
            label="Выводить по"
            value={limit}
            onChange={onChangeLimit}
          />
          <Input
            placeholder=""
            type="text"
            name="issuer"
            inputCls="filters__input"
            label="Банк"
            value={issuer}
            onChange={onChangeIssuer}
          />
          <div className="filters__select">
            <label className="input__label">Тип: Билл/Ребилл</label>
            <InputSelect options={orderLabels} value={type} onChange={onChangeType} />
          </div>
          <div className="filters__select">
            <label className="input__label">Эквайринг</label>
            <InputSelect options={paymentSystemLabels} value={paymentSystem} onChange={onChangePaymentSystem} />
          </div>
          <button className={cn('button', 'filters__btn')} onClick={handleApplyFilters}>
            Применить
          </button>
        </div>
        <div className="row">
          <div className="filters__select-2">
            <label className="input__label">Юр Лицо</label>
            <Select
              isMulti={true}
              onChange={(o) => setCompanies(o.map((item) => item.value).toString())}
              options={companiesOption}
              styles={selectStyles}
              isSearchable={true}
              placeholder="Юр Лицо"
              isClearable
            />
          </div>
          <div className="filters__select-2">
            <label className="input__label">Есть шаг</label>
            <Select
              value={stepOption?.find((o) => o.value === step)}
              onChange={onChangeStep}
              options={stepOption}
              styles={selectStyles}
              isSearchable={true}
              placeholder="шаг"
              isClearable
            />
          </div>
          <div className="filters__select-2">
            <label className="input__label">Нет шага</label>
            <Select
              value={stepOption?.find((o) => o.value === stepNone)}
              onChange={onChangeStepNone}
              options={stepOption}
              styles={selectStyles}
              isSearchable={true}
              placeholder="шаг"
              isClearable
            />
          </div>
          <div className="filters__select-2">
            <label className="input__label">Шлюзы</label>
            <Select
              onChange={(o) => setPaymentGatewayIds(o.map((item) => item.value).toString())}
              formatOptionLabel={formatOptionLabel}
              options={gatewaysOption}
              placeholder="Шлюзы"
              isSearchable={true}
              isMulti={true}
              styles={selectStyles}
              isClearable
            />
          </div>
          <div className="filters__select-2">
            <label className="input__label">Продукты</label>
            <Select
              isMulti={true}
              onChange={(o) => setProducts(o.map((item) => item.value).toString())}
              options={multiSelectOptions}
              styles={selectStyles}
              isSearchable={true}
              placeholder="Продукты..."
              isClearable
            />
          </div>
        </div>
        <div className="row">
          <div className="filters__select-2">
            <label className="input__label">Банки</label>
            <Select
              isMulti={true}
              onChange={(o) => setIssuers(o.map((item) => item.value).toString())}
              options={bankListOption}
              styles={selectStyles}
              isSearchable={true}
              placeholder="Банки..."
              isClearable
            />
          </div>
          <div className="filters__select">
            <label className="input__label">Статус Транзакции</label>
            <Select
              value={transactionStatusOption?.find((o) => o.value === status)}
              onChange={(option) => (option?.value ? onChangeState(option.value) : onChangeState(option))}
              options={statusLabels}
              styles={selectStyles}
              isSearchable={true}
              placeholder="Статус"
              isClearable
            />
          </div>
        </div>
      </div>
    </div>
  ) : topErrors ? (
    <div className="filters">
      <h2 className="filters__title">Фильтры</h2>
      <div className="filters__inner">
        {/* Row 1 */}
        <div className="row">
          <div className="filters__date">
            <label className="input__label">Дата от</label>
            <DatePicker
              selected={fromDate}
              onChange={onChangeFromDate}
              dateFormat="dd.MM.yyyy"
              isClearable={true}
              className="filter-dataPicker"
              filterDate={filterFrom}
            />
          </div>
          <div className="filters__date">
            <label className="input__label">Дата до</label>
            <DatePicker
              selected={toDate}
              onChange={onChangeToDate}
              dateFormat="dd.MM.yyyy"
              isClearable={true}
              className="filter-dataPicker"
              filterDate={filterTo}
            />
          </div>
          <Input
            placeholder=""
            type="text"
            name="webmaster"
            inputCls="filters__input"
            label="ID Вебмастера"
            value={webmaster}
            onChange={onChangeWebmaster}
          />
          <Input
            placeholder=""
            type="number"
            name="count"
            inputCls="filters__input"
            label="Выводить по"
            value={limit}
            onChange={onChangeLimit}
          />
          <Input
            placeholder=""
            type="text"
            name="issuer"
            inputCls="filters__input"
            label="Банк"
            value={issuer}
            onChange={onChangeIssuer}
          />
          <Input
            placeholder=""
            type="text"
            name="domain"
            inputCls="filters__input"
            label="Домен"
            value={domain}
            onChange={onChangeDomain}
          />
          <button className={cn('button', 'filters__btn')} onClick={handleApplyFilters}>
            Применить
          </button>
        </div>
        <div className="row">
          <div className="filters__select-2">
            <label className="input__label">Продукты</label>
            <Select
              isMulti={true}
              onChange={(o) => setProducts(o.map((item) => item.value).toString())}
              options={multiSelectOptions}
              styles={selectStyles}
              isSearchable={true}
              placeholder="Продукты..."
              isClearable
            />
          </div>
          <div className="filters__select-2">
            <label className="input__label">Юр Лицо</label>
            <Select
              isMulti={true}
              onChange={(o) => setCompanies(o.map((item) => item.value).toString())}
              options={companiesOption}
              styles={selectStyles}
              isSearchable={true}
              placeholder="Юр Лицо"
              isClearable
            />
          </div>
          <div className="filters__select-2">
            <label className="input__label">Ошибки</label>
            <Select
              isMulti={true}
              isSearchable={true}
              onChange={(o) => setErrors(o.map((item) => item.value).toString())}
              options={paymentErrorListOption}
              styles={selectStyles}
              placeholder="Ошибки"
              isClearable
            />
          </div>
          <div className="filters__select">
            <label className="input__label">Тип: Билл/Ребилл</label>
            <InputSelect options={orderLabels} value={type} onChange={onChangeType} />
          </div>
          <div className="filters__select">
            <label className="input__label">Шлюзы</label>
            <Select
              onChange={onChangePaymentGatewayId}
              formatOptionLabel={formatOptionLabel}
              options={gatewaysOption}
              value={gatewaysOption?.find((o) => o.value === paymentGatewayId)}
              className="filters-select"
              classNamePrefix="filters-select"
              placeholder="Шлюзы"
              isSearchable={true}
              styles={selectStyles}
              isClearable
            />
          </div>
        </div>
      </div>
    </div>
  ) : statisticChargeback ? (
    <div className="filters">
      <h2 className="filters__title">Фильтры</h2>
      <div className="filters__inner">
        {/* Row 1 */}
        <div className="row">
          <div className="filters__date">
            <label className="input__label">Дата от</label>
            <DatePicker
              selected={fromDate}
              onChange={onChangeFromDate}
              dateFormat="dd.MM.yyyy"
              isClearable={true}
              className="filter-dataPicker"
              filterDate={filterFrom}
            />
          </div>
          <div className="filters__date">
            <label className="input__label">Дата до</label>
            <DatePicker
              selected={toDate}
              onChange={onChangeToDate}
              dateFormat="dd.MM.yyyy"
              isClearable={true}
              className="filter-dataPicker"
              filterDate={filterTo}
            />
          </div>
          <Input
            placeholder=""
            type="text"
            name="webmaster"
            inputCls="filters__input"
            label="ID Вебмастера"
            value={webmaster}
            onChange={onChangeWebmaster}
          />
          <button className={cn('button', 'filters__btn')} onClick={handleApplyFilters}>
            Применить
          </button>
        </div>
        <div className="row">
          <div className="filters__select-2">
            <label className="input__label">Продукты</label>
            <Select
              value={multiSelectOptions?.find((o) => o.value === productId)}
              onChange={onChangeProductId}
              formatOptionLabel={formatOptionLabel}
              options={multiSelectOptions}
              styles={selectStyles}
              isSearchable={true}
              placeholder="Продукты..."
              isClearable
            />
          </div>
          <div className="filters__select-2">
            <label className="input__label">Эквайринг</label>
            <Select
              value={paymentsOptions?.find((o) => o.value === equirer)}
              onChange={onChangeEquirer}
              options={paymentsOptions}
              styles={selectStyles}
              isSearchable={true}
              placeholder="Эквайринг..."
              isClearable
            />
          </div>
        </div>
      </div>
    </div>
  ) : webmasterChargeback ? (
    <div className="filters">
      <h2 className="filters__title">Фильтры</h2>
      <div className="filters__inner">
        {/* Row 1 */}
        <div className="row">
          <div className="filters__date">
            <label className="input__label">Дата от</label>
            <DatePicker
              selected={fromDate}
              onChange={onChangeFromDate}
              dateFormat="dd.MM.yyyy"
              isClearable={true}
              className="filter-dataPicker"
              filterDate={filterFrom}
            />
          </div>
          <div className="filters__date">
            <label className="input__label">Дата до</label>
            <DatePicker
              selected={toDate}
              onChange={onChangeToDate}
              dateFormat="dd.MM.yyyy"
              isClearable={true}
              className="filter-dataPicker"
              filterDate={filterTo}
            />
          </div>
          <Input
            placeholder=""
            type="text"
            name="webmaster"
            inputCls="filters__input"
            label="ID Вебмастера"
            value={webmaster}
            onChange={onChangeWebmaster}
          />
          <Input
            placeholder=""
            type="text"
            name="status"
            inputCls="filters__input"
            label="Email"
            value={email}
            onChange={onChangeEmail}
          />
          <Input
            placeholder=""
            type="text"
            name="status"
            inputCls="filters__input"
            label="Лендинг"
            value={landing}
            onChange={onChangeLanding}
          />
          <Input
            placeholder=""
            type="number"
            name="count"
            inputCls="filters__input"
            label="Выводить по"
            value={limit}
            onChange={onChangeLimit}
          />
          <button className={cn('button', 'filters__btn')} onClick={handleApplyFilters}>
            Применить
          </button>
        </div>
        <div className="row">
          <div className="filters__select-2">
            <label className="input__label">Продукты</label>
            <Select
              value={multiSelectOptions?.find((o) => o.value === productId)}
              onChange={onChangeProductId}
              formatOptionLabel={formatOptionLabel}
              options={multiSelectOptions}
              styles={selectStyles}
              isSearchable={true}
              placeholder="Продукты..."
              isClearable
            />
          </div>
          <div className="filters__select-2">
            <label className="input__label">Эквайринг</label>
            <Select
              value={paymentsOptions?.find((o) => o.value === equirer)}
              onChange={onChangeEquirer}
              options={paymentsOptions}
              styles={selectStyles}
              isSearchable={true}
              placeholder="Эквайринг..."
              isClearable
            />
          </div>
          <div className="filters__select-2">
            <label className="input__label">Тип: Обычный/Арбитражный</label>
            <Select
              value={typeLabels?.find((o) => o.value === type)}
              onChange={onChangeTypeCharge}
              options={typeLabels}
              styles={selectStyles}
              isSearchable={true}
              placeholder="Тип"
              isClearable
            />
          </div>
        </div>
      </div>
    </div>
  ) : gatewayStatistics ? (
    <div className="filters">
      <h2 className="filters__title">Фильтры</h2>
      <div className="filters__inner">
        {/* Row 1 */}
        <div className="row">
          <div className="filters__select-2">
            <label className="input__label">ЮрЛица</label>
            <Select
              onChange={onChangeCompanyId}
              options={companiesOption}
              value={companiesOption?.find((o) => o.value === companyId)}
              className="filters-select"
              classNamePrefix="filters-select"
              placeholder="ЮрЛица"
              isSearchable={true}
              styles={selectStyles}
              isClearable
            />
          </div>
          <div className="filters__date">
            <label className="input__label">Дата от</label>
            <DatePicker
              selected={fromDate}
              onChange={onChangeFromDate}
              dateFormat="dd.MM.yyyy"
              isClearable={true}
              className="filter-dataPicker"
              filterDate={filterFrom}
            />
          </div>
          <div className="filters__date">
            <label className="input__label">Дата до</label>
            <DatePicker
              selected={toDate}
              onChange={onChangeToDate}
              dateFormat="dd.MM.yyyy"
              isClearable={true}
              className="filter-dataPicker"
              filterDate={filterTo}
            />
          </div>
          <button className={cn('button', 'filters__btn')} onClick={handleApplyFilters}>
            Применить
          </button>
        </div>
      </div>
    </div>
  ) : refund ? (
    <div className="filters">
      <h2 className="filters__title">Фильтры</h2>
      <div className="filters__inner">
        {/* Row 1 */}
        <div className="row">
          <div className="filters__select">
            <label className="input__label">Статус</label>
            <InputSelect options={statusLabels} value={status} onChange={onChangeState} />
          </div>
          <div className="filters__select">
            <label className="input__label">Тип: Билл/Ребилл</label>
            <InputSelect options={orderLabels} value={type} onChange={onChangeType} />
          </div>
          <Input
            placeholder=""
            type="text"
            name="status"
            inputCls="filters__input"
            label="Email"
            value={email}
            onChange={onChangeEmail}
          />
          <div className="filters__date">
            <label className="input__label">Дата от</label>
            <DatePicker
              selected={fromDate}
              onChange={onChangeFromDate}
              dateFormat="dd.MM.yyyy"
              isClearable={true}
              className="filter-dataPicker"
              filterDate={filterFrom}
            />
          </div>
          <div className="filters__date">
            <label className="input__label">Дата до</label>
            <DatePicker
              selected={toDate}
              onChange={onChangeToDate}
              dateFormat="dd.MM.yyyy"
              isClearable={true}
              className="filter-dataPicker"
              filterDate={filterTo}
            />
          </div>
          <Input
            placeholder=""
            type="number"
            name="count"
            inputCls="filters__input"
            label="Выводить по"
            value={limit}
            onChange={onChangeLimit}
          />
          <Input
            placeholder=""
            type="text"
            name="name"
            inputCls="filters__input"
            label="ФИО"
            value={name}
            onChange={onChangeName}
          />
          <Input
            placeholder=""
            type="text"
            name="reason"
            inputCls="filters__input"
            label="Причина возврата"
            value={reason}
            onChange={onChangeReason}
          />
          <button className={cn('button', 'filters__btn')} onClick={handleApplyFilters}>
            Применить
          </button>
        </div>
        {/* Row 2 */}
        <div className="row">
          <Input
            placeholder=""
            type="text"
            name="number"
            inputCls="filters__input"
            label="Номер"
            value={clientId}
            onChange={onChangeClientId}
          />
          <div className="filters__select">
            <label className="input__label">Шлюзы</label>
            <Select
              onChange={onChangePaymentGatewayId}
              options={gatewaysOption}
              formatOptionLabel={formatOptionLabel}
              value={gatewaysOption?.find((o) => o.value === paymentGatewayId)}
              className="filters-select"
              classNamePrefix="filters-select"
              placeholder="Шлюзы"
              isSearchable={true}
              styles={selectStyles}
              isClearable
            />
          </div>
        </div>
      </div>
    </div>
  ) : gatewayPage ? (
    <div className="filters">
      <h2 className="filters__title">Фильтры</h2>
      <div className="filters__inner">
        {/* Row 1 */}
        <div className="row">
          <div className="filters__select">
            <label className="input__label">Статус</label>
            <InputSelect options={statusLabels} value={status} onChange={onChangeState} />
          </div>
          <div className="filters__select-2">
            <label className="input__label">ЮрЛицо</label>
            <Select
              isMulti={true}
              onChange={(o) => setCompanyIds(o.map((item) => item.value).toString())}
              options={companiesOption}
              styles={selectStyles}
              className="filters-select"
              classNamePrefix="filters-select"
              isSearchable={true}
              placeholder="ЮрЛицо"
              isClearable
            />
          </div>
          <Input
            placeholder=""
            type="number"
            name="count"
            inputCls="filters__input"
            label="Выводить по"
            value={limit}
            onChange={onChangeLimit}
          />
          <div className="filters__select">
            <label className="input__label">SberBill</label>
            <Select
              value={sberbillOrSberrouteOption?.find((o) => o.value === sberbill)}
              onChange={onChangeSberbill}
              options={sberbillOrSberrouteOption}
              styles={selectStyles2}
              isSearchable={true}
              placeholder="SberBill"
              isClearable
            />
          </div>
          <div className="filters__select">
            <label className="input__label">SberRoute</label>
            <Select
              value={sberbillOrSberrouteOption?.find((o) => o.value === sberroute)}
              onChange={onChangeSberroute}
              options={sberbillOrSberrouteOption}
              styles={selectStyles2}
              isSearchable={true}
              placeholder="SberRoute"
              isClearable
            />
          </div>
          <div className="filters__select">
            <label className="input__label">Чистый/Грязный шлюз</label>
            <Select
              value={dirtyOption?.find((o) => o.value === dirty)}
              onChange={onChangeDirty}
              options={dirtyOption}
              styles={selectStyles2}
              placeholder="Шлюз"
              isClearable
            />
          </div>
          <button className={cn('button', 'filters__btn')} onClick={handleApplyFilters}>
            Применить
          </button>
        </div>
        <div className="row">
          <div className="filters__select">
            <label className="input__label">Тарифы</label>
            <Select
              value={schemeOption?.find((o) => o.value === scheme)}
              onChange={onChangeScheme}
              options={schemeOption}
              styles={selectStyles2}
              isSearchable={true}
              placeholder="Тарифы"
              isClearable
            />
          </div>
          <div className="filters__select-2">
            <label className="input__label">Остановка ребиллов по шлюзу</label>
            <Select
              isMulti={true}
              onChange={(o) => setRecurrentStoppedIds(o.map((item) => item.value).toString())}
              options={stopRebillOption}
              styles={selectStyles}
              className="filters-select"
              classNamePrefix="filters-select"
              isSearchable={true}
              placeholder="Остановка ребиллов"
              isClearable
            />
          </div>
          <div className="filters__select-2">
            <label className="input__label">Платежная система</label>
            <Select
              isMulti={true}
              onChange={(o) => setPaymentSystems(o.map((item) => item.value).toString())}
              options={paymentSystemLabels}
              styles={selectStyles}
              className="filters-select"
              classNamePrefix="filters-select"
              isSearchable={true}
              placeholder="Платежная система"
              isClearable
            />
          </div>
        </div>
      </div>
    </div>
  ) : domainPage ? (
    <div className="filters">
      <h2 className="filters__title">Фильтры</h2>
      <div className="filters__inner">
        {/* Row 1 */}
        <div className="row">
          <div className="filters__select">
            <label className="input__label">Статус</label>
            <InputSelect
              options={statusLabels}
              value={status}
              onChange={onChangeState}
              classStyle="filters-select-domain"
            />
          </div>
          <div className="filters__select-2">
            <label className="input__label">Продукты</label>
            <Select
              isMulti={true}
              value={valueSelect}
              onChange={handleOnChangeMultiSelect}
              options={multiSelectOptions}
              getOptionLabel={(option) => option.innerName}
              getOptionValue={(option) => option.id}
              styles={selectStyles}
              placeholder="Продукты..."
            />
          </div>
          <div className="filters__select-2">
            <label className="input__label">Шлюзы</label>
            <Select
              onChange={onChangePaymentGatewayId}
              formatOptionLabel={formatOptionLabel}
              options={gatewaysOption}
              value={gatewaysOption?.find((o) => o.value === paymentGatewayId)}
              placeholder="Шлюзы"
              isSearchable={true}
              styles={selectStyles}
              isClearable
            />
          </div>
          <div className="filters__select-2">
            <label className="input__label">ЮрЛицо</label>
            <Select
              isMulti={true}
              onChange={(o) => setCompanyIds(o.map((item) => item.value).toString())}
              options={companiesOption}
              styles={selectStyles}
              isSearchable={true}
              placeholder="ЮрЛицо"
              isClearable
            />
          </div>
          <div className="filters__select">
            <label className="input__label">landFooter</label>
            <Select
              value={landFooterOptions?.find((o) => o.value === landFooter)}
              onChange={onChangeLandFooter}
              options={landFooterOptions}
              styles={selectStyles}
              isSearchable={true}
              placeholder="landFooter"
              isClearable
            />
          </div>
        </div>
        <div className="row">
          <Input
            placeholder=""
            type="text"
            name="link"
            inputCls="filters__input"
            label="Домен"
            value={link}
            onChange={onChangeLink}
          />
          <Input
            placeholder=""
            type="number"
            name="count"
            inputCls="filters__input"
            label="Выводить по"
            value={limit}
            onChange={onChangeLimit}
          />
          <div className="filters__select">
            <label className="input__label">Репозиторий</label>
            <Select
              value={folderListOption?.find((o) => o.value === folder)}
              onChange={onChangeFolder}
              options={folderListOption}
              styles={selectStyles2}
              isSearchable={true}
              placeholder="Репозиторий"
              isClearable
            />
          </div>
          <div className="filters__select">
            <label className="input__label">SberBill</label>
            <Select
              value={sberbillOrSberrouteOption?.find((o) => o.value === sberbill)}
              onChange={onChangeSberbill}
              options={sberbillOrSberrouteOption}
              styles={selectStyles2}
              isSearchable={true}
              placeholder="SberBill"
              isClearable
            />
          </div>
          <div className="filters__select-2">
            <label className="input__label">Шлюзы (ref/noRef)</label>
            <Select
              isMulti={true}
              onChange={(o) => setGateways(o.map((item) => item.value).toString())}
              options={gatewaysDomainOptions}
              styles={selectStyles}
              className="filters-select"
              classNamePrefix="filters-select"
              isSearchable={true}
              placeholder="шлюзы (ref/noRef)"
              isClearable
            />
          </div>
          <button className={cn('button', 'filters__btn')} onClick={handleApplyFilters}>
            Применить
          </button>
        </div>
      </div>
    </div>
  ) : analytics ? (
    <div className="filters">
      <h2 className="filters__title">Фильтры</h2>
      <div className="filters__inner">
        <div className="row">
          <div className="filters__date">
            <label className="input__label">Дата от</label>
            <DatePicker
              selected={fromDate}
              onChange={onChangeFromDate}
              dateFormat="dd.MM.yyyy"
              isClearable={true}
              className="filter-dataPicker"
              filterDate={filterFrom}
            />
          </div>
          <div className="filters__date">
            <label className="input__label">Дата до</label>
            <DatePicker
              selected={toDate}
              onChange={onChangeToDate}
              dateFormat="dd.MM.yyyy"
              isClearable={true}
              className="filter-dataPicker"
              filterDate={filterTo}
            />
          </div>
          <div className="filters__select">
            <label className="input__label">Эквайринг</label>
            <InputSelect options={paymentSystemLabels} value={paymentSystem} onChange={onChangePaymentSystem} />
          </div>
          <div className="filters__select">
            <label className="input__label">Валюта</label>
            <InputSelect options={currencyLabels} value={currency} onChange={onChangeCurrency} needLabel />
          </div>
          <div className="filters__select">
            <label className="input__label">ЮрЛицо</label>
            <InputSelect options={companiesOption} value={companyId} onChange={onChangeCompanyId} />
          </div>
          <div className="filters__select">
            <label className="input__label">Билл/Ребилл</label>
            <Select
              onChange={(o) => setTypes(o.map((item) => item.value).toString())}
              className="filters-select"
              classNamePrefix="filters-select"
              options={typesOptions}
              isSearchable={true}
              isMulti={true}
              placeholder="Билл/Ребилл"
              isClearable
            />
          </div>
          <button className={cn('button', 'filters__btn')} onClick={handleApplyFilters}>
            Применить
          </button>
        </div>
      </div>
    </div>
  ) : analyticsMids ? (
    <div className="filters">
      <h2 className="filters__title">Фильтры</h2>
      <div className="filters__inner">
        <div className="row">
          <div className="filters__date">
            <label className="input__label">Дата от</label>
            <DatePicker
              selected={fromDate}
              onChange={onChangeFromDate}
              dateFormat="dd.MM.yyyy"
              isClearable={true}
              className="filter-dataPicker"
              filterDate={filterFrom}
            />
          </div>
          <div className="filters__date">
            <label className="input__label">Дата до</label>
            <DatePicker
              selected={toDate}
              onChange={onChangeToDate}
              dateFormat="dd.MM.yyyy"
              isClearable={true}
              className="filter-dataPicker"
              filterDate={filterTo}
            />
          </div>
          <div className="filters__select">
            <label className="input__label">Валюта</label>
            <InputSelect options={currencyLabels} value={currency} onChange={onChangeCurrency} needLabel />
          </div>
          <div className="filters__select">
            <label className="input__label">Платежная система</label>
            <InputSelect options={paymentSystemLabels} value={paymentSystem} onChange={onChangePaymentSystem} />
          </div>
          <div className="filters__select">
            <label className="input__label">ЮрЛицо</label>
            <InputSelect options={companiesOption} value={companyId} onChange={onChangeCompanyId} />
          </div>
          <Input
            placeholder=""
            type="text"
            name="gateway"
            inputCls="filters__input"
            label="Gateway"
            value={gateway}
            onChange={onChangeGateway}
          />
          <button className={cn('button', 'filters__btn')} onClick={handleApplyFilters}>
            Применить
          </button>
        </div>
      </div>
    </div>
  ) : shakerStats ? (
    <div className="filters">
      <h2 className="filters__title">Фильтры</h2>
      <div className="filters__inner">
        <div className="row">
          <div className="filters__date">
            <label className="input__label">Дата от</label>
            <DatePicker
              selected={fromDate}
              onChange={onChangeFromDate}
              dateFormat="dd.MM.yyyy"
              isClearable={true}
              className="filter-dataPicker"
              filterDate={filterFrom}
            />
          </div>
          <div className="filters__date">
            <label className="input__label">Дата до</label>
            <DatePicker
              selected={toDate}
              onChange={onChangeToDate}
              dateFormat="dd.MM.yyyy"
              isClearable={true}
              className="filter-dataPicker"
              filterDate={filterTo}
            />
          </div>
          <button className={cn('button', 'filters__btn')} onClick={handleApplyFilters}>
            Применить
          </button>
        </div>
      </div>
    </div>
  ) : sberOut ? (
    <div className="filters">
      <h2 className="filters__title">Фильтры</h2>
      <div className="filters__inner">
        {/* Row 1 */}
        <div className="row">
          <div className="filters__select-2">
            <label className="input__label">ЮрЛица</label>
            <Select
              onChange={onChangeCompanyId}
              options={companiesOption}
              value={companiesOption?.find((o) => o.value === companyId)}
              styles={selectStyles}
              placeholder="ЮрЛица"
              isSearchable={true}
              styles={selectStyles}
              isClearable
            />
          </div>
          <div className="filters__select-2">
            <label className="input__label">Тип: Обычный/Арбитражный</label>
            <Select
              value={typeLabels?.find((o) => o.value === type)}
              onChange={onChangeTypeCharge}
              options={typeLabels}
              styles={selectStyles}
              isSearchable={true}
              placeholder="Тип"
              isClearable
            />
          </div>
        </div>
        <button className={cn('button', 'filters__btn')} onClick={handleApplyFilters}>
          Применить
        </button>
      </div>
    </div>
  ) : sberBill ? (
    <div className="filters">
      <h2 className="filters__title">Фильтры</h2>
      <div className="filters__inner">
        {/* Row 1 */}
        <div className="row">
          <div className="filters__select-2">
            <label className="input__label">ЮрЛица</label>
            <Select
              onChange={onChangeCompanyId}
              options={companiesOption}
              value={companiesOption?.find((o) => o.value === companyId)}
              className="filters-select"
              classNamePrefix="filters-select"
              placeholder="ЮрЛица"
              isSearchable={true}
              styles={selectStyles}
              isClearable
            />
          </div>
        </div>
        <button className={cn('button', 'filters__btn')} onClick={handleApplyFilters}>
          Применить
        </button>
      </div>
    </div>
  ) : productPage ? (
    <div className="filters">
      <h2 className="filters__title">Фильтры</h2>
      <div className="filters__inner">
        {/* Row 1 */}
        <div className="row">
          <div className="filters__select">
            <label className="input__label">Статус</label>
            <InputSelect options={statusLabels} value={status} onChange={onChangeState} />
          </div>
        </div>
        <button className={cn('button', 'filters__btn')} onClick={handleApplyFilters}>
          Применить
        </button>
      </div>
    </div>
  ) : (
    <div className="filters">
      <h2 className="filters__title">Фильтры</h2>
      <div className="filters__inner">
        {/* Row 1 */}
        <div className="row">
          <div className="filters__select">
            <label className="input__label">Статус</label>
            <InputSelect options={statusLabels} value={status} onChange={onChangeState} />
          </div>
          <Input
            placeholder=""
            type="text"
            name="status"
            inputCls="filters__input"
            label="Email"
            value={email}
            onChange={onChangeEmail}
          />
          <div className="filters__date">
            <label className="input__label">Дата от</label>
            <DatePicker
              selected={fromDate}
              onChange={onChangeFromDate}
              dateFormat="dd.MM.yyyy"
              isClearable={true}
              className="filter-dataPicker"
              filterDate={filterFrom}
            />
          </div>
          <div className="filters__date">
            <label className="input__label">Дата до</label>
            <DatePicker
              selected={toDate}
              onChange={onChangeToDate}
              dateFormat="dd.MM.yyyy"
              isClearable={true}
              className="filter-dataPicker"
              filterDate={filterTo}
            />
          </div>
          <Input
            placeholder=""
            type="text"
            name="domain"
            inputCls="filters__input"
            label="Домен"
            value={domain}
            onChange={onChangeDomain}
          />
          <Input
            placeholder=""
            type="number"
            name="count"
            inputCls="filters__input"
            label="Выводить по"
            value={limit}
            onChange={onChangeLimit}
          />
          <button className={cn('button', 'filters__btn')} onClick={handleApplyFilters}>
            Применить
          </button>
        </div>
        {/* Row 2 */}
        <div className="row">
          <Input
            placeholder=""
            type="text"
            name="id"
            inputCls="filters__input"
            label="ID"
            value={id}
            onChange={onChangeId}
          />
          <Input
            placeholder=""
            type="text"
            name="number"
            inputCls="filters__input"
            label="Номер"
            value={orderNumber}
            onChange={onChangeOrderNumber}
          />
          <Input
            placeholder=""
            type="number"
            name="firstSix"
            inputCls="filters__input"
            label="Первые 6 цифр карты"
            value={firstSix}
            onChange={onChangeFirstSix}
          />
          <Input
            placeholder=""
            type="number"
            name="lastFour"
            inputCls="filters__input"
            label="Последние 4 цифр карты"
            value={lastFour}
            onChange={onChangeLastFour}
          />
        </div>
      </div>
    </div>
  )
}

export default Filters
