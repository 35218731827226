import React from 'react';
import styles from './OrderPaymentModal.scss';
import DatePicker from 'react-datepicker';
import ru from 'date-fns/locale/ru';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store';
import { startPayment } from 'store/slices/order';
import { formatISO9075 } from 'date-fns';
import { setPaymentStatus } from 'store/slices/order';
import Loader from 'assets/components/Loader';

const OrderPaymentModal = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const paymentLoad = useAppSelector((state) => state.order.paymentLoad);
  const [startDate, setStartDate] = React.useState('');
  const [amount, setAmount] = React.useState();
  const disabledReg = !amount || !id;

  const handleSubmit = async () => {
    await dispatch(
      startPayment({
        orderId: Number(id),
        amount,
        createAt: startDate ? formatISO9075(startDate) : null,
      }),
    );
  };

  const handleCancel = () => {
    setAmount('');
    setStartDate('');
    dispatch(setPaymentStatus(false));
  };

  return (
    <div className='orderPaymentModal'>
      {paymentLoad ? (
        <Loader />
      ) : (
        <div className='orderPaymentModal__container'>
          <div className='orderPaymentModal__header'>
            <div>Ручное проведение платежа/ребилла по заказу</div>
            <div className='orderPaymentModal__header__close' onClick={handleCancel}>
              X
            </div>
          </div>
          <div>
            <h3>Настройка</h3>
            <div className='orderPaymentModal__containerField'>
              <div className='orderPaymentModal__input-wrapper'>
                <label>
                  <span>Введите сумму списания:</span>
                  <input value={amount} onChange={(e) => setAmount(parseInt(e.target.value) || '')} type='text' />
                </label>
              </div>
              <div className='orderPaymentModal__input-wrapper'>
                <label>
                  <span>Выберите дату и время:</span>
                  <DatePicker
                    selected={startDate}
                    locale={ru}
                    onChange={(date) => setStartDate(date)}
                    timeInputLabel='Время:'
                    dateFormat='yyyy-MM-dd HH:mm'
                    timeFormat='HH:mm'
                    showTimeInput
                  />
                </label>
              </div>
            </div>
            <div className='orderPaymentModal__buttons'>
              <button type='button' onClick={handleCancel} className='orderPaymentModal__buttons__cancel'>
                Отмена
              </button>
              <button
                disabled={disabledReg}
                type='button'
                onClick={handleSubmit}
                className='orderPaymentModal__buttons__add'
              >
                Сохранить
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export { OrderPaymentModal };
