import React from 'react';
import Logo from 'assets/img/logo.png';

const LoaderImg = () => (
  <div className='loaderImg'>
    <img src={Logo} alt='SKILLPAD' className='loaderImg__logo' />
    <div className='loaderImg__loading'>
      <div className='loaderImg__loadingProgress' />
    </div>
  </div>
);

export default LoaderImg;
