import {
  setOrderClearSelected,
  orderGetOne,
  orderInfoUpdateStatus,
  orderInfoChargebackStatus,
  orderRedirectGetOne,
  orderRedirectGetReport,
  orderUnsubscribeOne,
  setOrderUnsubscribeOneIsSuccess,
  orderGetReportPDF,
  setPaymentStatus,
  setPaymentError, orderInfoCancelLead,
} from 'store/slices/order';
import { getDataFromArrayIds } from 'utils/getDataFromArr'
import { toast } from 'react-toastify'
import Loader from 'assets/components/Loader'
import Table from 'assets/components/Table/Table'
import { format } from 'date-fns'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import PopupRefund from 'assets/components/PopupRefund'
import OrderPaymentModal from './OrderPaymentModal'

const getOrderStatus = (status) => {
  switch (status) {
    case 0:
      return 'Новый'
    case 1:
      return 'Активный'
    case 2:
      return 'Неактивный'
    default:
      return ''
  }
}

const OrderInfoPage = () => {
  const { id } = useParams()
  const dispatch = useDispatch()
  const { selectedOrder, isLoad, flag, paymentStatus, flagChargeback, errorMessage, isSuccessUnsubscribe } =
    useSelector((state) => state.order)
  const paymentError = useSelector((state) => state.order.paymentError)
  const availableEdit = useSelector((state) => state.auth.role.permissions.payment)?.includes('create_payments')
  const availableCancelLead = useSelector((state) => state.auth.role.permissions.payment)?.includes('cancel_lead_payments')
  const [showPopupOrder, setShowPopupOrder] = useState(false)
  const [showPopupOrderError, setShowPopupOrderError] = useState(false)
  const [showPopupChargeback, setShowPopupChargeback] = useState(false)
  const [showPopupCancelLead, setShowPopupCancelLead] = useState(false)
  const [showPopupChargebackError, setShowPopupChargebackError] = useState(false)
  const [editItemOrder, setEditItemOrder] = useState(null)
  const [showUnsubscribePopup, setShowUnsubscribePopup] = useState(false)
  const [type, setType] = useState('')
  const [otherOrdersInfo, setOtherOrderInfo] = useState(null)

  const handleEditRefundOrder = (item) => {
    setEditItemOrder(item)
    setShowPopupOrder(true)
  }

  const handleEditChargebackOrder = (item, type) => {
    setEditItemOrder(item)
    setShowPopupChargeback(true)
    setType(type)
  }

  const handleSaveChangeChargeBackOrder = () => {
    dispatch(orderInfoChargebackStatus(Number(editItemOrder.id), type, id))
    setShowPopupChargeback(false)
  }

  const handleSaveChangeCancelLeadBackOrder = () => {
    dispatch(orderInfoCancelLead(Number(editItemOrder.id), type, id))
    setShowPopupCancelLead(false)
  }

  const handleSaveChangeRefundOrder = () => {
    dispatch(orderInfoUpdateStatus(Number(editItemOrder.id), id))
    setShowPopupOrder(false)
  }

  useEffect(() => {
    dispatch(orderGetOne(id))
    return () => dispatch(setOrderClearSelected())
  }, [dispatch, id])

  useEffect(() => {
    setShowPopupOrderError(flag)
  }, [flag])

  useEffect(() => {
    setShowPopupChargebackError(flagChargeback)
  }, [flagChargeback])

  const handleUnsubscribe = () => {
    dispatch(orderUnsubscribeOne(Number(id)))
    setShowUnsubscribePopup(false)
  }

  useEffect(() => {
    if (paymentError) {
      toast.error(paymentError || 'Произошла ошибка ;(')
      dispatch(setPaymentError(''))
    }
  }, [paymentError])

  useEffect(() => {
    if (selectedOrder.childrenOrdersIds?.length) {
      getDataFromArrayIds(selectedOrder.childrenOrdersIds, 'admin/orders')
        .then((data) => data?.find((order) => order.product.name === 'Эконом'))
        .then((res) => res && setOtherOrderInfo(res))
    }
  }, [selectedOrder])

  return (
    <div className="create">
      {isLoad ? (
        <Loader />
      ) : (
        <>
          <h2 className="create__title">Детали заказа {id}</h2>

          <div className="order-info-entry">
            <div className="order-info-entry__title">ID</div>
            <div>{selectedOrder.id}</div>
            <button className="button-small" onClick={() => dispatch(orderRedirectGetOne(id))}>
              Войти
            </button>
            <button className="button-small" onClick={() => dispatch(orderRedirectGetReport(id))}>
              Отчёт
            </button>
            <button
              className="button-small"
              onClick={() =>
                dispatch(
                  orderGetReportPDF({
                    id,
                    firstSix: selectedOrder.cardFirstSix || null,
                    lastFour: selectedOrder.cardLastFour || null,
                  }),
                )
              }
            >
              Отчёт (PDF)
            </button>
            {selectedOrder.status !== 2 && (
              <button className="button-medium" onClick={() => setShowUnsubscribePopup(true)}>
                Отмена подписки
              </button>
            )}
          </div>
          {showUnsubscribePopup && (
            <PopupRefund
              message={`Вы уверены, что хотите отменить подписку ?`}
              onConfirm={handleUnsubscribe}
              onCancel={() => setShowUnsubscribePopup(false)}
            />
          )}
          {isSuccessUnsubscribe && (
            <PopupRefund
              message={`Подписка успешно отменена`}
              onConfirm={() => dispatch(setOrderUnsubscribeOneIsSuccess())}
              oneButton
            />
          )}

          {selectedOrder.parentOrderId && (
            <div className="order-info-entry">
              <div className="order-info-entry__title">Родитель</div>
              <div>
                <Link to={`/order/${selectedOrder.parentOrderId}`} target="_blank">
                  {selectedOrder.parentOrderId}
                </Link>
              </div>
            </div>
          )}

          {!!selectedOrder.childrenOrdersIds?.length && (
            <div className="order-info-entry">
              <div className="order-info-entry__title">Экономы</div>
              <div className="order-info-entry__children">
                {selectedOrder.childrenOrdersIds.map((order) => (
                  <Link key={order} to={`/order/${order}`} target="_blank">
                    {order}
                  </Link>
                ))}
              </div>
            </div>
          )}

          {!!selectedOrder.renewalOrdersIds?.length && (
            <div className="order-info-entry">
              <div className="order-info-entry__title">RENEWAL</div>
              <div className="order-info-entry__children">
                {selectedOrder.renewalOrdersIds.map((order) => (
                  <Link key={order} to={`/order/${order}`} target="_blank">
                    {order}
                  </Link>
                ))}
              </div>
            </div>
          )}

          <div className="order-info-entry">
            <div className="order-info-entry__title">Номер</div>
            <div>{selectedOrder.number}</div>
          </div>

          <div className="order-info-entry">
            <div className="order-info-entry__title">ID клиента</div>
            <Link to={`/user/${selectedOrder.user?.id}`} target="_blank">
              {selectedOrder.user?.id}
            </Link>
          </div>

          <div className="order-info-entry">
            <div className="order-info-entry__title">Email клиента</div>
            <div>{selectedOrder.user?.email}</div>
          </div>

          <div className="order-info-entry">
            <div className="order-info-entry__title">Таймзона</div>
            <div>{selectedOrder.timezone}</div>
          </div>

          <div className="order-info-entry">
            <div className="order-info-entry__title">UTM</div>
            <div>{`utm_campaign: ${selectedOrder.utm?.utm_campaign}, utm_content: ${selectedOrder.utm?.utm_content}, utm_medium: ${selectedOrder.utm?.utm_medium}, utm_source: ${selectedOrder.utm?.utm_source}, utm_term: ${selectedOrder.utm?.utm_term}`}</div>
          </div>

          <div className="order-info-entry">
            <div className="order-info-entry__title">Лэндинг</div>
            <div>{selectedOrder.landing}</div>
          </div>

          <div className="order-info-entry">
            <div className="order-info-entry__title">IP</div>
            <div>{selectedOrder.ip}</div>
          </div>

          <div className="order-info-entry">
            <div className="order-info-entry__title">UserAgent</div>
            <div>{selectedOrder.ua}</div>
          </div>

          <div className="order-info-entry">
            <div className="order-info-entry__title">CPA</div>
            <div>{selectedOrder.cpa}</div>
          </div>

          <div className="order-info-entry">
            <div className="order-info-entry__title">ID продукта</div>
            <Link to={`/product/edit/${selectedOrder.product?.id}`} target="_blank">
              {selectedOrder.product?.id}
            </Link>
          </div>

          <div className="order-info-entry">
            <div className="order-info-entry__title">Имя продукта</div>
            <div>{selectedOrder.product?.innerName}</div>
          </div>

          <div className="order-info-entry">
            <div className="order-info-entry__title">Имя продукта для клиента</div>
            <div>{selectedOrder.product?.name}</div>
          </div>

          <div className="order-info-entry">
            <div className="order-info-entry__title">Создан</div>
            <div>{selectedOrder.createdAt}</div>
          </div>

          <div className="order-info-entry">
            <div className="order-info-entry__title">Статус подписки</div>
            <div>
              {otherOrdersInfo && !otherOrdersInfo.deactivatedAt
                ? 'Эконом'
                : otherOrdersInfo?.deactivatedAt
                ? getOrderStatus(otherOrdersInfo?.status)
                : getOrderStatus(selectedOrder.status)}
            </div>{' '}
          </div>

          <div className="order-info-entry">
            <div className="order-info-entry__title">Переход на эконом</div>
            <div>
              {otherOrdersInfo &&
                `${otherOrdersInfo.createdAt
                  .slice(0, 10)
                  .split('-')
                  .reverse()
                  .join('.')} ${otherOrdersInfo.createdAt.slice(-8)}`}
            </div>
          </div>

          <div className="order-info-entry">
            <div className="order-info-entry__title">Истекает</div>
            <div>{selectedOrder.expiresAt}</div>
          </div>

          <div className="order-info-entry">
            <div className="order-info-entry__title">След. платеж </div>
            <div>{selectedOrder.nextPaymentAt}</div>
          </div>

          <div className="order-info-entry">
            <div className="order-info-entry__title">Деактивирована</div>
            <div>
              {otherOrdersInfo
                ? otherOrdersInfo.deactivatedAt &&
                  `${otherOrdersInfo.deactivatedAt
                    .slice(0, 10)
                    .split('-')
                    .reverse()
                    .join('.')} ${otherOrdersInfo.deactivatedAt.slice(-8)} ${otherOrdersInfo.deactivatedType} ${
                    otherOrdersInfo.deactivatedBy || ''
                  }`
                : selectedOrder.deactivatedAt &&
                  `${selectedOrder.deactivatedAt} ${selectedOrder.deactivatedType} ${
                    selectedOrder.deactivatedBy || ''
                  }`}
            </div>
          </div>

          <div className="order-info-entry">
            <div className="order-info-entry__title">Способ деактивации</div>
            <div>
              {otherOrdersInfo && otherOrdersInfo?.deactivationMethod && otherOrdersInfo?.deactivationClientIp
                ? `${otherOrdersInfo.deactivationMethod} ${otherOrdersInfo.deactivationClientIp}`
                : null}
            </div>
          </div>

          <div className="order-info-entry">
            <div className="order-info-entry__title">Платежная система</div>
            <div>{selectedOrder.paymentSystem}</div>
          </div>

          <div className="order-info-entry">
            <div className="order-info-entry__title">ID Платежный шлюз</div>
            <div>
              <Link to={`/gateway/edit/${selectedOrder.paymentGatewayId}`} target="_blank">
                {selectedOrder.paymentGatewayId}
              </Link>
            </div>
          </div>

          <div className="order-info-entry">
            <div className="order-info-entry__title">Платежный шлюз</div>
            <div>{selectedOrder.paymentGateway}</div>
          </div>

          <div className="order-info-entry">
            <div className="order-info-entry__title">Юр.Лицо</div>
            <div>{selectedOrder?.companyName}</div>
          </div>

          <div className="order-info-entry">
            <div className="order-info-entry__title">Тип карты</div>
            <div>{selectedOrder.cardType}</div>
          </div>

          <div className="order-info-entry">
            <div className="order-info-entry__title">Категория карты</div>
            <div>{selectedOrder.cardCategory}</div>
          </div>

          <div className="order-info-entry">
            <div className="order-info-entry__title">Номер карты</div>
            <div>
              {selectedOrder.cardFirstSix}** **** {selectedOrder.cardLastFour}
            </div>
          </div>

          <div className="order-info-entry">
            <div className="order-info-entry__title">Срок действия карты</div>
            <div>{selectedOrder?.cardExpDate && format(new Date(selectedOrder.cardExpDate), 'MM/yy')}</div>
          </div>

          <div className="order-info-entry">
            <div className="order-info-entry__title">Банк карты</div>
            <div>
              [{selectedOrder.issuerBankCountry}] {selectedOrder.issuer}
            </div>
          </div>

          <div className="order-info-table">
            <label className="order-info-label">История платежей</label>
            <Table
              titles={[
                { name: 'ID' },
                { name: 'Внешний ID', mod: 'big' },
                { name: 'Статус', mod: 'm' },
                { name: 'Создан' },
                { name: 'Тип' },
                { name: 'Сумма' },
                { name: 'Refund' },
                { name: 'Chargeback' },
                { name: 'Arbitration' },
                { name: 'Отмена', available: availableCancelLead },
                { name: 'Текст ошибки' },
                { name: 'Код ошибки' },
                { name: 'Шлюз' },
              ]}
              content={
                otherOrdersInfo ? [...selectedOrder.payments, ...otherOrdersInfo.payments] : selectedOrder.payments
              }
              keyProp="orderPayments"
              availableCancelLead={availableCancelLead}
              handleEditRefundOrder={handleEditRefundOrder}
              handleEditChargebackOrder={handleEditChargebackOrder}
            />

            {selectedOrder.state === 2 ? (
              <div className="order-info__paymnetText">В данный момент по заказу идут платежи</div>
            ) : null}

            {availableEdit ? (
              <button className="button-modal" onClick={() => dispatch(setPaymentStatus(true))}>
                Провести платеж
              </button>
            ) : null}

            {showPopupOrder && (
              <PopupRefund
                message={`Вы уверены, что хотите провести возврат платежа id:${editItemOrder.id}`}
                onConfirm={handleSaveChangeRefundOrder}
                onCancel={() => setShowPopupOrder(false)}
              />
            )}
            {showPopupOrderError && (
              <PopupRefund
                message={`Ошибка при отправке запроса: ${errorMessage}`}
                onConfirm={() => setShowPopupOrderError(false)}
                oneButton
              />
            )}
            {showPopupChargeback && (
              <PopupRefund
                message={`Вы уверены, что хотите провести ${type} платежа id:${editItemOrder.id}`}
                onConfirm={handleSaveChangeChargeBackOrder}
                onCancel={() => {
                  setShowPopupChargeback(false)
                  setType('')
                }}
              />
            )}
            {showPopupCancelLead && (
                <PopupRefund
                    message={`Вы уверены, что хотите провести ${type} платежа id:${editItemOrder.id}`}
                    onConfirm={handleSaveChangeCancelLeadBackOrder}
                    onCancel={() => {
                      setShowPopupCancelLead(false)
                      setType('')
                    }}
                />
            )}
            {showPopupChargebackError && (
              <PopupRefund
                message={`Ошибка при отправке запроса: ${errorMessage}`}
                onConfirm={() => setShowPopupChargebackError(false)}
                oneButton
              />
            )}

            {selectedOrder?.logs?.order?.length ? (
              <div className="order-info-table">
                <label className="order-info-label">Логи доступа к заказу</label>
                <Table
                  titles={[{ name: 'Время' }, { name: 'IP' }, { name: 'UserAgent' }]}
                  content={selectedOrder?.logs?.order}
                  keyProp="orderLogs"
                />
              </div>
            ) : null}

            {selectedOrder?.logs?.auth?.length ? (
              <div className="order-info-table">
                <label className="order-info-label">Логи авторизаций</label>
                <Table
                  titles={[{ name: 'ID' }, { name: 'Время' }, { name: 'IP' }, { name: 'UserAgent' }]}
                  content={selectedOrder?.logs?.auth}
                  keyProp="authLogs"
                />
              </div>
            ) : null}

            {selectedOrder?.logs?.files && Object.values(selectedOrder.logs.files).length ? (
              <div className="order-info-table">
                <label className="order-info-label">Логи доступа к файлам</label>
                {Object.values(selectedOrder.logs.files).map((f) => (
                  <div key={f.fileId}>
                    <div className="order-info-table__title">
                      ID - {f.fileId} | Название - {f.name}
                    </div>
                    <Table
                      titles={[{ name: 'Время' }, { name: 'IP' }, { name: 'UserAgent' }]}
                      content={f.logs}
                      keyProp="fileLogs"
                    />
                  </div>
                ))}
              </div>
            ) : null}

            {selectedOrder?.logs?.admin && Object.values(selectedOrder.logs.admin).length ? (
                <div className="order-info-table">
                  <label className="order-info-label">Логи действий</label>
                  <Table
                      titles={[{ name: 'Время' }, { name: 'Тип действия', mod: 'big' }, { name: 'User' }, { name: 'Действия', mod: 'big' }]}
                      content={selectedOrder?.logs?.admin}
                      orderId={selectedOrder.id}
                      keyProp="adminLogs"
                  />
                </div>
            ) : null}

            <div className="buttonGroup">
              <Link to="/orders/all" className="button order-info-button">
                Назад
              </Link>
            </div>
          </div>
          {paymentStatus ? <OrderPaymentModal /> : null}
        </>
      )}
    </div>
  )
}

export { OrderInfoPage }
