import React from 'react';
import { useAppDispatch } from 'store';
import { modalCleanData } from 'store/slices/modal';
import { getBots, deleteBot } from 'store/slices/bots';
import Header from 'assets/components/Header';
import Table from 'assets/components/Table';
import Loader from 'assets/components/Loader';
import { useAppSelector } from 'store';

const titleBots = [
  {
    name: 'ID',
  },
  {
    name: 'тип',
  },
  {
    name: 'Имя',
  },
  {
    name: 'Link',
    mod: 'big',
  },
  {
    name: '...',
  },
];

const BotsPage = () => {
  const dispatch = useAppDispatch();
  const isLoad = useAppSelector((state) => state.bots.isLoad);
  const botsList = useAppSelector((state) => state.bots.botsList);
  const modalStatus = useAppSelector((state) => state.modal.modalStatus);
  const infoPayload = useAppSelector((state) => state.modal.infoPayload);

  React.useEffect(() => {
    dispatch(getBots());
  }, []);

  React.useEffect(() => {
    if (modalStatus === 'yes') {
      dispatch(deleteBot(Number(infoPayload.id), 'delete'));
      dispatch(modalCleanData());
    }
  }, [modalStatus, dispatch]);

  return (
    <>
      {isLoad ? <Loader /> : ''}
      <Header title='Боты' text='Добавить бота' href='/bots/add' />
      <Table keyProp='bots' content={botsList} titles={titleBots} />
    </>
  );
};

export default BotsPage;
