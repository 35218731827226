import React from 'react';
import './formField.scss';

const FormField = (props) => {
  const { name, label, value, onChange, type = 'text' } = props;
  return (
    <div className='wrapper'>
      <label htmlFor={name}>{label}</label>
      <input name={name} type={type} value={value} onChange={(event) => onChange(event.target.value)} />
    </div>
  );
};

export default FormField;
