import React from 'react';
import { request } from 'api';
import './botsCrtPage.scss';
import SelectWrapper from 'assets/components/SelectWrapper';
import FormField from 'assets/components/FormField';
import Select from 'react-select';
import Loader from 'assets/components/Loader';
import { useAppDispatch, useAppSelector } from 'store';
import { domainGetAll } from 'store/slices/domain';
import { createBots, getSelectedBot, updateBot } from 'store/slices/bots';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

const selectStyles = {
  control: (provided) => ({
    ...provided,
    minHeight: '40px',
  }),
  container: (provided) => ({
    ...provided,
    minWidth: '200px',
    minHeight: '1px',
    textAlign: 'left',
    border: 'none',
  }),
};

const typeOfBotOptions = [
  {
    value: 0,
    label: 'telegram',
  },
  {
    value: 1,
    label: 'vk',
  },
];

const vkData = {
  link: '',
  token: '',
  bot_name: '',
  confirmation_token: '',
  group_id: '',
  secret: '',
};

const tgData = {
  link: '',
  token: '',
  bot_name: '',
};

const BotsCrtPage = () => {
  const { id } = useParams();
  const isLoad = useAppSelector((state) => state.bots.isLoad);
  const dispatch = useAppDispatch();
  const domainsOption = useAppSelector((state) => state.domain.list)?.map((item) => ({
    label: item.name,
    value: Number(item.id),
  }));
  const [typeId, setTypeId] = React.useState(null);
  const [settings, setSettings] = React.useState({
    link: '',
    token: '',
    bot_name: '',
  });
  const [domains, setDomains] = React.useState([]);
  const [isActive, setIsActive] = React.useState();
  const [reload, setReload] = React.useState(true);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (id) {
      dispatch(updateBot(id, { typeId, domains, settings }));
    } else {
      dispatch(createBots({ typeId, domains, settings }));
    }
  };

  React.useEffect(() => {
    dispatch(domainGetAll());
  }, [dispatch]);

  React.useEffect(() => {
    if (id && reload) {
      dispatch(getSelectedBot(id)).then((response) => {
        setReload(false);
        setTypeId(response.data?.typeId);
        setDomains([response.data?.domains[0]?.id]);
        setIsActive(response.data?.domains[0]?.status);
        if (response.data?.typeId === 0) {
          setSettings({
            link: response.data?.link,
            token: response.data?.token,
            bot_name: response.data?.bot_name,
          });
        } else {
          setSettings({
            link: response.data?.link,
            token: response.data?.token,
            bot_name: response.data?.bot_name,
            confirmation_token: response.data?.confirmation_token,
            group_id: response.data?.group_id,
            secret: response.data?.secret,
          });
        }
      });
    }
  }, [id, dispatch, reload]);

  const handleIsActive = async () => {
    return await request
      .post(`/admin/bots/${id}/set-webhook`)
      .then(() => {
        toast.success('Запрос прошел успешшно');
        setReload(true);
      })
      .catch(() => toast.error('Ошибка при при выполнени запроса'));
  };

  return (
    <>
      {isLoad ? <Loader /> : null}
      <form onSubmit={handleSubmit} className='botPage'>
        <h1>{id ? `Редактирование Бота ID: ${id}` : 'Создание Бота'}</h1>
        <SelectWrapper label='Тип'>
          <Select
            onChange={(option) => {
              setTypeId(option.value);
              option.value === 1 ? setSettings(vkData) : setSettings(tgData);
            }}
            value={typeOfBotOptions?.filter((o) => o.value === typeId)}
            styles={selectStyles}
            options={typeOfBotOptions}
            placeholder='Тип'
            isDisabled={id}
          />
        </SelectWrapper>
        <FormField
          value={settings.link}
          onChange={(newValue) => setSettings((prev) => ({ ...prev, link: newValue }))}
          name='link'
          label='Сылка'
          placeholder='Ссылка'
        />
        <FormField
          value={settings.token}
          onChange={(newValue) => setSettings((prev) => ({ ...prev, token: newValue }))}
          name='token'
          label='Token'
          placeholder='Token'
        />
        <FormField
          value={settings.bot_name}
          onChange={(newValue) => setSettings((prev) => ({ ...prev, bot_name: newValue }))}
          name='bot_name'
          label='Bot Name'
          placeholder='bot_name'
        />
        {settings.hasOwnProperty('confirmation_token') && (
          <FormField
            value={settings.confirmation_token}
            onChange={(newValue) => setSettings((prev) => ({ ...prev, confirmation_token: newValue }))}
            name='confirmation_token'
            label='Confirmation token'
            placeholder='Confirmation token'
          />
        )}
        {settings.hasOwnProperty('group_id') && (
          <FormField
            value={settings.group_id}
            onChange={(newValue) => setSettings((prev) => ({ ...prev, group_id: newValue }))}
            name='group_id'
            label='group_id'
            placeholder='group_id'
          />
        )}
        {settings.hasOwnProperty('secret') && (
          <FormField
            value={settings.secret}
            onChange={(newValue) => setSettings((prev) => ({ ...prev, secret: newValue }))}
            name='secret'
            label='secret'
            placeholder='secret'
          />
        )}
        <SelectWrapper label='Домен'>
          <Select
            onChange={(option) => setDomains(() => [option.value])}
            value={domainsOption?.filter((o) => o.value === domains?.[0])}
            styles={selectStyles}
            options={domainsOption}
            isSearchable={true}
            placeholder='Домен'
          />
        </SelectWrapper>
        {id && !isActive && (
          <button type='button' onClick={handleIsActive}>
            Активировать Бота
          </button>
        )}
        <button
          disabled={!domains.length || Object.values(settings).includes('') || typeof typeId !== 'number'}
          type='submit'
        >
          Сохранить
        </button>
      </form>
    </>
  );
};

export default BotsCrtPage;
