import { request } from 'api';

export const AUTH_CHECK_REQUEST = 'AUTH_CHECK_REQUEST';
export const AUTH_CHECK_SUCCESS = 'AUTH_CHECK_SUCCESS';
export const AUTH_CHECK_FAILURE = 'AUTH_CHECK_FAILURE';

const authCheckRequest = () => ({ type: AUTH_CHECK_REQUEST });
const authCheckSuccess = (payload) => ({ type: AUTH_CHECK_SUCCESS, payload });
const authCheckFailure = () => ({ type: AUTH_CHECK_FAILURE });

export const authCheck = () => async (dispatch) => {
  dispatch(authCheckRequest());
  request
    .get('/admin')
    .then((resp) => dispatch(authCheckSuccess(resp.data)))
    .catch(() => dispatch(authCheckFailure()));
};

export const AUTH_LOGIN_REQUEST = 'AUTH_LOGIN_REQUEST';
export const AUTH_LOGIN_SUCCESS = 'AUTH_LOGIN_SUCCESS';
export const AUTH_LOGIN_FAILURE = 'AUTH_LOGIN_FAILURE';

const authLoginRequest = () => ({ type: AUTH_LOGIN_REQUEST });
const authLoginSuccess = (payload) => ({ type: AUTH_LOGIN_SUCCESS, payload });
const authLoginFailure = (payload) => ({ type: AUTH_LOGIN_FAILURE, payload });

export const authLogin = (payload) => async (dispatch) => {
  dispatch(authLoginRequest());
  request
    .post('/admin/login', payload)
    .then((resp) => {
      dispatch(authLoginSuccess(resp.data));
    })
    .catch((err) => {
      dispatch(authLoginFailure(err));
    });
};

export const AUTH_LOGIN_SECOND_REQUEST = 'AUTH_LOGIN_SECOND_REQUEST';
export const AUTH_LOGIN_SECOND_SUCCESS = 'AUTH_LOGIN_SECOND_SUCCESS';
export const AUTH_LOGIN_SECOND_FAILURE = 'AUTH_LOGIN_SECOND_FAILURE';

const authLoginSecondRequest = () => ({ type: AUTH_LOGIN_SECOND_REQUEST });
const authLoginSecondSuccess = (payload) => ({ type: AUTH_LOGIN_SECOND_SUCCESS, payload });
const authLoginSecondFailure = (payload) => ({ type: AUTH_LOGIN_SECOND_FAILURE, payload });

export const authAutintification = (payload) => async (dispatch) => {
  dispatch(authLoginSecondRequest());
  request
    .post('/admin/2fa_check', payload)
    .then((resp) => {
      dispatch(authLoginSecondSuccess(resp.data));
    })
    .catch((err) => {
      dispatch(authLoginSecondFailure(err));
    });
};

export const AUTH_LOGOUT_REQUEST = 'AUTH_LOGOUT_REQUEST';
export const AUTH_LOGOUT_SUCCESS = 'AUTH_LOGOUT_SUCCESS';
export const AUTH_LOGOUT_FAILURE = 'AUTH_LOGOUT_FAILURE';

const authLogoutRequest = () => ({ type: AUTH_LOGOUT_REQUEST });
const authLogoutSuccess = () => ({ type: AUTH_LOGOUT_SUCCESS });
const authLogoutFailure = () => ({ type: AUTH_LOGOUT_FAILURE });

export const authLogout = () => async (dispatch) => {
  dispatch(authLogoutRequest());
  request
    .post('/admin/logout')
    .then((resp) => dispatch(authLogoutSuccess()))
    .catch(() => dispatch(authLogoutFailure()));
};

export const AUTH_CLEAN_ERORRS_LOCALE = 'AUTH_CLEAN_ERORRS_LOCALE';

const authCleanErrorsLocale = () => ({ type: AUTH_CLEAN_ERORRS_LOCALE });

export const authCleanErrors = () => {
  return (dispatch) => {
    dispatch(authCleanErrorsLocale());
  };
};

export const AUTH_FAIL_LOCALE = 'AUTH_FAIL_LOCALE';

const authFailLocale = () => ({ type: AUTH_FAIL_LOCALE });

export const authFail = () => {
  return (dispatch) => {
    dispatch(authFailLocale());
  };
};

export const AUTH_2FA_ON_REQUEST = 'AUTH_2FA_ON_REQUEST';
export const AUTH_2FA_ON_SUCCESS = 'AUTH_2FA_ON_SUCCESS';
export const AUTH_2FA_ON_FAILURE = 'AUTH_2FA_ON_FAILURE';

const auth2FaOnRequest = () => ({ type: AUTH_2FA_ON_REQUEST });
const auth2FaOnSuccess = (payload) => ({ type: AUTH_2FA_ON_SUCCESS, payload });
const auth2FaOnFailure = (payload) => ({ type: AUTH_2FA_ON_FAILURE, payload });

export const auth2FaOnLogin = () => async (dispatch) => {
  dispatch(auth2FaOnRequest());
  request
    .post('/admin/2fa/enable')
    .then((resp) => dispatch(auth2FaOnSuccess(resp.status)))
    .catch((error) => {
      dispatch(auth2FaOnFailure(error.response));
    });
};

export const AUTH_2FA_TOKEN_REQUEST = 'AUTH_2FA_TOKEN_REQUEST';
export const AUTH_2FA_TOKEN_SUCCESS = 'AUTH_2FA_TOKEN_SUCCESS';
export const AUTH_2FA_TOKEN_FAILURE = 'AUTH_2FA_TOKEN_FAILURE';

const auth2FaTokenRequest = () => ({ type: AUTH_2FA_TOKEN_REQUEST });
const auth2FaTokenSuccess = (payload) => ({ type: AUTH_2FA_TOKEN_SUCCESS, payload });
const auth2FaTokenFailure = () => ({ type: AUTH_2FA_TOKEN_FAILURE });

export const auth2FaTokenLogin = () => async (dispatch) => {
  dispatch(auth2FaTokenRequest());
  request
    .get('/admin/2fa/token')
    .then((resp) => dispatch(auth2FaTokenSuccess(resp.data)))
    .catch((error) => {
      dispatch(auth2FaTokenFailure(error));
    });
};

export const AUTH_2FA_QRCODE_REQUEST = 'AUTH_2FA_QRCODE_REQUEST';
export const AUTH_2FA_QRCODE_SUCCESS = 'AUTH_2FA_QRCODE_SUCCESS';
export const AUTH_2FA_QRCODE_FAILURE = 'AUTH_2FA_QRCODE_FAILURE';

const auth2FaQrCodeRequest = () => ({ type: AUTH_2FA_QRCODE_REQUEST });
const auth2FaQrCodeSuccess = (payload) => ({ type: AUTH_2FA_QRCODE_SUCCESS, payload });
const auth2FaQrCodeFailure = () => ({ type: AUTH_2FA_QRCODE_FAILURE });

export const auth2FaQrCodeLogin = () => async (dispatch) => {
  dispatch(auth2FaQrCodeRequest());
  request
    .get('/admin/2fa/qr-code', { responseType: 'blob' })
    .then((resp) => dispatch(auth2FaQrCodeSuccess(URL.createObjectURL(resp.data))))
    .catch((error) => {
      dispatch(auth2FaQrCodeFailure(error));
    });
};

export const AUTH_2FA_TOKEN_QRCODE_CLEAR = "AUTH_2FA_TOKEN_QRCODE_CLEAR";

export const auth2FaTokenQrCodeClear = () => ({ type: AUTH_2FA_TOKEN_QRCODE_CLEAR });
