import React from 'react';
import FormField from 'assets/components/FormField';
import DatePicker from 'react-datepicker';
import SelectWrapper from 'assets/components/SelectWrapper';
import ru from 'date-fns/locale/ru';
import { formatISO9075, set } from 'date-fns';
import './webinarCrtPage.scss';
import { useAppDispatch, useAppSelector } from 'store';
import { domainGetAll } from 'store/slices/domain';
import { productGetAll } from 'store/slices/product';
import Select from 'react-select';
import { v4 as uuid } from 'uuid';
import { createWebinar, setError, getSelectedWebinar, updateWebinar, setIsLoad } from 'store/slices/webinar';
import Loader from 'assets/components/Loader';
import { toast } from 'react-toastify';
import { Link, useParams, useHistory } from 'react-router-dom';
import { request } from 'api';

const selectStyles = {
  control: (provided) => ({
    ...provided,
    minHeight: '40px',
  }),
  container: (provided) => ({
    ...provided,
    minWidth: '200px',
    minHeight: '1px',
    textAlign: 'left',
    border: 'none',
  }),
};

const WebinarCrtPage = () => {
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const isLoad = useAppSelector((state) => state.webinars.isLoad);
  const errorMessage = useAppSelector((state) => state.webinars.errors);
  const domains = useAppSelector((state) => state.domain.list)?.map((item) => ({
    label: item.name,
    value: Number(item.id),
  }));
  const productsOption = useAppSelector((state) => state.product.list)?.map((item) => ({
    label: item.name,
    value: item.id,
  }));
  const [webinar, setWebinar] = React.useState({
    name: '',
    startAt: '',
    endAt: '',
    active: false,
    hash: uuid(),
    hashVideo: '',
    products: [],
    domainId: '',
    description: '',
    texts: [],
  });
  const [count_fake_messages, setCount_fake_messages] = React.useState('');

  React.useEffect(() => {
    dispatch(domainGetAll());
    dispatch(productGetAll());
  }, []);

  React.useEffect(() => {
    if (id) {
      dispatch(getSelectedWebinar(id)).then((response) => {
        setWebinar({
          ...response.data,
          products: response.data?.products.map((item) => item.id),
          domainId: response.data?.domain?.id,
        });
        setCount_fake_messages(response.data.count_fake_messages);
      });
    }
  }, [id]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const obj = {
      ...webinar,
      startAt: typeof webinar.startAt === 'object' ? formatISO9075(webinar.startAt) : webinar.startAt,
      endAt: typeof webinar.endAt === 'object' ? formatISO9075(webinar.endAt) : webinar.endAt,
    };
    if (id) {
      if (typeof obj['domainId'] === 'undefined') {
        obj.domainId = obj.domain.id;
      }
      delete obj.domain;
      dispatch(updateWebinar(id, obj));
    } else {
      dispatch(createWebinar(obj))
        .then((resp) => history.push(`/webinars/edit/${resp.data.id}`))
        .catch((error) => {
          dispatch(setError(Object.values(error.response.data.error?.messages)[0]));
          dispatch(setIsLoad(false));
        });
    }
  };

  React.useEffect(() => {
    if (errorMessage) {
      toast.error(errorMessage);
      dispatch(setError(null));
    }
  }, [errorMessage]);

  const handleOnChange = (e) => {
    handleUpload(e.target.files[0]);
  };

  const handleUpload = async (file) => {
    const formData = new FormData();
    formData.append('file', file);
    return await request
      .post(`/admin/webinars/${id}/import-file`, formData, {
        ContentType: 'application/json',
      })
      .then(() => toast.success('Файл успешно загружен'))
      .catch(() => toast.error('Ошибка при загрузке файла'));
  };

  return (
    <>
      {isLoad ? <Loader /> : null}
      <div className='webinarCrtPage'>
        <h1>{id ? 'Создать Вебинар' : 'Редактирование вебинара'}</h1>
        <form onSubmit={handleSubmit}>
          <FormField
            name='name'
            label='Название'
            value={webinar.name || ''}
            onChange={(newValue) => setWebinar((prevState) => ({ ...prevState, name: newValue }))}
          />
          <SelectWrapper label='Начало'>
            <DatePicker
              selected={
                webinar.startAt
                  ? set(new Date(), {
                      hours: webinar.startAt.split(':')[0],
                      minutes: webinar.startAt.split(':')[1],
                      seconds: webinar.startAt.split(':')[2],
                    })
                  : null
              }
              onChange={(date) =>
                setWebinar((prevState) => ({ ...prevState, startAt: date.toLocaleTimeString('ru-RU') }))
              }
              isClearable={true}
              locale={ru}
              dateFormat='HH:mm:ss'
              timeFormat='HH:mm:ss'
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={1}
              timeCaption='Время:'
            />
          </SelectWrapper>
          <SelectWrapper label='Конец'>
            <DatePicker
              selected={
                webinar.endAt
                  ? set(new Date(), {
                      hours: webinar.endAt.split(':')[0],
                      minutes: webinar.endAt.split(':')[1],
                      seconds: webinar.endAt.split(':')[2],
                    })
                  : null
              }
              onChange={(date) =>
                setWebinar((prevState) => ({ ...prevState, endAt: date.toLocaleTimeString('ru-RU') }))
              }
              isClearable={true}
              locale={ru}
              dateFormat='HH:mm:ss'
              timeFormat='HH:mm:ss'
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={1}
              timeCaption='Время:'
            />
          </SelectWrapper>
          <SelectWrapper label='Продукт'>
            <Select
              onChange={(option) =>
                setWebinar((prevState) => ({ ...prevState, products: option.map((item) => item.value) }))
              }
              value={productsOption?.filter((item) => webinar.products.includes(item.value))}
              styles={selectStyles}
              options={productsOption}
              isSearchable={true}
              isMulti={true}
              placeholder='Продукт'
            />
          </SelectWrapper>
          <div className='wrapper'>
            <label htmlFor='active'>Активный</label>
            <input
              name='active'
              type='checkbox'
              checked={webinar.active || false}
              onChange={() => setWebinar((prevState) => ({ ...prevState, active: !prevState.active }))}
            />
          </div>
          <SelectWrapper label='Домен'>
            <Select
              onChange={(option) => setWebinar((prevState) => ({ ...prevState, domainId: option.value }))}
              value={domains?.find((o) => o.value === webinar?.domainId)}
              styles={selectStyles}
              options={domains}
              isSearchable={true}
              placeholder='Домен'
            />
          </SelectWrapper>
          <FormField
            name='hashVideo'
            label='Hash'
            value={webinar.hashVideo || ''}
            onChange={(newValue) => setWebinar((prevState) => ({ ...prevState, hashVideo: newValue }))}
          />
          {id && <div>Количество вообщений: {count_fake_messages}</div>}
          {id && (
            <div className='wrapper'>
              <label htmlFor='file'>Загрузка файла</label>
              <input name='file' type='file' accept='.csv' onChange={handleOnChange} />
            </div>
          )}
          <div className='webinarCrtPage__textarea'>
            <span>Описание</span>
            <textarea
              name='description'
              value={webinar.description}
              onChange={(e) => setWebinar((prevState) => ({ ...prevState, description: e.target.value }))}
            />
          </div>

          <>
            {webinar.texts.length
              ? webinar.texts?.map((_, index) => {
                  return (
                    <React.Fragment key={index}>
                      <FormField
                        name='period'
                        placeholder='Период'
                        label='Период'
                        value={webinar.texts[index].period}
                        onChange={(newValue) =>
                          setWebinar((prevState) => ({
                            ...prevState,
                            texts: prevState.texts.map((desc, ind) => {
                              if (index === ind) {
                                return { ...desc, period: parseInt(newValue) ? parseInt(newValue) : '' };
                              }
                              return desc;
                            }),
                          }))
                        }
                      />
                      <div className='webinarCrtPage__textarea'>
                        <span>Текст</span>
                        <textarea
                          name='text'
                          value={webinar.texts[index].text}
                          onChange={(e) =>
                            setWebinar((prevState) => ({
                              ...prevState,
                              texts: prevState.texts.map((desc, ind) => {
                                if (index === ind) {
                                  return { ...desc, text: e.target.value };
                                }
                                return desc;
                              }),
                            }))
                          }
                        />
                      </div>
                    </React.Fragment>
                  );
                })
              : null}
          </>
          <button
            className='addWebinar'
            onClick={() =>
              setWebinar((prevState) => ({ ...prevState, texts: [...prevState.texts, { text: '', period: '' }] }))
            }
            type='button'
          >
            Добавить текст для Вебинара
          </button>
          <button disabled={Object.values(webinar).includes('') || !webinar.texts.length} type='submit'>
            Сохранить
          </button>
          <Link to='/webinars'>Вернуться назад</Link>
        </form>
      </div>
    </>
  );
};

export default WebinarCrtPage;
